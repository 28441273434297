import React from 'react';
import { connect } from 'react-redux';
import { betActions, userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import Sidebar from "../../components/Sidebar/Sidebar";


class satteledBet extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      currentCount: "Sport",
      noOfRecords: 20,
      index: 0,
      betType: 1,
      optionRadioName: "MATCHED",
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().format('YYYY-MM-DD'),
      sportsId: 0,
      limit: "",
      // sportsData: {}
    }
  }

  componentDidMount() {
    let data = {
      // "fromDate": moment(this.state.startDate).startOf('day').unix(),
      // "toDate": moment(this.state.endDate).endOf('day').unix(),
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 10
    }

    this.props.dispatch(userActions.transactionHistory(data));
    // this.props.dispatch(userActions.getBetsList(data));

    let gameReqData =
    {
      "keyWord": "",
      "pageNo": 1,
      "sortBy": "slug",
      "sortOrder": "asc",
      "fromDate": "",
      "toDate": "",
      "size": 10
    }

    this.props.dispatch(userActions.getGamesList(gameReqData));
  }


  getBatHistory = () => {
    let data = {
      "fromDate": moment(this.state.startDate).startOf('day').unix(),
      "toDate": moment(this.state.endDate).endOf('day').unix(),
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 10
    }

    this.props.dispatch(userActions.transactionHistory(data));
  }

  inputChangeStartDate = (e) => {
    console.log("eeeeeeeeeeee", e.target.value);

    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
    this.setState({ endDate: e.target.value });
  }

  handleChangeSelect = (event) => {
    this.setState({ sportsId: event.target.value });
  };


  inputChangeCurrentCount = (event) => {
    this.setState({ currentCount: event });
  }

  handlePageClick = (data) => {
    console.log("data  ", data);
    this.setState({ index: data.selected });
    let pagination = {
      "fromDate": moment(this.state.startDate).startOf('day').unix(),
      "toDate": moment(this.state.endDate).endOf('day').unix(),
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 10
    }
    this.props.dispatch(betActions.betHistory(pagination));
  }

  render() {

    let { bet, users } = this.props;
    let { transactionHistory, loading } = users;



    console.log("betbetbetbetbetbetbet", bet);



    // const custom = "flex items-center  text-white space-x-4 text-slate-400 text-[.8125rem]";
    // const active = "bg-[#23292E] rounded-full w-8 h-8 flex items-center justify-center text-[.8125rem] text-white ";


    return (

      <>
        <div>
          <Loader
            active={loading}
          />
        </div>
        <div className='bg-white w-full min-h-screen flex py-10' style={{ backgroundImage: "url(/images/mw-ban.png)" }}>
          <div className='w-full lg:w-[90%] space-y-4 mx-auto'>
            <div className="overflow-hidden ">
              <div className="max-w-full overflow-auto ">
                <div className="inline-block min-w-full ">
                  <div className="overflow-hidden w-full ">
                    <table className="min-w-full capitalize border-2 border-collapse ">
                      <tr className=" text-white text-[0.8125rem] text-center font-semibold bg-[#9E7A16] border-b-2">
                        <th className="px-[6px] py-2 border">Total Credit</th>
                        <th className="px-[6px] py-2 border">0</th>
                        <th className="px-[6px] py-2 border">Total Debit</th>
                        <th className="px-[6px] py-2 border">0</th>
                        <th className="px-[6px] py-2 border">Total Balance :- 0</th>
                      </tr>
                      <tr className=" text-white text-[0.8125rem] text-center font-semibold bg-[#9E7A16] border-b-2">
                        <th className="px-[6px] py-2 border">Date</th>
                        <th className="px-[6px] py-2 border">Action</th>
                        <th className="px-[6px] py-2 border">Amount</th>
                        <th className="px-[6px] py-2 border">Entry</th>
                        <th className="px-[6px] py-2 border">Credit</th>
                        <th className="px-[6px] py-2 border">Debit</th>
                        <th className="px-[6px] py-2 border">Balance</th>
                      </tr>
                      {transactionHistory && transactionHistory.length > 0 ? transactionHistory.map((element, index) => (
                        <React.Fragment >
                          <tr className={`${element.Type === "Back" ? "bg-[#6ABBEC]" : "bg-[#FAA9BA]"} text-black text-[0.8125rem] text-left font-medium border-b-2 `}>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                              {/* {moment(parseInt(element.Placed) * 1000).utcOffset("+05:30").format("DD MMM, YYYY HH:mm A")} */}
                              {moment(new Date(parseInt(element.createdAt))).utcOffset("+05:30").format("DD MMM, YYYY hh:mm A")}
                            </td>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                            </td>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                              {element && element.amount ? element.amount : "-"}
                            </td>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                            </td>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                            </td>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                            </td>
                            <td className="px-[6px] py-1.5 whitespace-nowrap border border-slate-300">
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                        :
                        <tr className="py-1 px-[6px] text-black text-sm text-center whitespace-nowrap">
                          <td className="py-1 px-[6px]">There are no records to show</td>
                        </tr>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users, bet } = state;

  console.log("users:::ACTIVE_MATCH::", users);

  return {
    users,
    bet
  };
}

export default connect(mapStateToProps)(satteledBet);

