export const CONST = {
  // BACKEND_URL: 'https://shilong-backend.vercel.app/api/v1/'
  // BACKEND_URL: 'http://localhost:8804/api/v1/'
  BACKEND_URL: 'https://www.shillongpocket.com/api/v1/'
};

export const betChipsData = {
  "1000": 1000,
  "2000": 2000,
  "5000": 5000,
  "10000": 10000,
  "20000": 20000,
  "50000": 50000,
  "100000": 100000,
  "250000": 250000,
};

export const DomainName = 'Shillong_Pocket';
