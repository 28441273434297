import React from 'react';

export default function BetPlaceDesktop(props) {
    const { updateStackOnclick, inputChange, decreaseCount, count, increaseCount, placeBet, handleClose, betSlipData } = props;
    console.log("betSlipDatabetSlipDatabetSlipDataURBET", betSlipData);

    return (
        <div className={`${betSlipData.type === "LAGAI" ? "match_bg_pink_index-0" : "match_bg_blue_index-0 "} p-2`}>
            <div className='p-3 font-medium text-base'>
                {betSlipData.nat}
            </div>
            <div class="flex justify-between items-center space-x-1 text-black">
                <div className="bg-white rounded w-full flex divide-x divide-gray-300">
                    <span className="p-2 font-bold cursor-pointer" onClick={decreaseCount}>-</span>
                    <span className="p-2 text-sm w-full">{count}</span>
                    <span className="p-2 font-bold cursor-pointer" onClick={increaseCount}>+</span>
                </div>
                <div className="bg-white rounded w-full flex divide-x divide-gray-300">
                    <span className="p-2 font-extrabold cursor-pointer" onClick={decreaseCount}>-</span>
                    <input className="focus:outline-none rounded px-2 w-full" placeholder="0" value={betSlipData.stake} onChange={inputChange} />
                    <span className="p-2 font-bold cursor-pointer" onClick={increaseCount}>+</span>
                </div>
            </div>
            <div className="grid grid-cols-4 gap-6 p-3">
                <button class="rounded px-1.5 py-1 text-xs bg-[#CBCBCB] font-bold" onClick={() => updateStackOnclick("500")}>500</button>
                <button class="rounded px-1.5 py-1 text-xs bg-[#CBCBCB] font-bold" onClick={() => updateStackOnclick("1000")}>1000</button>
                <button class="rounded px-1.5 py-1 text-xs bg-[#CBCBCB] font-bold" onClick={() => updateStackOnclick("5000")}>5000</button>
                <button class="rounded px-1.5 py-1 text-xs bg-[#CBCBCB] font-bold" onClick={() => updateStackOnclick("10000")}>10000</button>
                <button class="rounded px-1.5 py-1 text-xs bg-[#CBCBCB] font-bold" onClick={() => updateStackOnclick("50000")}>50000</button>
                <button class="rounded px-1.5 py-1 text-xs bg-[#CBCBCB] font-bold" onClick={() => updateStackOnclick("0")} >Clear</button>
            </div>
            <div className="flex justify-end items-center space-x-2 py-2 whitespace-nowrap w-full">
                <button className="bg-[#D2322E] rounded-sm px-4 py-2 text-sm text-white w-full" onClick={() => handleClose()}>Cancel</button>
                <button className="bg-[#2F5A93] rounded-sm px-4 py-2 text-sm text-white w-full" onClick={() => placeBet()}>Place Bet</button>
            </div>
        </div>
    );
}

