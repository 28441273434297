import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import moment from 'moment';
import Sidebar from "../../components/Sidebar/Sidebar";
import Loader from '../../components/Loader/Loader';
import { FaFilter } from 'react-icons/fa';
import { RxCross2 } from 'react-icons/rx';

class AccountSatement extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      declareMatchModal: false,
      setFmatchId: false,
      declareMatchDetails: {},
      errorsMatch: {},
      currentCount: 0,
      endDate: moment().format('YYYY-MM-DD'),
      startDate: moment().subtract(6, 'days').format('YYYY-MM-DD'),
    }
  }

  getStementSubmit = () => {
    let data =
    {
      "from_date": moment(this.state.startDate).startOf('day').unix(),
      "to_date": moment(this.state.endDate).endOf('day').unix(),
      "limit": "15",
      "pageno": "1",
    }
  }

  inputChangeStartDate = (e) => {
    this.setState({ startDate: e.target.value });
  }

  inputChangeEndDate = (e) => {
  }

  setCurrentCount = (data) => {
    this.setState({ currentCount: data });
  };
  onClickMenu = (url) => {
    this.props.history.push(url);
  };

  render() {
    let { users } = this.props;
    let { statement, loading } = users;

    // console.log("statementstatementstatementstatement", statement);

    return (
      <>
        <div>
          <Loader
            active={loading}
          />
        </div>
        <div className='relative w-full h-full bg-white overflow-y-auto flex space-x-1 lg:p-1'>
          <Sidebar
            open={true}
            // setOpen={setOpen}
            // setOpenMobile={setOpenMobile}
            // openMobile={openMobile}
            showSport={true}
            props={this.props}
          />
          <div className='w-full'>
            <div className='flex justify-between bg-white py-2 px-2 border-t-[6px] border-black rounded-t-xl' >
              <span className='text-sm font-medium text-[#213042]'>Account Satement</span>
              <span onClick={() => this.onClickMenu("/app/dashboard")} className='bg-black text-[0.8125rem] font-medium text-white px-2 py-0.5 rounded cursor-pointer'>Back</span>
            </div>
            <div className='bg-[#EEEEEE] p-4'>
              <div className="flex space-x-4">
                <div className="flex items-center space-x-2">
                  <input id="all" type="radio" name="inline-radio-group" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                  <label for="all" className="text-sm font-medium text-gray-900 dark:text-gray-300">All</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input id="profit&lost" type="radio" name="inline-radio-group" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                  <label for="profit&lost" className="text-sm font-medium text-gray-900 dark:text-gray-300">Profit And Loss </label>
                </div>
                <div className="flex items-center space-x-2">
                  <input id="pdc" type="radio" name="inline-radio-group" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                  <label for="pdc" className="text-sm font-medium text-gray-900 dark:text-gray-300">PDC</label>
                </div>
                <div className="flex items-center space-x-2">
                  <input id="accountStatement" type="radio" name="inline-radio-group" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500" />
                  <label for="accountStatement" className="text-sm font-medium text-gray-900 dark:text-gray-300">Account Satement</label>
                </div>
              </div>
            </div>
            <div className='flex space-x-4 bg-[#eeeeee] py-0.5'>
              <input type="date" className="px-3 text-[0.8125rem] bg-white border border-gray-300  md:w-80 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 "
                name="Date"
                dateFormat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.startDate} onChange={this.inputChangeStartDate} />

              <input type="date" className="px-3 text-[0.8125rem] bg-white border border-gray-300  md:w-80 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 "
                name="Date"
                dateFormat="yyyy-mm-dd"
                id="datepicker" required
                value={this.state.endDate} onChange={this.inputChangeEndDate}
              />
              <div className='flex items-center space-x-4 bg-black/10 pr-4'>
                <button onClick={() => this.getStementSubmit()} className=' py-1.5 px-4 bg-[#F7BE27] text-black text-base font-bold'>Filter</button>
                <span className=''><FaFilter /></span>
                <button onClick={() => this.getStementSubmit()} className='py-1.5 px-4 bg-[#D2322E] text-black text-base font-bold'>Clear</button>
                <span className='border bg-white'><RxCross2 /></span>
              </div>
            </div>
            <div className="overflow-hidden pb-24">
              <div className="max-w-full overflow-auto ">
                <div className="inline-block min-w-full ">
                  <div className="w-full ">
                    <div className="w-full capitalize border border-collapse overflow-x-auto">
                      <div className="grid grid-cols-10  text-black text-[0.8125rem] font-semibold bg-[#CCCCCC] border-b border-t divide-x">
                        <div className="px-[6px] py-1  text-left">S.No.</div>
                        <div className="px-[6px] py-1  text-left  ">Date</div>
                        <div className="px-[6px] py-1  text-left col-span-3">Description</div>
                        <div className="px-[6px] py-1  text-right">Prev. Bal	</div>
                        <div className="px-[6px] py-1  text-right ">Credit</div>
                        <div className="px-[6px] py-1  text-right  ">Debit</div>
                        <div className="px-[6px] py-1  text-right  break-all">Comm+	</div>
                        <div className="px-[6px] py-1  text-right   break-all">Balance</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">1</div>
                        <div className="px-[6px] py-2  text-left  ">27 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 break-all">Mobile Charge (Sri Lanka Women v New Zealand Women)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">2</div>
                        <div className="px-[6px] py-2  text-left  ">27 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 ">Mobile Charge (Scotland v Sri Lanka)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">3</div>
                        <div className="px-[6px] py-2  text-left  ">27 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 ">Mobile Charge (Salem Spartans v Lyca Kovai Kings)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">4</div>
                        <div className="px-[6px] py-2  text-left  ">27 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 ">Mobile Charge (Ireland v UAE)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">5</div>
                        <div className="px-[6px] py-2  text-left  ">26 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 ">	Mobile Charge (Zimbabwe v USA)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">6</div>
                        <div className="px-[6px] py-2  text-left  ">24 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 ">Mobile Charge (Nellai Royal Kings v Chepauk Super Gillies)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      <div className="grid grid-cols-10  text-gray-500 text-[0.8125rem]  bg-[#fff] border-b border-t divide-x">
                        <div className="px-[6px] py-2  text-left">7</div>
                        <div className="px-[6px] py-2  text-left  ">24 june 2023</div>
                        <div className="px-[6px] py-2  text-left col-span-3 ">Mobile Charge (Zimbabwe v West Indies)</div>
                        <div className="px-[6px] py-2  text-right">10000</div>
                        <div className="px-[6px] py-2  text-right text-green-500">0</div>
                        <div className="px-[6px] py-2  text-right  text-red-500">0</div>
                        <div className="px-[6px] py-2  text-right text-green-500 ">0</div>
                        <div className="px-[6px] py-2  text-right  ">10000</div>
                      </div>
                      {/* {
                        statement && statement.length > 0 ?
                          statement.map((element, index) => (
                            <React.Fragment >
                              <div className="grid grid-cols-10  text-black text-[0.8125rem] border-b border-t">
                                <div className="px-[6px] py-1 whitespace-nowrap text-left col-span-2 ">{moment(parseInt(element.created_at) * 1000).utcOffset("+05:30").format("DD MMM, YYYY HH:mm A")}</div>
                                {element.amount > 0 ?
                                  <>
                                    <div className="px-[6px] py-1 whitespace-nowrap text-left text-green-600">{element && element.amount ? element.amount : "-"}</div>
                                    <div className="px-[6px] py-1 whitespace-nowrap text-center text-red-600">- -</div>
                                  </> :
                                  <>
                                    <div className="px-[6px] py-1 whitespace-nowrap text-left text-green-600">- -</div>
                                    <div className="px-[6px] py-1 whitespace-nowrap text-center text-red-600">{element && element.amount ? element.amount : "-"}</div>
                                  </>
                                }
                                <div className="px-[6px] py-1 whitespace-nowrap text-right text-green-600">{element && element.available_balance ? element.available_balance : "-"}</div>
                                <div className="px-[6px] py-1 whitespace-nowrap text-left col-span-5 ">{element && element.description ? element.description : "-"}</div>
                              </div>

                            </React.Fragment>
                          ))
                          :
                          <div className="text-[#495057] text-sm bg-white hover:bg-gray-100">
                            <div className="py-1 px-[6px] whitespace-nowrap text-center"> There are no records to show</div>
                          </div>
                      } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </>

    );
  }
}

function mapStateToProps(state) {
  const { users } = state;

  console.log("users:::ACTIVE_MATCH::", users);

  return {
    users
  };
}

export default connect(mapStateToProps)(AccountSatement);
