import React from 'react';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import BetPlaceDesktop from "../../components/BetPlaceDesktop/BetPlaceDesktop";
import SidebarRight from '../../components/SidebarRight/SidebarRight';
import EditformModal from "../../casino_components/EditformModal/EditformModal";
import BetSlipTab from "../../casino_components/BetSlipTab/BetSlipTab";

class SattaEventDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      dropdown1: false,
      dropdown2: false,
      editStake: false,
      betPlaceModal: false,
      betSlipData: {},
      count: 0,
    }
  }
  componentDidMount() {

    // let data = {
    //   "limit": 10,
    //   "pageno": 1
    // }
    // let data1 = {
    //   "limit": 1,
    //   "pageno": 1,
    //   "sport_id": 111,
    //   "series_id": 0
    // }
    // let data2 = {
    //   "limit": 10,
    //   "pageno": 1,
    //   "sport_id": this.props.match.params.sportId,
    //   "series_id": this.props.match.params.seriesId ? this.props.match.params.seriesId : 0
    // }
    // this.props.dispatch(userActions.game_profile(data));
    // this.props.dispatch(userActions.games_list(data));
    // this.props.dispatch(userActions.event_casino(data1));
    // this.props.dispatch(userActions.event_game(data2));
    // this.interval = setInterval(() => this.loadData(), 15000);
  }

  loadData = () => {
    // let data = {
    //   "limit": 10,
    //   "pageno": 1
    // }
    // let data1 = {
    //   "limit": 1,
    //   "pageno": 1,
    //   "sport_id": 111,
    //   "series_id": 0
    // }
    // this.props.dispatch(userActions.game_profile(data));
    // this.props.dispatch(userActions.games_list(data));
    // this.props.dispatch(userActions.event_casino(data1));
  }

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log("name, value  ", name, value);
    let { betSlipData } = this.state;
    betSlipData.stake = value
    this.setState({
      betSlipData: betSlipData,
    })
  }

  increaseCount = () => {
    this.setState((prevState) => ({
      count: prevState.count + 0.01,
    }));
  };

  decreaseCount = () => {
    this.setState((prevState) => ({
      count: prevState.count - 0.01,
    }));
  };

  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = () => {
    // let { users } = this.props;
    // let { getCasinoByEventId } = users;
    // let { shortName } = getCasinoByEventId ? getCasinoByEventId : {}
    // let { eventId } = getCasinoByEventId ? getCasinoByEventId : {}

    let betObject = {
      "roundId": this.state.betSlipData.data.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": this.state.betSlipData.stake,
      // "casinoType": shortName,
      // "eventId": eventId
    }
    // this.props.dispatch(userActions.saveBetNew(betObject, this.props));
    console.log("betObjectbetObjectbetObject", betObject);
  };

  handledropdown1 = () => {
    this.setState({ dropdown1: !this.state.dropdown1, dropdown2: false, });
  };

  handledropdown2 = () => {
    this.setState({ dropdown1: false, dropdown2: !this.state.dropdown2 });
  };

  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake })
  }

  handleBackOpen = (data) => {
    this.setState({ betPlaceModal: true, betSlipData: { ...data, stake: "0" }, count: data.odds });
  };

  handleClose = () => {
    this.setState({ betPlaceModal: false });
  };

  render() {
    let { users } = this.props;
    let { eventcasinoItems } = users;

    console.log('RENDER_____111___', eventcasinoItems);
    console.log("RENDER_________game_profileItems:1111:::", this.state);

    console.log('dsetStatesetStateItemgameItemgameItem', this.state.gameItem);

    const Card0 = {
      type: "Yes",
      rate: 0.95,
      nat: "0",
      gstatus: "0"
    }
    const Card1 = {
      type: "Yes",
      rate: 0.95,
      nat: "1",
      gstatus: "0"
    }
    const Card2 = {
      type: "Yes",
      rate: 0.95,
      nat: "2",
      gstatus: "0"
    }
    const Card3 = {
      type: "Yes",
      rate: 0.95,
      nat: "3",
      gstatus: "0"
    }
    const Card4 = {
      type: "Yes",
      rate: 0.95,
      nat: "4",
      gstatus: "0"
    }

    return (
      <div className='relative w-full bg-[#EEEEEE] h-full page_bg overflow-y-auto'>
        <div className='flex space-x-1 lg:p-1'>
          <Sidebar
            open={true}
            gameItem={this.state.gameItem}
            // setOpenMobile={setOpenMobile}
            // openMobile={openMobile}
            props={this.props}
            showSport={true}
          />
          <div className="lg:flex w-full space-x-1">
            <div className='overflow-y-auto lg:w-[75%] w-full space-y-1 md:pb-20'>
              <div className='flex justify-between px-3  py-3 text-[15px] bg-[#2A2A2A] text-white '>
                <div>Satta</div>
                <div>27-Jun-2023 12:17 PM</div>
              </div>
              <div className='bg-[#A2A2A2]'>
                <div className='w-full flex justify-center '>
                  <button onClick={() => this.handledropdown1()} className='bg-[#31745E] w-full py-1.5  text-white active:bg-white active:text-black'>Single Patti</button>
                  <button onClick={() => this.handledropdown2()} className='bg-[#31745E] w-full py-1.5 text-white active:bg-white active:text-black'>Harup Andar Bahar</button>
                </div>
                <div className='bg-[#FFFFFF] lg:py-1 pt-0.5 pb-3 cursor-pointer rounded '>
                  <p className='text-black text-center text-[19px]  lg:text-[22px] uppercase'>Faridabad-30-05-2023</p>
                  <p className='text-black text-center py-1.5 text-[16px]'>Open Time : 30-May-2023 09:00 AM | Close Time: 30-May-2023 05:30 PM</p>
                </div>
                {this.state.dropdown1 === true ?
                  <div className='bg-[#FFFFFF]'>
                    <p className='text-white mx-2 bg-black text-center leading-6 text-[20px]'>SINGLE PATTI</p>
                    <div className='px-5 pt-2 pb-4 grid grid-cols-3 md:grid-cols-4 gap-7'>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        05
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        06
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        07
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        08
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        09
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        10
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        11
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        12
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        13
                      </div>
                    </div>
                  </div>
                  : null}
                {this.state.dropdown2 === true ?
                  <div className='bg-[#FFFFFF]'>
                    <p className='text-white mx-2 bg-black text-center leading-6 text-[20px]'>HARUP ANDAR</p>
                    <div className='px-5 pt-2 pb-4 grid grid-cols-3 md:grid-cols-4 gap-7'>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        5
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        6
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        7
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        8
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        9
                      </div>
                    </div>
                    <p className='text-white mx-2 bg-black text-center leading-6 text-[20px]'>HARUP BAHAR</p>
                    <div className='px-5 pt-2 pb-4 grid grid-cols-3 md:grid-cols-4 gap-7'>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        5
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        6
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        7
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        8
                      </div>
                      <div className='bg-[#17A2B8] text-white  py-2 rounded text-center hover:bg-[#128597] cursor-pointer'>
                        9
                      </div>
                    </div>
                  </div>
                  : null}
              </div>
              <div className="lg:hidden block fixed z-60 bottom-16 w-full ">
                {this.state.betPlaceModal === true ?
                  <BetPlaceDesktop
                    betSlipData={this.state.betSlipData}
                    updateStackOnclick={this.updateStackOnclick}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    count={this.state.count}
                  />
                  : null}
              </div>
            </div>
            <div className="w-[25%] lg:block hidden">
              {this.state.betPlaceModal === true ?
                <BetPlaceDesktop
                  betSlipData={this.state.betSlipData}
                  updateStackOnclick={this.updateStackOnclick}
                  inputChange={this.inputChange}
                  decreaseCount={this.decreaseCount}
                  increaseCount={this.increaseCount}
                  placeBet={this.placeBet}
                  handleClose={this.handleClose}
                  count={this.state.count}
                />
                : null}
              <BetSlipTab setEditStakeOpen={this.setEditStakeOpen} />
              <SidebarRight />
            </div>
            {this.state.editStake === true ?
              <EditformModal setEditStakeOpen={this.setEditStakeOpen} />
              : null
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(SattaEventDetails);

