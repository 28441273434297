import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { AiFillCaretDown } from 'react-icons/ai';
import { FaWallet } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { userActions } from '../../_actions';
import { BsWallet } from "react-icons/bs";

import { IoMenuOutline } from "react-icons/io5";

const Header = (props) => {
  let { opensizebar, setopensizebar } = props;
  const [navbarOpen, setNavbarOpen] = useState(false);

  const dispatch = useDispatch();
  const onClickMenutwo = (url) => {
    localStorage.removeItem('user');
    props.history.push(url);
  }

  const navigate = (url) => {
    props.history.push(url);
    setOpenModal(!openModal);
  }

  const [clickedOutside, setClickedOutside] = useState(false);
  const myRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setClickedOutside(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [myRef]);
  useEffect(() => {
    dispatch(userActions.getProfile());
  }, [dispatch]);
  const [openModal, setOpenModal] = useState(false);
  let user = JSON.parse(localStorage.getItem('spuser'));
  const { users } = props;
  const { userAccountDetailsItems, getProfile } = users;
  return (
    <div>
      <div className='bg-[#17273b] w-full border-b'>
        <div className="flex items-center justify-between md:sticky top-0 z-40 h-[60px] lg:h-[76px] px-5 relative w-full">
          <div className='flex justify-start items-center lg:pl-10'>
            <IoMenuOutline
              onClick={() => setopensizebar((prev) => !prev)}
              className='text-white absolute cursor-pointer left-1 md:left-4 text-2xl font-bold'
            />
            <img
              onClick={() => navigate("/app/dashboard")}
              src='/images/logo.png'
              alt='logo'
              className='lg:h-[40px] h-[40px] ml-4 md:ml-1 cursor-pointer'
            />
          </div>
          <div className='flex space-x-1 items-center'>
            <div className='text-white md:relative'>
              <div className='flex items-center lg:space-x-20 space-x-5'>
                <div className='flex items-center gap-1 text-[10px] lg:text-sm text-[#17273b] bg-white border p-1 shadow-sm leading-none rounded cursor-pointer'>
                  <FaWallet size={24} />
                  <span className='text-base font-bold'><span className=' font-bold'>₹</span> {getProfile && getProfile.wallet ? Number(getProfile?.wallet).toFixed(2) : "0"}</span>
                </div>
                {/* <div
                  ref={myRef}
                  onClick={() => setClickedOutside(!clickedOutside)}
                  className='bg-white text-black flex items-center space-x-2 p-2 rounded cursor-pointer'
                >
                  <FaUser />
                  <AiFillCaretDown size={12} />
                  
                  {clickedOutside &&
                <div className='absolute mt-0.5 rounded md:right-2 md:top-8 right-10 top-10 shadow-2xl text-black bg-white w-[180px] md:mx-0 mr-[2%] ml-[2%] text-sm transition duration-2000 z-40'>
                  <div className='py-0 w-full divide-y divide-red-700/50'>
                    <div className='text-base px-4 py-1.5 w-full cursor-pointer'>
                      {getProfile && getProfile.userName ? getProfile.userName : 'User'}
                    </div>
                    <div className='py-2 w-full'>
                      <div
                        onClick={() => navigate("/app/changepassword")}
                        className='text-sm px-4 py-1 w-full flex justify-start items-center space-x-2 cursor-pointer'
                      >
                        <FaLock size={12} />
                        <span>Change Password</span>
                      </div>
                      <div
                        onClick={() => onClickMenutwo("/login")}
                        className='text-sm px-4 py-1 w-full flex justify-start items-center space-x-2 cursor-pointer'
                      >
                        <FaSignOutAlt size={12} />
                        <span>Log Out</span>
                      </div>
                      <div
                        onClick={() => onClickMenutwo("/login")}
                        className='text-sm md:hidden  px-4 py-1 w-full flex justify-start items-center space-x-2 cursor-pointer'
                      >
                        <BsWallet size={12} />
                        <span className=' flex justify-center items-center'>₹ {getProfile && getProfile.wallet ? getProfile.wallet : "0"}</span>
                      </div>




                  
                    </div>
                  </div>
                </div>
              }
                </div> */}
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* <SubHeader /> */}
    </div>
  );
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Header);
