export const creditWithdrawConstants = {

    CHILD_LIST_USER_REQUEST: 'CHILD_LIST_USER_REQUEST',
    CHILD_LIST_USER_SUCCESS: 'CHILD_LIST_USER_SUCCESS',
    CHILD_LIST_USER_FAILURE: 'CHILD_LIST_USER_FAILURE',

    CHILD_LIST_ACTIVE_USER_CREDIT_REQUEST: 'CHILD_LIST_ACTIVE_USER_CREDIT_REQUEST',
    CHILD_LIST_ACTIVE_USER_CREDIT_SUCCESS: 'CHILD_LIST_ACTIVE_USER_CREDIT_SUCCESS',
    CHILD_LIST_ACTIVE_USER_CREDIT_FAILURE: 'CHILD_LIST_ACTIVE_USER_CREDIT_FAILURE',

    UPDATE_USER_INFO_REQUEST: 'UPDATE_USER_INFO_REQUEST',
    UPDATE_USER_INFO_SUCCESS: 'UPDATE_USER_INFO_SUCCESS',
    UPDATE_USER_INFO_FAILURE: 'UPDATE_USER_INFO_FAILURE',

    UPDATE_BET_ACCOUNT_STATUS_REQUEST: 'UPDATE_BET_ACCOUNT_STATUS_REQUEST',
    UPDATE_BET_ACCOUNT_STATUS_SUCCESS: 'UPDATE_BET_ACCOUNT_STATUS_SUCCESS',
    UPDATE_BET_ACCOUNT_STATUS_FAILURE: 'UPDATE_BET_ACCOUNT_STATUS_FAILURE',

    CHANGE_PASSOWRD_REQUEST: 'CHANGE_PASSOWRD_REQUEST',
    CHANGE_PASSOWRD_SUCCESS: 'CHANGE_PASSOWRD_SUCCESS',
    CHANGE_PASSOWRD_FAILURE: 'CHANGE_PASSOWRD_FAILURE',

    CHILD_PROFILE_REQUEST: 'CHILD_PROFILE_REQUEST',
    CHILD_PROFILE_SUCCESS: 'CHILD_PROFILE_SUCCESS',
    CHILD_PROFILE_FAILURE: 'CHILD_PROFILE_FAILURE',

    WITHDRAW_CHIPS_PNL_REQUEST: 'WITHDRAW_CHIPS_PNL_REQUEST',
    WITHDRAW_CHIPS_PNL_SUCCESS: 'WITHDRAW_CHIPS_PNL_SUCCESS',
    WITHDRAW_CHIPS_PNL_FAILURE: 'WITHDRAW_CHIPS_PNL_FAILURE',

    DEPOSIT_CHIPS_PNL_REQUEST: 'DEPOSIT_CHIPS_PNL_REQUEST',
    DEPOSIT_CHIPS_PNL_SUCCESS: 'DEPOSIT_CHIPS_PNL_SUCCESS',
    DEPOSIT_CHIPS_PNL_FAILURE: 'DEPOSIT_CHIPS_PNL_FAILURE',

    DEPOSIT_WITHDRAW_DATA_REQUEST: 'DEPOSIT_WITHDRAW_DATA_REQUEST',
    DEPOSIT_WITHDRAW_DATA_SUCCESS: 'DEPOSIT_WITHDRAW_DATA_SUCCESS',
    DEPOSIT_WITHDRAW_DATA_FAILURE: 'DEPOSIT_WITHDRAW_DATA_FAILURE',

    GETALL_CREDIT_WITHDRAW_REQUEST: 'GETALL_CREDIT_WITHDRAW_REQUEST',
    GETALL_CREDIT_WITHDRAW_SUCCESS: 'GETALL_CREDIT_WITHDRAW_SUCCESS',
    GETALL_CREDIT_WITHDRAW_FAILURE: 'GETALL_CREDIT_WITHDRAW_FAILURE',

    GET_LIST_CREDIT_WITHDRAW_REQUEST: 'GET_LIST_CREDIT_WITHDRAW_REQUEST',
    GET_LIST_CREDIT_WITHDRAW_SUCCESS: 'GET_LIST_CREDIT_WITHDRAW_SUCCESS',
    GET_LIST_CREDIT_WITHDRAW_FAILURE: 'GET_LIST_CREDIT_WITHDRAW_FAILURE',

    ADD_CREDIT_WITHDRAW_REQUEST: 'ADD_CREDIT_WITHDRAW_REQUEST',
    ADD_CREDIT_WITHDRAW_SUCCESS: 'ADD_CREDIT_WITHDRAW_SUCCESS',
    ADD_CREDIT_WITHDRAW_FAILURE: 'ADD_CREDIT_WITHDRAW_FAILURE',

    DELETE_CREDIT_WITHDRAW_REQUEST: 'DELETE_CREDIT_WITHDRAW_REQUEST',
    DELETE_CREDIT_WITHDRAW_SUCCESS: 'DELETE_CREDIT_WITHDRAW_SUCCESS',
    DELETE_CREDIT_WITHDRAW_FAILURE: 'DELETE_CREDIT_WITHDRAW_FAILURE',

    UPDATE_CREDIT_WITHDRAW_REQUEST: 'UPDATE_CREDIT_WITHDRAW_REQUEST',
    UPDATE_CREDIT_WITHDRAW_SUCCESS: 'UPDATE_CREDIT_WITHDRAW_SUCCESS',
    UPDATE_CREDIT_WITHDRAW_FAILURE: 'UPDATE_CREDIT_WITHDRAW_FAILURE',

    DISABLE_CREDIT_WITHDRAW_REQUEST: 'DISABLE_CREDIT_WITHDRAW_REQUEST',
    DISABLE_CREDIT_WITHDRAW_SUCCESS: 'DISABLE_CREDIT_WITHDRAW_SUCCESS',
    DISABLE_CREDIT_WITHDRAW_FAILURE: 'DISABLE_CREDIT_WITHDRAW_FAILURE',

    CREDIT_DATA_REQUEST: 'CREDIT_DATA_REQUEST',
    CREDIT_DATA_SUCCESS: 'CREDIT_DATA_SUCCESS',
    CREDIT_DATA_FAILURE: 'CREDIT_DATA_FAILURE',

    DEPOSIT_CREDIT_REQUEST: 'DEPOSIT_CREDIT_REQUEST',
    DEPOSIT_CREDIT_SUCCESS: 'DEPOSIT_CREDIT_SUCCESS',
    DEPOSIT_CREDIT_FAILURE: 'DEPOSIT_CREDIT_FAILURE',

    ACCOUNT_HISTORY_REQUEST: 'ACCOUNT_HISTORY_REQUEST',
    ACCOUNT_HISTORY_SUCCESS: 'ACCOUNT_HISTORY_SUCCESS',
    ACCOUNT_HISTORY_FAILURE: 'ACCOUNT_HISTORY_FAILURE',

};
