import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { FaLock, FaUserAlt } from "react-icons/fa";
import { BiLogIn } from "react-icons/bi";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import Loader from "../../components/Loader/Loader";
class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      passtype: "password",
      visible: false,
    };
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        userName: this.state.fieldslogin.username,
        password: this.state.fieldslogin.password,
      };
      this.props.dispatch(userActions.login(data, this.props));
      // this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"] || fieldslogin["username"].trim() == "") {
      formIsValid = false;
      errorslogin["username"] = "User Name Cannot Be Blank.";
    } else if (fieldslogin["username"].includes(" ")) {
      formIsValid = false;
      errorslogin["username"] = "Spaces are not allowed in the  Name.";
    }
    //password
    if (!fieldslogin["password"] || fieldslogin["password"].trim() == "") {
      formIsValid = false;
      errorslogin["password"] = "Password Cannot Be Blank.";
    }
    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleVisible = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  }



  render() {
    let { authentication } = this.props;
    console.log(authentication)
    // console.log(users)
    let { loading } = authentication ? authentication : {};

    return (
      <div className=" bg-black  overflow-hidden  flex justify-center items-center ">
        <Loader loading={loading} />
        <div style={{ backgroundImage: 'url("/images/login-bg.jpg")' }} className="flex   justify-center  lg:w-[50vw] w-full  items-center h-screen">
          <div className="flex flex-col space-y-4  p-8 h-full  md:border   rounded-lg  justify-center items-center  ">
            <div className=" py-10">
              <img src="/images/logo.png" />
            </div>

            <div>
              <h1 className="text-2xl font-bold text-white">Welcome To POCKET-X</h1>
            </div>
            <div className="w-96 p-4">

              <div className=" w-full flex mb-2  flex-col">
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={this.state.fieldslogin.username}
                  className="w-full px-2 py-3 rounded-md focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500"
                  placeholder="Username"
                  onChange={this.inputChange}
                  maxLength={30}
                />
                {this.state.errorslogin &&
                  this.state.errorslogin["username"] ? (
                  <div className="text-red-500 text-left text-sm">
                    {this.state.errorslogin["username"]}
                  </div>
                ) : null}
              </div>
              <div className="w-full  relative">
                <div className=" relative">

                  <input
                    type={this.state.visible ? "text" : "password"}
                    name="password"
                    maxLength={30}
                    id="password"
                    value={this.state.fieldslogin.password}
                    className="w-full px-2 py-3  rounded-md focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500" placeholder="Password"
                    onChange={this.inputChange}
                  />
                  {this.state.visible ? <MdOutlineRemoveRedEye onClick={this.handleVisible} className=" absolute bottom-5 cursor-pointer  right-2" /> : <FaRegEyeSlash onClick={this.handleVisible} className=" absolute  bottom-5 cursor-pointer right-2" />}
                </div>
                {this.state.errorslogin &&
                  this.state.errorslogin["password"] ? (
                  <div className="text-red-500 whitespace-nowrap text-left text-sm">
                    {this.state.errorslogin["password"]}
                  </div>
                ) : null}
              </div>
              {/* <div className=" my-2 text-blue-500  flex justify-between items-center ">
              <Link to={'/register'} className=" cursor-pointer">Register User</Link>
              <Link to={'/forgotPassword'} className=" cursor-pointer">Forgot Password</Link>
              </div> */}

              <div className="w-full flex justify-center items-center">
                <button type="button" onClick={this.loginSubmit}
                  className="flex justify-center items-center py-3 font-bold bg-[#315683] w-full text-white  text-xl  rounded-lg mt-5">
                  <span className="tracking-wider ">Login</span>
                </button>
              </div>

            </div>



          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users, authentication } = state;
  return {
    loggingIn,
    otpSent,
    user,
    authentication,
    users,
  };
}
export default connect(mapStateToProps)(Login);
