import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import Sidebar from "../../components/Sidebar/Sidebar";
import { BsGraphUp, BsArrowDownShort, BsInfoCircleFill } from 'react-icons/bs';
import { IoIosArrowDown } from 'react-icons/io';
import BetPlaceDesktop from '../../components/BetPlaceDesktop/BetPlaceDesktop';
import SidebarRight from "../../components/SidebarRight/SidebarRight";
import EditformModal from "../../casino_components/EditformModal/EditformModal";
import BetSlipTab from "../../casino_components/BetSlipTab/BetSlipTab";

class Cricketdetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      count: 0,
      finalMatchStack: [],
      editStake: false,
      tv: false,
      tv2: false,
      currentIndex: 0,
      otherstake: false,
      betSlipData: {},
      rundata: [],
      fieldsbet: {},
      errorsbet: {},
    };
  }

  componentDidMount() {
    let data3 = {
      market_id: this.props.match.params.market_id,
    };
    this.props.dispatch(userActions.getMarket(data3));
    console.log("data3data3data3data3", data3);
    this.interval = setInterval(() => this.loadData(), 5000);
  }
  loadData = () => {
    let data3 = {
      market_id: this.props.match.params.market_id,
    };
    this.props.dispatch(userActions.getMarket(data3));
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  increaseCount = () => {
    this.setState((prevState) => ({
      count: prevState.count + 0.01,
    }));
  };

  decreaseCount = () => {
    this.setState((prevState) => ({
      count: prevState.count - 0.01,
    }));
  };

  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log("name, value  ", name, value);

    let { betSlipData } = this.state;
    betSlipData.stake = value
    this.setState({
      betSlipData: betSlipData,
    })
  }


  updateStackOnclick = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = () => {
    // let { users } = this.props;
    // let { getCasinoByEventId } = users;
    // let { shortName } = getCasinoByEventId ? getCasinoByEventId : {}
    // let { eventId } = getCasinoByEventId ? getCasinoByEventId : {}

    let betObject = {
      "roundId": this.state.betSlipData.data.mid,
      "sid": this.state.betSlipData.data.sid,
      "rate": this.state.count + "",
      "amount": this.state.betSlipData.stake,
      // "casinoType": shortName,
      // "eventId": eventId
    }
    // this.props.dispatch(userActions.saveBetNew(betObject, this.props));
    console.log("betObjectbetObjectbetObject", betObject);
  };

  handleBackOpen = (data) => {
    this.setState({ backBetModal: true, betSlipData: { ...data, stake: "0" }, count: data.odds });
  };

  handleClose = () => {
    this.setState({ backBetModal: false, backBetModal2: false });
  };

  handleTv = () => {
    this.setState({ tv: !this.state.tv })
  }
  handleTv2 = () => {
    this.setState({ tv2: !this.state.tv2 })
  }

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake })
  }

  render() {
    let { users } = this.props;
    let { getMarket } = users;
    let { result } = getMarket ? getMarket : {};
    console.log("getMarketgetMarketgetMarketgetMarketgetMarket", getMarket);

    return (
      <>
        <div className="relative w-full h-full page_bg overflow-y-auto flex space-x-1 lg:p-1">
          <Sidebar
            open={true}
            sportId={this.props.match.params.sportId}
            seriesId={this.props.match.params.seriesId}
            match_id={this.props.match.params.match_id}
            props={this.props}
          />
          <div className="w-full">
            <div className="lg:flex w-full space-x-1">
              <div className="lg:w-[75%] w-full">
                <div className='bg-black border-t border-green-400 rounded'>
                  <div onClick={() => this.handleTv()} className='px-2 cursor-pointer flex justify-between items-center '>
                    <div className='flex space-x-2 px-2 pt-3 pb-2'>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                      <p className='text-white text-sm'> Live Tv 1</p>
                    </div>
                    <div><IoIosArrowDown size={20} className={`text-white ${this.state.tv === true ? "rotate-180" : "rotate-0"}`} /></div>
                  </div>
                  {this.state.tv === true ?
                    <div className='flex justify-center items-center'>
                      <img className='w-[300px] ' src="/images/tv.png" alt="" />
                    </div>
                    : null}
                </div>

                <div className='bg-black border-t border-white rounded lg:hidden block'>
                  <div onClick={() => this.handleTv2()} className='px-2 cursor-pointer flex justify-between items-center '>
                    <div className='flex space-x-2 px-2 pt-3 pb-2'>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                      <p className='text-white text-sm'> Live Tv 2</p>
                    </div>
                    <div><IoIosArrowDown size={20} className={`text-white ${this.state.tv2 === true ? "rotate-180" : "rotate-0"}`} /></div>
                  </div>
                  {this.state.tv2 === true ?
                    <div className='flex justify-center items-center'>
                      <img className='w-[300px] ' src="/images/tv.png" alt="" />
                    </div>
                    : null}
                </div>

                {/* get-match socre */}
                {result && result !== null ? (
                  <>
                    <div class="w-full font-semibold text-[13px] text-black bg-[black] rounded-t-3xl py-[3px] border border-white mr-[2px]"></div>
                    <div className="flex  justify-between px-2 items-center  border-b-2  border-white py-5 bg-[#252328] text-white h-[128px]">
                      <div className="text-left text-[14px] w-full">
                        <div className="flex space-x-2 justify-start items-center">
                          {/* <div>
                            <img className="w-6 h-6 rounded-full object-cover " src={result && result.score && result.score.team1_img ? result.score.team1_img : "0"} alt="" />
                          </div> */}
                          <p className="font-semibold text-[#f98f19]">
                            {result && result.score && result.score.team1_name ? result.score.team1_name : "0"}
                          </p>
                        </div>
                        <div className="font-semibold text-[#E9D125]">
                          {result && result.score && result.score.team1_score ? result.score.team1_score : "0 - 0"}
                        </div>
                        <div>
                          {result && result.score && result.score.team1_over ? result.score.team1_over : "0"}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="px-2 text-sm">
                          <p className="text-[14px] text-center">
                            {result && result.score && result.score.toss ? result.score.toss : "not play"}</p>
                        </div>
                        <div className="bg-black rounded-lg py-4">
                          <h2 className="text-[#E8FE26] text-lg font-bold text-center">{result && result.score && result.score.Status ? result.score.Status : "not played yet"}</h2>
                        </div>
                        <div className="flex justify-center items-center text-[14px] space-x-2">
                          This over: {" "}
                          <span className="flex justify-start items-center space-x-2 px-1 py-1">
                            {result && result.score && result.score.balls_array && result.score.balls_array.length > 0 ? result.score.balls_array.map((elementtemp, index) => (<>
                              {elementtemp < 4 ?
                                <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div>
                                : <>
                                  {elementtemp === "4" ?
                                    <div className=" bg-[#6C9CE6] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === "1w" ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === "6" ?
                                    <div className=" bg-[#418019] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === "w" ?
                                    <div className=" bg-red-500 w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                </>}
                            </>
                            )) : "0"}
                          </span>
                        </div>
                      </div>
                      <div className="text-right text-[14px] w-full">
                        <div className="flex space-x-2 justify-end items-center">
                          <p className="font-semibold text-[#f98f19]">
                            {result && result.score && result.score.team2_name ? result.score.team2_name : "0"}
                          </p>
                          {/* <div>
                            <img className="w-6 h-6 rounded-full object-cover " src={result && result.score && result.score.team2_img ? result.score.team2_img : "0"} alt="" />
                          </div> */}
                        </div>
                        <div className="font-semibold text-[#E9D125]">
                          {result && result.score && result.score.team2_score ? result.score.team2_score : "0-0"}
                        </div>
                        <div>
                          {result && result.score && result.score.team2_over ? result.score.team2_over : "0"}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* team Desktop data */}
                <div className="lg:block hidden ">
                  <div className="bg-black flex justify-between items-center py-1.5 px-1 text-white text-[12px] font-medium">
                    <p>
                      {result && result.team_data && result.team_data[0] && result.team_data[0].team_name ? result.team_data[0].team_name : "NA"} v  {result && result.team_data && result.team_data[1] && result.team_data[1].team_name ? result.team_data[1].team_name : "NA"}/
                      <span className=''>Match Odds</span>  <span>Min :250  Max :300000</span>
                    </p>
                    <div className='justify-center flex items-center space-x-2'>
                      <p className='text-white w-5 h-5 rounded-full  justify-center flex items-center bg-[#E2C606]' >FS</p>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                    </div>
                  </div>
                  {/* <BackLayDesktop /> */}

                  <table className="w-full bg-white ">
                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                        </td>true
                        <td>
                          <div className="flex justify-end items-center ">
                            <div class="text-center flex justify-end items-center pt-2">
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                              <div class="w-24 text-center py-0.5 cursor-pointer">
                                <span className="text-[16px]">LAGAI</span>
                                <span className="flex justify-center items-center">
                                  <BsArrowDownShort className='bg-[#4fa0e7] text-white h-4 w-4 rounded-full' size={16} />
                                </span>
                              </div>
                              <div class="w-24 lg:pb-3 flex space-x-2 py-0.5 cursor-pointer">
                                <span className="text-[16px]">KHAI</span>
                                <span><BsArrowDownShort className='bg-[#FAA9BA] text-white h-4 w-4 rounded-full' size={16} /></span>
                              </div>
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      {result && result.team_data && result.team_data.length > 0 ? result.team_data.map((element, index) => (
                        <tr className="border-b border-gray-200">
                          <td className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                            <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span>
                              {element.team_name}
                            </span>
                            <p className='text-sky-500'>0.00</p>
                          </td>
                          <td>
                            <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                              <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                <div onClick={() => this.handleBackOpen({ data: element, type: "LAGAI", selectionId: element.selectionId, odds: element.lgaai, nat: element.team_name })}
                                  className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                  <span className="text-[13px] font-semibold">{element && element.lgaai ? element.lgaai : "0.00"}</span><br />
                                  <span className="text-[10px]">0</span>
                                </div>
                                <div onClick={() => this.handleBackOpen({ data: element, type: "KHAI", selectionId: element.selectionId, odds: element.khaai, nat: element.team_name })}
                                  className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                  <span className="text-[13px] font-semibold">
                                    {element && element.khaai ? element.khaai : "0.00"}
                                  </span><br />
                                  <span className="text-[10px]">0</span>
                                </div>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer">
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer" >
                              </div>
                            </div>
                          </td>
                        </tr>
                      )) : "NA"}
                    </tbody>
                  </table>
                </div>

                {/* team mobile data */}
                <div className="lg:hidden block  bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 px-1 text-white text-[12px] font-medium">
                    <p>
                      {result && result.team_data && result.team_data[0] && result.team_data[0].team_name ? result.team_data[0].team_name : "NA"} v  {result && result.team_data && result.team_data[1] && result.team_data[1].team_name ? result.team_data[1].team_name : "NA"} /

                      <span className=''>Match Odds</span>  <span>Min :250  Max :300000</span>
                    </p>

                    <div className='justify-center flex items-center space-x-2'>
                      <p className='text-white w-5 h-5 rounded-full  justify-center flex items-center bg-[#E2C606]' >FS</p>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                    </div>
                  </div>
                  {/* <BackLayDesktop /> */}

                  <div className="w-full bg-white ">
                    <div>
                      <div className="border-b border-gray-200 w-full">
                        <div className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                        </div>
                        <div className="w-full">
                          <div className="flex justify-end items-center ">
                            <div class="text-center flex justify-end items-center pt-2">
                              <div class="w-24 text-center py-0.5 cursor-pointer">
                                <span className="text-[16px]">LAGAI</span>
                                <br />
                                <span className="flex justify-center items-center">
                                  <BsArrowDownShort className='bg-[#4fa0e7] text-white h-4 w-4 rounded-full' size={16} />
                                </span>
                              </div>
                              <div class="w-24 lg:pb-3 flex space-x-2 py-0.5 cursor-pointer">
                                <span className="text-[16px]">KHAI </span>
                                <span><BsArrowDownShort className='bg-[#FAA9BA] text-white h-4 w-4 rounded-full' size={16} /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {result && result.team_data && result.team_data.length > 0 ? result.team_data.map((element, index) => (
                        <div className="border-b border-gray-200 flex justify-between items-center">
                          <div className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                            <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span>
                              {element && element.team_name ? element.team_name : "-"}
                            </span>
                            <p className='text-sky-500'>0.00</p>
                          </div>
                          <div>
                            <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                              <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                <div onClick={() => this.handleBackOpen({ data: element, type: "LAGAI", selectionId: element.selectionId, odds: element.lgaai, nat: element.team_name })} className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                  <span className="text-[13px] font-semibold">
                                    {element && element.lgaai ? element.lgaai : "0.00"}
                                  </span><br />
                                  <span className="text-[10px]">0</span>
                                </div>
                                <div onClick={() => this.handleBackOpen({ data: element, type: "KHAI", selectionId: element.selectionId, odds: element.khaai, nat: element.team_name })} className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                  <span className="text-[13px] font-semibold">
                                    {element && element.khaai ? element.khaai : "0.00"}
                                  </span><br />
                                  <span className="text-[10px]">0</span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      )) : "NA"}
                    </div>
                  </div>
                </div>

                {/* toss Desktop data */}
                {result && result.toss_data && result.toss_data.length > 0 ?
                  <div className="lg:block hidden bg-white">
                    <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                      <p>Toss Book </p>
                      <div class="text-center flex justify-end items-center pt-2">
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">
                          {result.toss_data[0].team_name ? result.toss_data[0].team_name : "NA"}
                        </div>
                        <div class="w-24 font-semibold text-[13px] text-white py-[3px]">
                          {result.toss_data[1].team_name ? result.toss_data[1].team_name : "NA"}
                        </div>
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      </div>
                    </div>
                    <table className="w-full ">
                      <tbody>
                        <tr>
                          <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                            <span>WHO WILL WIN THE TOSS </span>
                          </td>
                          <td>
                            <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                              <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                <div onClick={() => this.handleBackOpen({ data: result.toss_data[0], type: "LAGAI", selectionId: result.toss_data[0].selectionId, })} className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                  <span className="text-[13px] font-semibold">
                                    {result.toss_data[0].lgaai}
                                  </span><br />
                                  <span className="text-[10px]">
                                    {result.toss_data[0].khaai}
                                  </span>
                                </div>
                                <div onClick={() => this.handleBackOpen({ data: result.toss_data[1], type: "KHAI", selectionId: result.toss_data[1].selectionId })} className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                  <span className="text-[13px] font-semibold">
                                    {result.toss_data[0].lgaai}
                                  </span><br />
                                  <span className="text-[10px]">
                                    {result.toss_data[0].khaai}
                                  </span>
                                </div>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer">
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer" >
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div> : <></>}

                {/* Fancy Desktop data */}
                {result && result.commission_fancy_data && result.commission_fancy_data !== null ? (
                  <div className="lg:block hidden bg-white">
                    <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                      <p>Fancy<span className='text-xs'>Min :250  Max :300000</span></p>
                      <div class="text-center flex justify-end items-center pt-2">
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                        <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      </div>
                    </div>
                    {result.commission_fancy_data.length > 0 ? result.commission_fancy_data.map((element, index) => (
                      <div className="w-full border-b border-gray-200 flex justify-between items-center">
                        <div className="flex justify-between items-center h-full text-[13px] text-gray-800 font-medium px-2 py-4 w-full">
                          <div>{element && element.session_name ? element.session_name : "--"} </div>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                          <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                            <div onClick={() => this.handleBackOpen({ data: element, type: "LAGAI", selectionId: element.selectionId, odds: element.oddsNo, nat: element.session_name })} className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                              <span className="text-[13px] font-semibold">{element && element.runsNo ? element.runsNo : "-"}</span><br />
                              <span className="text-[10px]">{element && element.oddsNo ? element.oddsNo : "-"}</span>
                            </div>
                            <div onClick={() => this.handleBackOpen({ data: element, type: "KHAI", selectionId: element.selectionId, odds: element.oddsYes, nat: element.session_name })} className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                              <span className="text-[13px] font-semibold">{element && element.runsYes ? element.runsYes : "-"}</span><br />
                              <span className="text-[10px]">{element && element.oddsYes ? element.oddsYes : "-"}</span>
                            </div>
                            {element && element.running_status === "SUSPENDED" ?
                              <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                <h2 className='text-white text-xs font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div> : null}
                            {element && element.running_status === "Ball Running" ?
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center' >
                                <h2 className='text-black text-xs font-bold uppercase'><span>
                                  Ball Running
                                </span></h2>
                              </div> : null}
                          </div>
                          <div className="w-24 text-center py-0.5 cursor-pointer">
                          </div>
                          <div className="w-24 text-center py-0.5 cursor-pointer" >
                          </div>
                        </div>
                      </div>
                    )) : null}
                  </div>
                ) : (
                  <></>
                )}

                {/*Fancy mobile */}
                {result && result.commission_fancy_data && result.commission_fancy_data !== null ?
                  <div className="lg:hidden block  bg-white">
                    <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                      <p>Fancy  <span className='text-[10px]'>Min :250  Max :300000</span></p>
                      <div class="text-center flex justify-end items-center pt-2">
                        <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                        <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                      </div>
                    </div>

                    {result && result.commission_fancy_data && result.commission_fancy_data.length > 0 ? result.commission_fancy_data.map((element, index) => (
                      <div className="w-full border-b border-gray-200 flex justify-between items-center">
                        <div className="flex justify-between items-center h-full text-[13px] text-gray-800 font-medium px-2 py-4 w-full">
                          <span>{element && element.session_name ? element.session_name : "--"} </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                          <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                            <div onClick={() => this.handleBackOpen({ data: element, type: "LAY", element: element, selectionId: element.selectionId, odds: element.oddsNo, nat: element.session_name })} className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                              <span className="text-[13px] font-semibold">{element && element.runsNo ? element.runsNo : "-"}</span><br />
                              <span className="text-[10px]">{element && element.oddsNo ? element.oddsNo : "-"}</span>
                            </div>
                            <div onClick={() => this.handleBackOpen({ data: element, type: "LAY", element: element, selectionId: element.selectionId, odds: element.oddsYes, nat: element.session_name })} className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                              <span className="text-[13px] font-semibold">{element && element.runsYes ? element.runsYes : "-"}</span><br />
                              <span className="text-[10px]">{element && element.oddsYes ? element.oddsYes : "-"}</span>
                            </div>
                            {element && element.running_status === "SUSPENDED" ? null : <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                            ><h2 className='text-black text-sm font-bold uppercase'>
                                Suspended
                              </h2>
                            </div>}
                            {element && element.running_status === "Ball Running" ? null :
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                <h2 className='text-black text-sm font-bold uppercase'>Ball Running</h2>
                              </div>}
                          </div>
                        </div>
                      </div>
                    )) : "Not Found"}
                  </div> : <></>}

                {/* Extra Fancy Desktop data */}

                <div className="lg:block hidden bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>Extra  Fancy  <span className='text-xs'>Min :100   Max :50000</span></p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                    </div>
                  </div>

                  <div className="w-full ">
                    <div>
                      <div className="border-b border-gray-200 flex ">
                        <div className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Top batsman runs in match adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div>
                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div onClick={() => this.handleBackOpen("element")} className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div onClick={() => this.handleBackOpen("element")} className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Extra Fancy mobile data */}

                <div className="lg:hidden block bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>Extra  Fancy<span className='text-xs'>Min :100   Max :50000</span></p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                    </div>
                  </div>

                  <table className="w-full ">
                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Top batsman runs in match(WI W vs IRE W)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>
                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="h-14">
                  </div>
                </div>

                <div className='w-full text-center text-blue-500 py-3  text-sm bg-[#FFFFFF] border-t-[6px] border-black rounded-t-xl mb-2' >
                  <p className='group-hover:text-black hover:text-black cursor-pointer'>See All Completed Bets</p>
                </div>

                <div className="lg:hidden block fixed z-60 bottom-16 w-full ">
                  {this.state && this.state.backBetModal === true ? (
                    <BetPlaceDesktop
                      betSlipData={this.state.betSlipData}
                      updateStackOnclick={this.updateStackOnclick}
                      inputChange={this.inputChange}
                      decreaseCount={this.decreaseCount}
                      increaseCount={this.increaseCount}
                      placeBet={this.placeBet}
                      handleClose={this.handleClose}
                      count={this.state.count}
                    />
                  ) : null}
                </div>
              </div>
              <div className="w-[25%] lg:block hidden">
                <div className='bg-black mt-0  border-t border-white rounded'>
                  <div onClick={() => this.handleTv2()} className='px-2 cursor-pointer flex justify-between items-center '>
                    <div className='flex space-x-2 px-2 pt-3 pb-2'>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                      <p className='text-white text-sm'> Live Tv 2</p>
                    </div>
                    <div><IoIosArrowDown size={20} className={`text-white ${this.state.tv2 === true ? "rotate-180" : "rotate-0"}`} /></div>
                  </div>
                  {this.state.tv2 === true ?
                    <div className='flex justify-center items-center'>
                      <img className='w-[300px] ' src="/images/tv.png" alt="" />
                    </div>
                    : null}
                </div>
                {this.state && this.state.backBetModal === true ? (
                  <BetPlaceDesktop
                    betSlipData={this.state.betSlipData}
                    updateStackOnclick={this.updateStackOnclick}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    count={this.state.count}
                  />
                ) : null}
                <BetSlipTab setEditStakeOpen={this.setEditStakeOpen} />
                <SidebarRight />
              </div>
              {this.state.editStake === true ?
                <EditformModal setEditStakeOpen={this.setEditStakeOpen} />
                : null
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;

  console.log("usersusersusersusers11111111", users);
  return {
    users,
  };
}

export default connect(mapStateToProps)(Cricketdetails);
