import React from 'react';
import { AiOutlineExclamationCircle } from "react-icons/ai";


export default function Rules(props) {
    const { handleCloseAlert } = props
    return (
        <div className='h-full md:h-screen absolute top-0 left-0 z-50 w-full bg-black/30 pt-8 p-3'>
            <div className='flex flex-col justify-center items-center h-full w-full'>
                <div className="bg-white md:w-[500px] space-y-3 w-full mx-auto p-5 rounded-md">
                    <AiOutlineExclamationCircle className='text-[#F8C489] h-28 w-28 mx-auto' />
                    <div className='p-1 w-full text-center'>
                        <h2 className='text-3xl text-stone-700/90 font-semibold'>Time Closed!</h2>
                    </div>
                    <div className="flex justify-end items-center w-full">
                        <span onClick={() => handleCloseAlert()} className="bg-[#7CD1F9] text-white px-5 py-2 rounded cursor-pointer">Ok</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

