import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';

class Calander extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            file: '',
            imagePreviewUrl: '',
            username:"",
            error:{},
        };
        this.amountInputRef = React.createRef();
    }
componentDidMount(){
    const { params } = this.props.match;
    this.props.dispatch(userActions.calender({gameId:params?.gameId})); // Assuming uploadImage is defined in userActions
   
    console.log(params)
}


    handleFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.props.dispatch(userActions.uploadImage(event)); // Assuming uploadImage is defined in userActions
        } else {
            console.log("No File To Upload!");
        }
    };



    submit=(e)=>{
        e.preventDefault();
   
        if(this.handleValid()){
            const data = {
                amount: this.state.amount,
                userName: this.state.username.trim(),
                
            }
            this.props.dispatch(userActions.transferPointOneToAnother(data));
            
            this.setState({
                amount: '',
                username:"",
            });
            
        }
    }

    handleValid = () =>{
    let valid =true;
    let errorMessage = {};

    if(!this.state.amount||this.state.amount ===""||this.state.amount&&this.state.amount.includes(".")){
        valid=false;
        errorMessage["amount"]="Decimal is not allowed";
    }

    this.setState({
        error: errorMessage
    })

    return valid;

   } 


    render() {
        let { users} = this.props
        let {imgUrl,loading,calanderData}=users?users:{}
        console.log(imgUrl)
        const { params } = this.props.match;
        return (
            <div className='bg-white h-screen'>
                <Loader loading={loading}/>
               
                <form className="p-5" onSubmit={(e)=>this.submit(e)}>
                <div className='  mb-2 border-b-2 font-bold capitalize'>{params&&params?.gameName}</div>


                <div className='overflow-hidden overflow-x-auto'>
                          <table class="min-w-full divide-y divide-gray-200 ">
                            <thead>
                              <tr class="bg-gray-50">
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">Week</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">MON</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">TUE</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">WED</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">THU</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">FRI</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">SAT</th>
                                <th  class="px-1 py-1 text-center  text-sm font-medium  text-[#17273B] uppercase tracking-wider">SUN</th>
                              </tr>
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200">
                              {calanderData&&calanderData?.map((week, index) => {
                                const startDate = new Date(week.rWeek);
                                // Calculate end date by adding 6 days to start date
                                const endDate = new Date(startDate.getTime() + 6 * 24 * 60 * 60 * 1000);
                                // Format dates as YYYY-MM-DD strings
                                const formattedStartDate = startDate.toISOString().split('T')[0];
                                const formattedEndDate = endDate.toISOString().split('T')[0];

                                return (
                                  <tr key={index} class="bg-white border">

                                    <td class="px-1 py-1 text-xs  whitespace-nowrap border">
                                      <div className='flex justify-center text-xs items-center flex-col'>
                                      <div>{formattedStartDate}</div>
                                      to
                                      <div>{formattedEndDate}</div>

                                      </div>
                                      
                                      </td>
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div className=''>{week?.MON?.openNumber || "*"}</div>
                                        <div  className=' text-red-500'>{week?.MON?.jodiNumber || "**"}</div>
                                        <div className=''>{week?.MON?.closeNumber || "*"}</div>
                                      </div>}

                                    </td> 
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div>{week?.TUE?.openNumber || "*"}</div>
                                        <div className=' text-red-500'>{week?.TUE?.jodiNumber || "**"}</div>
                                        <div>{week?.TUE?.closeNumber || "*"}</div>
                                      </div>}

                                    </td>
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div>{week?.WED?.openNumber || "*"}</div>
                                        <div className=' text-red-500'>{week?.WED?.jodiNumber || "**"}</div>
                                        <div>{week?.WED?.closeNumber || "*"}</div>
                                      </div>}

                                    </td>
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div>{week?.THU?.openNumber || "*"}</div>
                                        <div className=' text-red-500'>{week?.THU?.jodiNumber || "**"}</div>
                                        <div>{week?.THU?.closeNumber || "*"}</div>
                                      </div>}

                                    </td>
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div>{week?.FRI?.openNumber || "*"}</div>
                                        <div className=' text-red-500'>{week?.FRI?.jodiNumber || "**"}</div>
                                        <div>{week?.FRI?.closeNumber || "*"}</div>
                                      </div>}

                                    </td>
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div>{week?.SAT?.openNumber || "*"}</div>
                                        <div className=' text-red-500'>{week?.SAT?.jodiNumber || "**"}</div>
                                        <div>{week?.SAT?.closeNumber || "*"}</div>
                                      </div>}

                                    </td>
                                    <td class="px-1 py-1 text-xs whitespace-nowrap border p-0.5">
                                      {<div className=' flex justify-between'>
                                        <div>{week?.SUN?.openNumber || "*"}</div>
                                        <div className=' text-red-500'>{week?.SUN?.jodiNumber || "**"}</div>
                                        <div>{week?.SUN?.closeNumber || "*"}</div>
                                      </div>}

                                    </td>


                                  </tr>
                                )

                              })}
                            </tbody>
                          </table>
                        </div>








                
                </form>
            </div>
        );
    }   
}


function mapStateToProps(state) {
    const { users
    } = state;
    return {
        users

    };
}
export default connect(mapStateToProps)(Calander);


