export const createappConstants = {

    GETALL_CREATE_APP_REQUEST: 'GETALL_CREATE_APP_REQUEST',
    GETALL_CREATE_APP_SUCCESS: 'GETALL_CREATE_APP_SUCCESS',
    GETALL_CREATE_APP_FAILURE: 'GETALL_CREATE_APP_FAILURE',

    GET_LIST_CREATE_APP_REQUEST: 'GET_LIST_CREATE_APP_REQUEST',
    GET_LIST_CREATE_APP_SUCCESS: 'GET_LIST_CREATE_APP_SUCCESS',
    GET_LIST_CREATE_APP_FAILURE: 'GET_LIST_CREATE_APP_FAILURE',

    ADD_CREATE_APP_REQUEST: 'ADD_CREATE_APP_REQUEST',
    ADD_CREATE_APP_SUCCESS: 'ADD_CREATE_APP_SUCCESS',
    ADD_CREATE_APP_FAILURE: 'ADD_CREATE_APP_FAILURE',

    DELETE_CREATE_APP_REQUEST: 'DELETE_CREATE_APP_REQUEST',
    DELETE_CREATE_APP_SUCCESS: 'DELETE_CREATE_APP_SUCCESS',
    DELETE_CREATE_APP_FAILURE: 'DELETE_CREATE_APP_FAILURE',

    UPDATE_CREATE_APP_REQUEST: 'UPDATE_CREATE_APP_REQUEST',
    UPDATE_CREATE_APP_SUCCESS: 'UPDATE_CREATE_APP_SUCCESS',
    UPDATE_CREATE_APP_FAILURE: 'UPDATE_CREATE_APP_FAILURE',

    DISABLE_CREATE_APP_REQUEST: 'DISABLE_CREATE_APP_REQUEST',
    DISABLE_CREATE_APP_SUCCESS: 'DISABLE_CREATE_APP_SUCCESS',
    DISABLE_CREATE_APP_FAILURE: 'DISABLE_CREATE_APP_FAILURE',

    GET_CREATE_APP_BY_ID_REQUEST: 'GET_CREATE_APP_BY_ID_REQUEST',
    GET_CREATE_APP_BY_ID_SUCCESS: 'GET_CREATE_APP_BY_ID_SUCCESS',
    GET_CREATE_APP_BY_ID_FAILURE: 'GET_CREATE_APP_BY_ID_FAILURE',

};
