import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { MdOutlineWatchLater } from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import moment from 'moment'
import Sidebar from "../../components/Sidebar/Sidebar";

class matches extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
    }
  }
  componentDidMount() {

    let data = {
      "limit": 10,
      "pageno": 1
    }
    let data1 = {
      "limit": 1,
      "pageno": 1,
      "sport_id": 111,
      "series_id": 0
    }
    let data2 = {
      "limit": 10,
      "pageno": 1,
      "sport_id": this.props.match.params.sportId,
      "series_id": this.props.match.params.seriesId ? this.props.match.params.seriesId : 0
    }

    console.log('data1????', data1);

    this.props.dispatch(userActions.game_profile(data));
    this.props.dispatch(userActions.games_list(data));
    this.props.dispatch(userActions.event_casino(data1));
    this.props.dispatch(userActions.event_game(data2));


  }
  handleResponseCasino = (data) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data && data.sport_id === 1113) {
      this.props.history.push('/app/teenpatti-t20')
    } else if (data && data.sport_id === 5556) {
      this.props.history.push('/app/amar-akbar-anthony')
    }
    else if (data && data.sport_id === 5557) {
      this.props.history.push('/app/7updown')
    }
    else if (data && data.sport_id === 5559) {
      this.props.history.push('/app/dragon-tiger')
    }
  }

  handleResponseTannis = (data, status) => {
    console.log('data1111111111111111', data);
    console.log('/app/cricket-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id + "/" + status);
    switch (data.sport_id) {
      case "1":
        this.props.history.push('/app/soccer-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
        break;
      case "2":
        this.props.history.push('/app/tennis-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
        break;
      case "4":
        this.props.history.push('/app/cricket-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
        break;

      default:
        break;
    }

  }

  renderSwitch(data) {
    switch (data) {
      case "1":
        return "Soccer";
      case "2":
        return "Tennis";
      case "4":
        return "Cricket";
    }
  }

  secToTime = (startDate, endDate) => {

    let ms = endDate * 1000 - startDate * 1000;
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 3600) % 24);
    return [hours, minutes, seconds].map(v => String(v).padStart(2, 0)).join(':');

  }



  render() {
    let { users } = this.props;
    let { eventcasinoItems, eventgameItems, FcurrentTime } = users;
    let { InplayMatches } = eventgameItems ? eventgameItems : {};
    let { UpCommingMatches } = eventgameItems ? eventgameItems : {};


    return (

      <>
        <div className='relative w-full h-full page_bg overflow-y-auto flex'>

          <Sidebar
            open={true}
            sportId={this.props.match.params.sportId}
            props={this.props}
          />
          <div className='h-screen overflow-y-auto w-full'>
            <div>
              <div className='flex justify-between bg-[#1C77C7] p-1.5' >
                <h2 className='text-base font-medium text-white'>
                  {this.renderSwitch(this.props.match.params.sportId)}
                </h2>
              </div>
              {
                InplayMatches && InplayMatches.length > 0 ? InplayMatches.map((element, index) => (
                  <>
                    <>
                      <div className='divide-y divide-[#C6D2D8] border border-[#C6D2D8]'>
                        <div className='lg:flex'>
                          <div className='lg:w-[60%] w-[100%] p-1 flex justify-between'>
                            <div>
                              <div className='flex justify-start items-center cursor-pointer'>
                                <span className='text-[#6bbd11] '><GoPrimitiveDot size={20} /> </span>
                                {element && element.InplayStatus && element.InplayStatus === "OPEN" ?
                                  <h2 className='text-xs font-medium' onClick={() => this.handleResponseTannis(element, "Inplay")}>{element.name}</h2> :
                                  <h2 className='text-xs font-medium' >{element.name}</h2>
                                }
                                <span className='text-red-600 text-xs font-semibold pl-1'>Live</span>
                              </div>
                              <div className='flex justify-start items-center text-gray-600 text-xs font-medium px-1 space-x-1'>
                                <span><MdOutlineWatchLater /></span>
                                <span>{moment(parseInt(element.start_date) * 1000).utcOffset("+05:30").format("DD MMM, YYYY hh:mm A")}
                                </span>
                              </div>
                            </div>
                            <div className='text-black'>
                              {element && element.bm && element.bm === "1" ?
                                <>
                                  {
                                    <img className="w-8 h-8" key={index} src="/assets/images/bm-icon1.svg" alt={'bookmaker'} />
                                  }
                                </> :
                                null
                              }

                            </div>
                          </div>
                          {element && element.InplayStatus && element.InplayStatus === "OPEN" ?
                            <>
                              <div className="lg:w-[40%] w-full grid grid-cols-6 text-base relative">
                                <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px] '><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToBack && element.runner_json[0].ex.availableToBack[0] && element.runner_json[0].ex.availableToBack[0].price ? element.runner_json[0].ex.availableToBack[0].price : "--"}</h2></div>
                                <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToLay && element.runner_json[0].ex.availableToLay[0] && element.runner_json[0].ex.availableToLay[0].price ? element.runner_json[0].ex.availableToLay[0].price : "--"}</h2></div>
                                <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToBack && element.runner_json[2].ex.availableToBack[0] && element.runner_json[2].ex.availableToBack[0].price ? element.runner_json[2].ex.availableToBack[0].price : "--"}</h2></div>
                                <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToLay && element.runner_json[2].ex.availableToLay[0] && element.runner_json[2].ex.availableToLay[0].price ? element.runner_json[2].ex.availableToLay[0].price : "--"}</h2></div>
                                <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToBack && element.runner_json[1].ex.availableToBack[0] && element.runner_json[1].ex.availableToBack[0].price ? element.runner_json[1].ex.availableToBack[0].price : "--"}</h2></div>
                                <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToLay && element.runner_json[1].ex.availableToLay[0] && element.runner_json[1].ex.availableToLay[0].price ? element.runner_json[1].ex.availableToLay[0].price : "--"}</h2></div>

                              </div>
                            </>
                            :
                            <div className="lg:w-[40%] w-full grid grid-cols-6 text-base relative">
                              <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToBack && element.runner_json[0].ex.availableToBack[0] && element.runner_json[0].ex.availableToBack[0].price ? element.runner_json[0].ex.availableToBack[0].price : "--"}</h2></div>
                              <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToLay && element.runner_json[0].ex.availableToLay[0] && element.runner_json[0].ex.availableToLay[0].price ? element.runner_json[0].ex.availableToLay[0].price : "--"}</h2></div>
                              <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToBack && element.runner_json[2].ex.availableToBack[0] && element.runner_json[2].ex.availableToBack[0].price ? element.runner_json[2].ex.availableToBack[0].price : "--"}</h2></div>
                              <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToLay && element.runner_json[2].ex.availableToLay[0] && element.runner_json[2].ex.availableToLay[0].price ? element.runner_json[2].ex.availableToLay[0].price : "--"}</h2></div>
                              <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToBack && element.runner_json[1].ex.availableToBack[0] && element.runner_json[1].ex.availableToBack[0].price ? element.runner_json[1].ex.availableToBack[0].price : "--"}</h2></div>
                              <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToLay && element.runner_json[1].ex.availableToLay[0] && element.runner_json[1].ex.availableToLay[0].price ? element.runner_json[1].ex.availableToLay[0].price : "--"}</h2></div>
                              <div className='absolute bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>

                                <h2 className='text-white uppercase text-xs font-bold'>suspended</h2></div>
                            </div>
                          }

                        </div>
                      </div>
                    </>

                  </>
                )) : null}
            </div>

            {
              UpCommingMatches && UpCommingMatches.length > 0 ? UpCommingMatches.map((element, index) => (
                <>
                  <>
                    <div className='divide-y divide-[#C6D2D8] border border-[#C6D2D8]'>
                      <div className='lg:flex'>
                        <div className='lg:w-[60%] w-full p-1 flex justify-between px-2'>
                          <div>
                            <div className='flex justify-start items-center cursor-pointer '>
                              {element && element.InplayStatus && element.InplayStatus === "OPEN" ?
                                <h2 className='text-xs font-medium' onClick={() => this.handleResponseTannis(element, "Upcomming")}>{element.name}</h2> :
                                <h2 className='text-xs font-medium' >{element.name}</h2>
                              }
                            </div>
                            <div className='flex justify-start items-center text-gray-600 text-xs font-medium px-1 space-x-1'>
                              <span><MdOutlineWatchLater /></span>
                              <span>{moment(parseInt(element.start_date) * 1000).utcOffset("+05:30").format("DD MMM, YYYY hh:mm A")}
                              </span>
                            </div>
                          </div>
                          <div className='text-black'>
                            {element && element.bm && element.bm === "1" ?
                              <>
                                {
                                  <img className="w-8 h-8" key={index} src="/assets/images/bm-icon1.svg" alt={'bookmaker'} />
                                }
                              </> :
                              null
                            }
                          </div>
                        </div>

                        {element && element.InplayStatus && element.InplayStatus === "OPEN" ?
                          <div className="lg:w-[40%] w-full grid grid-cols-6 text-base relative">
                            <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px] '><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToBack && element.runner_json[0].ex.availableToBack[0] && element.runner_json[0].ex.availableToBack[0].price ? element.runner_json[0].ex.availableToBack[0].price : "--"}</h2></div>
                            <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToLay && element.runner_json[0].ex.availableToLay[0] && element.runner_json[0].ex.availableToLay[0].price ? element.runner_json[0].ex.availableToLay[0].price : "--"}</h2></div>
                            <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToBack && element.runner_json[2].ex.availableToBack[0] && element.runner_json[2].ex.availableToBack[0].price ? element.runner_json[2].ex.availableToBack[0].price : "--"}</h2></div>
                            <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToLay && element.runner_json[2].ex.availableToLay[0] && element.runner_json[2].ex.availableToLay[0].price ? element.runner_json[2].ex.availableToLay[0].price : "--"}</h2></div>
                            <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToBack && element.runner_json[1].ex.availableToBack[0] && element.runner_json[1].ex.availableToBack[0].price ? element.runner_json[1].ex.availableToBack[0].price : "--"}</h2></div>
                            <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToLay && element.runner_json[1].ex.availableToLay[0] && element.runner_json[1].ex.availableToLay[0].price ? element.runner_json[1].ex.availableToLay[0].price : "--"}</h2></div>
                            {
                              FcurrentTime > element.start_date - element.BetAllowTimeBefore ?
                                <>
                                </>
                                :
                                <div className='absolute bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>


                                  <h2 className='text-white text-xs font-bold'><span>
                                    {
                                      this.secToTime(parseInt(Date.now() / 1000), element.start_date - element.BetAllowTimeBefore)
                                    }

                                  </span></h2>
                                </div>
                            }
                          </div>
                          :
                          <div className="lg:w-[40%] w-full grid grid-cols-6 text-base relative">
                            <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToBack && element.runner_json[0].ex.availableToBack[0] && element.runner_json[0].ex.availableToBack[0].price ? element.runner_json[0].ex.availableToBack[0].price : "--"}</h2></div>
                            <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[0] && element.runner_json[0].ex && element.runner_json[0].ex.availableToLay && element.runner_json[0].ex.availableToLay[0] && element.runner_json[0].ex.availableToLay[0].price ? element.runner_json[0].ex.availableToLay[0].price : "--"}</h2></div>
                            <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToBack && element.runner_json[2].ex.availableToBack[0] && element.runner_json[2].ex.availableToBack[0].price ? element.runner_json[2].ex.availableToBack[0].price : "--"}</h2></div>
                            <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[2] && element.runner_json[2].ex && element.runner_json[2].ex.availableToLay && element.runner_json[2].ex.availableToLay[0] && element.runner_json[2].ex.availableToLay[0].price ? element.runner_json[2].ex.availableToLay[0].price : "--"}</h2></div>
                            <div className='bg-[#6ABBEC] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToBack && element.runner_json[1].ex.availableToBack[0] && element.runner_json[1].ex.availableToBack[0].price ? element.runner_json[1].ex.availableToBack[0].price : "--"}</h2></div>
                            <div className='bg-[#FAA9BA] flex justify-center items-center text-xs font-bold p-[5px]'><h2>{element && element.runner_json && element.runner_json[1] && element.runner_json[1].ex && element.runner_json[1].ex.availableToLay && element.runner_json[1].ex.availableToLay[0] && element.runner_json[1].ex.availableToLay[0].price ? element.runner_json[1].ex.availableToLay[0].price : "--"}</h2></div>
                            <div className='absolute bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>

                              <h2 className='text-white uppercase text-xs font-bold'>suspended</h2></div>
                          </div>
                        }
                      </div>
                    </div>
                  </>
                </>
              )) : null}
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(matches);