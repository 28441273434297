import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import Layout from "./Layout";
import Error from "../pages/error";
import Login from "../pages/login";
import { Helmet, HelmetProvider } from "react-helmet-async";
import NetworkDetector from "../components/Hoc/NetworkDetector";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import Register from "../pages/register/Register";
import { useState } from "react";

function App() {
  const [metaTags, setMetaTags] = useState({
    title: "Pocket X",
    description: "Pocket X OnLine Betting",
    image: "/images/logo.png",
    url: "",
  });
  return (
    <HelmetProvider>
      <Helmet>
        <meta property="og:title" content={metaTags.title} />
        <meta property="og:description" content={metaTags.description} />
        <meta property="og:image" content={metaTags.image} />
        <meta property="og:url" content={metaTags.url} />
      </Helmet>

      <HashRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="app/dashboard" />}
          />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="app/dashboard" />}
          />
          <PrivateRoute path="/app" component={withRouter(Layout)} />
          <PublicRoute
            path="/:userId/:parentId/register"
            component={withRouter(Register)}
          />
          <PublicRoute path="/login" component={withRouter(Login)} />
          <PublicRoute
            path="/forgotPassword"
            component={withRouter(ForgotPassword)}
          />
          <Route component={Error} />
        </Switch>
      </HashRouter>
      
    </HelmetProvider>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("spuser") ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          false ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
export default NetworkDetector(App);
