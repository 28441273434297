import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarRight from '../../components/SidebarRight/SidebarRight';
import EditformModal from "../../casino_components/EditformModal/EditformModal";
import BetSlipTab from "../../casino_components/BetSlipTab/BetSlipTab";
import { IoCaretBackCircle } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';

class SattaEvents extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
      gameTwo: '',
      gameThree: '',
      gameFour: '',
      gameItem: {},
      activeTab: 1,
      editStake: false,

      data: {
        "limit": 10,
        "pageno": 1
      }
    }
  }
  componentDidMount() {

    let data = {
      "gameId": this.props.match.params.gameId,
    }
    // this.props.dispatch(userActions.game_profile(data));
    this.props.dispatch(userActions.getMarketListByGameId(data));
  }


  navigateToGameSingle = (data) => {

    console.log(data && data.shortName && data.shortName.includes("SINGLE_PATTI"))
    if (data && data.shortName && data.shortName.toLowerCase() == "single") {

      this.props.history.push(`/app/matkaSingle/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
    }
    if (data && data.shortName && data.shortName == "ODD_EVEN") {

      this.props.history.push(`/app/matkaOddEven/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
    }
    if (data && data.shortName && data.shortName == "JODI") {

      this.props.history.push(`/app/matkaJodi/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
    }
    if (data && data.shortName && data.shortName == "SINGLE_PATTI") {

      this.props.history.push(`/app/matkaSinglePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
    }
    if (data && data.shortName && data.shortName == "DOUBLE_PATTI") {

      this.props.history.push(`/app/matkaDoublePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
    }
    if (data && data.shortName && data.shortName == "TRIPLE_PATTI") {

      this.props.history.push(`/app/matkaTriplePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
    }
  }

  // navigateToGameDoublePatti = (data) => {
  //   this.props.history.push(`/app/matkaDoublePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  // }

  // navigateToGameOddEven = (data) => {
  //   console.log(data)
  //   console.log(this.props.match.params)
  //   this.props.history.push(`/app/matkaOddEven/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  // }

  // navigateToGameJodi = (data) => {
  //   this.props.history.push(`/app/matkaJodi/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  // }

  // navigateToGameTriplePatti = (data) => {
  //   this.props.history.push(`/app/matkaTriplePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  // }

  // navigateToGameSinglePatti = (data) => {
  //   this.props.history.push(`/app/matkaSinglePatti/${this.props.match.params.gameName}/${this.props.match.params.gameId}/${data._id}`)
  // }

  handleSidebar = (data) => {
    console.log('datagameItemgameItemgameItem', data);
    this.setState({ gameItem: data });

  }
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake })
  }

  render() {
    let { users } = this.props;
    let { getMarketList, loading } = users;
    // let
    let dataofMarket = getMarketList&&Array.isArray(getMarketList)&&getMarketList.length>0&&getMarketList[0]?.marketList?getMarketList[0]?.marketList:[];
    console.log(dataofMarket)

    console.log(getMarketList)

    return (
      // <div className='w-full h-full overflow-y-auto' style={{ backgroundImage: "url(/images/mw-ban.png)" }}>
      <div className='w-full h-full overflow-y-auto relative bg-gray-200'>
        <Loader loading={loading} />
        <div className=' text-5xl absolute text-[#17273b]'>

          <IoCaretBackCircle className=' cursor-pointer' onClick={() => this.props.history.push('/app/dashboard')} />
        </div>
        <div className="w-full py-10  flex flex-col gap-2 ">
          <div className='px-3 py-3 text-2xl font-semibold text-center text-[#17273b] capitalize'>
            {this.props.match.params.gameName}
          </div>
          <div className=' flex justify-center items-center gap-5 flex-wrap'>

            {dataofMarket && Array.isArray(dataofMarket) && dataofMarket.length > 0 && dataofMarket.map((e) => {
              console.log(e)

              return (
                <div className='h-[100px] lg:h-[250px] w-[100px] lg:w-[250px]'>
                  <img onClick={() => this.navigateToGameSingle(e)} src={e?.image} alt='logo' className='cursor-pointer h-full w-full' />
                </div>
              )
            })}


 
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(SattaEvents);
