import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { FaRegEyeSlash } from "react-icons/fa";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import OtpTimer from "otp-timer";
import { IoEyeSharp } from "react-icons/io5";
import { Helmet } from 'react-helmet';

class Register extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      passtype: "password",
      visible: false,
      visibles: false,
      visibless: false,
    };
  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  componentDidMount() {
    this.props.dispatch(userActions.ClearUser());
  }

  handleFile = (event) => {
    if (event.target.files && event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event)); // Assuming uploadImage is defined in userActions
    } else {
      console.log("No File To Upload!");
    }

    this.setState((prevState) => ({
      errorslogin: {
        ...prevState.errorslogin,
        img: "", // Clear the error for the amount field
      },
    }));
  };

  handleClick = (e) => {
    let data = {
      userName: this.state.fieldslogin.username,
      password: this.state.fieldslogin.password,
      mobNo: this.state.fieldslogin.mobNo,
      image: " ",
      email: this.state.fieldslogin.email,
      confirmPassword: this.state.fieldslogin.confirmPassword,
    };
    this.props.dispatch(userActions.userRegister(data, this.props));
  };

  changeState = () => {
    this.setState((prevState) => ({
      isOTPSent: !prevState.isOTPSent,
    }));
  };

  loginSubmit(e) {
    e.preventDefault();
    let { userId, parentId } = this.props.match.params;
    if (this.handleValidationLogin()) {
      let data = {
        userName: this.state.fieldslogin.username.trim(),
        password: this.state.fieldslogin.password.trim(),
        mobNo: this.state.fieldslogin.mobNo || null,
        image: " ",
        parentId: parentId,
        userId: userId,
        confirmPassword: this.state.fieldslogin.confirmPassword.trim(),
      };
      this.props.dispatch(
        userActions.userRegister(data, this.props, this.changeState)
      );
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"] || fieldslogin["username"].trim() == "") {
      formIsValid = false;
      errorslogin["username"] = "User name can't be empty.";
    } else if (fieldslogin["username"].includes(" ")) {
      formIsValid = false;
      errorslogin["username"] = "Spaces are not allowed in the  name.";
    }
    //password
    if (!fieldslogin["password"] || fieldslogin["password"].trim() == "") {
      formIsValid = false;
      errorslogin["password"] = "Password can't be empty.";
    }
    if (!fieldslogin["confirmPassword"]) {
      formIsValid = false;
      errorslogin["confirmPassword"] = "Confirm password can't be empty.";
    }

    if (!fieldslogin.password || fieldslogin.password === "") {
      formIsValid = false;
      errorslogin.password = "New password can't be empty.";
    }

    if (fieldslogin["confirmPassword"] !== fieldslogin["password"]) {
      formIsValid = false;
      errorslogin["confirmPassword"] = "Confirm password does not match.";
    }
    // if (!fieldslogin["email"]) {
    //   formIsValid = false;
    //   errorslogin["email"] = "Email cannot be blank.";
    // }
    // if (!fieldslogin["mobNo"]) {
    //   formIsValid = false;
    //   errorslogin["mobNo"] = "Mob no. cannot be blank.";
    // }

    console.log(errorslogin);

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };
  handleValidationLogin2 = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    if (!fieldslogin["otp"]) {
      formIsValid = false;
      errorslogin["otp"] = "OTP Cannot Be Blank.";
    }

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleVisible = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible,
    }));
  };

  Verify = () => {
    const { loggingIn, users } = this.props;
    let { registerUser } = users ? users : {};
    let { isOTPSent, userToken } = registerUser ? registerUser : {};

    if (this.handleValidationLogin2()) {
      let data = {
        token: userToken,
        otp: this.state.fieldslogin.otp,
      };
      this.props.dispatch(userActions.registerValidateOtp(data, this.props));
    }
  };

  handleClick1 = () => {
    this.setState((prevState) => ({
      visibless: !prevState.visibless,
    }));
  };
  handleClick2 = () => {
    this.setState((prevState) => ({
      visibles: !prevState.visibles,
    }));
  };

  render() {
    const { loggingIn, users } = this.props;
    let { registerUser, isOTPSent } = users ? users : {};
    let { userToken } = registerUser ? registerUser : {};


    return (
      <>
  

        <div className=" bg-black   flex justify-center items-center ">
          <div style={{ backgroundImage: 'url("/images/login-bg.jpg")' }} className="flex bg-white justify-center  lg:w-[50vw] w-full  items-center h-screen">
            <div  className="flex flex-col   md:px-3 px-6   md:border h-[90%]  rounded-lg  justify-center items-center  py-5">
            <div className=" py-10">
              <img src="/images/logo.png" />
            </div>
              <div className="flex items-center justify-center text-3xl text-[white] font-bold ">
                {/* Register User */}
              </div>
              <div className="flex items-center uppercase justify-center font-bold text-2xl text-white">
              Register on <span className=" ml-1"> POCKET </span> - <span className=" text-[#76CD26] text-4xl font-bold">X</span>
              </div>
              <div className="w-96 p-4">
                <div className=" w-full flex flex-col">
                  <input
                    type="text"
                    disabled={isOTPSent ? true : false}
                    name="username"
                    id="username"
                    value={this.state.fieldslogin.username}
                    className="w-full px-2 py-3 focus:outline-none rounded m-1 text-lg border-b border-gray-400 placeholder:text-stone-500"
                    placeholder="Enter Username"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin &&
                  this.state.errorslogin["username"] ? (
                    <div className="  text-red-500 text-left text-sm">
                      {this.state.errorslogin["username"]}
                    </div>
                  ) : null}
                </div>

                <div className=" w-full flex flex-col">
                  <input
                    type="number"
                    name="mobNo"
                    id="mobNo"
                    disabled={isOTPSent ? true : false}
                    value={this.state.fieldslogin.mobNo}
                    className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500 rounded m-1"
                    placeholder="Enter Mobile Number"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin && this.state.errorslogin["mobNo"] ? (
                    <div className="  text-red-500 text-left text-sm">
                      {this.state.errorslogin["mobNo"]}
                    </div>
                  ) : null}
                </div>
                {/* <div className=" w-full flex flex-col">
                <input
                  type="email"
                  name="email"
                  disabled={isOTPSent ? true : false}
                  id="email"
                  value={this.state.fieldslogin.email}
                  className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500 rounded m-1"
                  placeholder="Enter Your Email"
                  onChange={this.inputChange}
                />
                {this.state.errorslogin &&
                  this.state.errorslogin["email"] ? (
                  <div className="  text-red-500 text-left text-sm">
                    {this.state.errorslogin["email"]}
                  </div>
                ) : null}
              </div> */}
                <div className=" w-full flex flex-col relative">
                  {this.state.visibles ? (
                    <IoEyeSharp
                      onClick={() => this.handleClick2()}
                      className=" absolute right-0 top-5 "
                    />
                  ) : (
                    <FaRegEyeSlash
                      onClick={() => this.handleClick2()}
                      className=" absolute right-0 top-5 "
                    />
                  )}
                  <input
                    type={this.state.visibles ? "text" : "password"}
                    name="password"
                    id="password"
                    disabled={isOTPSent ? true : false}
                    value={this.state.fieldslogin.password}
                    className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500 rounded m-1"
                    placeholder="Enter Password"
                    onChange={this.inputChange}
                  />

                  {this.state.errorslogin &&
                  this.state.errorslogin["password"] ? (
                    <div className="  text-red-500 text-left text-sm">
                      {this.state.errorslogin["password"]}
                    </div>
                  ) : null}
                </div>
                <div className=" w-full flex flex-col relative">
                  {this.state.visibless ? (
                    <IoEyeSharp
                      onClick={() => this.handleClick1()}
                      className=" absolute right-0 top-5 "
                    />
                  ) : (
                    <FaRegEyeSlash
                      onClick={() => this.handleClick1()}
                      className=" absolute right-0 top-5 "
                    />
                  )}
                  <input
                    type={this.state.visibless ? "text" : "password"}
                    name="confirmPassword"
                    disabled={isOTPSent ? true : false}
                    id="confirmPassword"
                    value={this.state.fieldslogin.confirmPassword}
                    className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500 rounded m-1"
                    placeholder="Enter Confirm Password"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin &&
                  this.state.errorslogin["confirmPassword"] ? (
                    <div className="  text-red-500 text-left text-sm">
                      {this.state.errorslogin["confirmPassword"]}
                    </div>
                  ) : null}
                </div>
                {isOTPSent && (
                  <div className=" w-full flex flex-col">
                    <input
                      type="text"
                      name="otp"
                      id="otp"
                      value={this.state.fieldslogin.otp}
                      className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500 rounded m-1"
                      placeholder="Enter OTP"
                      onChange={this.inputChange}
                    />
                    {this.state.errorslogin && this.state.errorslogin["otp"] ? (
                      <div className="  text-red-500 text-left text-sm">
                        {this.state.errorslogin["otp"]}
                      </div>
                    ) : null}
                  </div>
                )}

                {isOTPSent && (
                  <div className=" justify-center items-center flex mt-2">
                    <OtpTimer
                      ButtonText="Resend Otp"
                      background={"#ffffff"}
                      buttonColor={"#F64411"}
                      textColor={"#000000"}
                      seconds={30}
                      minutes={0}
                      resend={this.handleClick}
                    />
                  </div>
                )}

                {/* <div className=" my-2 text-blue-500  flex justify-end items-center ">
                <Link to={'/login'} className=" cursor-pointer">Login </Link>
              </div> */}

                {isOTPSent && isOTPSent ? (
                  <div className="w-full flex justify-center items-center">
                    <button
                      type="button"
                      onClick={this.Verify}
                      className=" flex justify-center items-center py-5 bg-[white] w-full text-white font-semibold  text-lg  rounded-lg mt-5"
                    >
                      <span className="tracking-wider ">Verify OTP</span>
                    </button>
                  </div>
                ) : (
                  <div className="w-full flex justify-center items-center">
                    <button
                      type="button"
                      onClick={this.loginSubmit}
                      className=" flex justify-center rounded  items-center py-5 bg-[white] w-full text-[#26415F] font-semibold  text-lg   mt-5"
                    >
                      <span className="tracking-wider ">Submit</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users,
  };
}
export default connect(mapStateToProps)(Register);
