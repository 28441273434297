import React from "react";
import Slider from "react-slick";

export default function SimpleSlider({ slideData }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  console.log(slideData)
  return (
    <Slider {...settings}>
      {slideData && slideData.length > 0 && slideData.map((e, i) => {

        return (
          <div key={i} className="flex justify-center h-32 md:h-60  items-center p-1  ">
            <img src={e?.image} className=" h-full  w-[100%] rounded-md" />
          </div>
        )
      })}



    </Slider>
  );
}