import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { FaTimes } from "react-icons/fa";
import { IoCaretBackCircle } from "react-icons/io5";
import Loader from '../../components/Loader/Loader';
import { MdOutlineBlock } from "react-icons/md";
class MatkaJodi extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
      gameTwo: '',
      gameThree: '',
      gameFour: '',
      gameItem: {},
      activeTab: 1,
      editStake: false,
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},
      selectedElements: [],
      orderList:[],
      totalPoint: 0,
      initialValue:"0",
      data: {
        "limit": 10,
        "pageno": 1
      }
    }
  }
  componentDidMount() {
    let data = {
      "marketId": this.props.match.params.marketId,
    }
    this.props.dispatch(userActions.getMarketByMarketId(data));
    this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
  }

  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value) >= 0 ? value : 0;
    errorStake[name] = "data not found";
    console.log("value  ", value);

    let sum = 0;

    for (const key in fieldStake) {
      if (fieldStake.hasOwnProperty(key)) {
        sum += parseInt(fieldStake[key]);
      }
    }

    this.setState({ fieldStake, errorStake, totalPoint: sum });
  };

  handleChangeSelect = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldMarket, errorStake } = this.state;
    fieldMarket[name] = value;
    errorStake[name] = "data not found";
    this.setState({ fieldMarket, errorStake });
  };

  placeBet = () => {
console.log(this.state.orderList)
let {orderList} = this.state
let betsarray = Object.entries(orderList)
  .filter(([key, value]) => !isNaN(parseInt(value)) && parseInt(value) !== 0 && parseInt(value) !== undefined) // Filter out entries with NaN, undefined, or 0 values
  .map(([key, value]) => ({
    betNumber: key,
    amount: parseInt(value)
  }));
if(betsarray&&betsarray.length>0){

  let reqData = {
    "marketId": this.props.match.params.marketId,
    "gameId": this.props.match.params.gameId,
    "bets": betsarray,
    "isOpen": false,
  }

  this.props.dispatch(userActions.placeBet(reqData))
}
  
  }
  // placeBet = () => {
  //   let { fieldStake, fieldMarket, totalPoint } = this.state;
  //   let bets = Object.keys(fieldStake).map(key => ({ "betNumber": key, "amount": parseInt(fieldStake[key]) }));
  //   let arryofbets = bets.filter(e => !isNaN(e.amount) && e.amount !== null);

  //   console.log(arryofbets)
  //   console.log(totalPoint)
  //   if (totalPoint && totalPoint !== 0 && totalPoint !== '') {

  //     let reqData = {
  //       "marketId": this.props.match.params.marketId,
  //       "gameId": this.props.match.params.gameId,

  //       "bets": arryofbets,
  //       "isOpen": false,
  //     }

  //     this.props.dispatch(userActions.placeBet(reqData));

  //   }
  // }


  selectElement = (element, data) => {
    const { selectedElements } = this.state;
    const index = selectedElements.indexOf(element);

    if (index !== -1) {
      selectedElements.splice(index, 1);
      if (parseInt(data) !== 0) {
        let difff = this.state.totalPoint - parseInt(data);
        this.setState({
          totalPoint: difff,
          fieldStake: {
            ...this.state.fieldStake,
            [element]: ""
          }
        });


      }


    } else {
      selectedElements.push(element);
    }
    this.setState({ selectedElements });
  };



  handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    this.setState((prevState) => ({
      orderList: {
        ...prevState.orderList,
        [name]: value,
      },
    }));

  }

  handleClick = (e)=>{
    const { name, value } = e.target;
    console.log(e.target.name)
this.setState((prevState) => ({
  orderList: {
    ...prevState.orderList,
    [name]: this.state.initialValue,
  },
}));
  }
  
  handleAmount =(e)=>{
    console.log(e.target.value)
    this.setState({initialValue:e.target.value});
  }

  render() {
    let { users } = this.props;
    let { marketDetails, marketLoading } = users;
    let { fieldStake, fieldMarket, errorStake, totalPoint, selectedElements } = this.state;
    let { loading, gameData
    } = users;


    let { currentTime, list } = gameData ? gameData : {};
    let { openTime, closingTime, betOpenTime, betClosingTime, max, min, NotnumList } = list ? list : {};


    console.log(NotnumList)
    return (
      <div className='w-full h-full overflow-y-auto bg-gray-200'>
        <div className=' text-5xl absolute text-[#17273b]'>
          <Loader loading={marketLoading} />

          <IoCaretBackCircle className=' cursor-pointer' onClick={() => this.props.history.goBack()} />
        </div>
        {/* <div className='w-full h-full overflow-y-auto' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
        <div className="w-full md:p-6 p-2 mb-10 space-y-10">
          <div className='px-3 py-3 text-2xl font-semibold text-center text-[#17273b] capitalize '>
            {this.props.match.params.gameName}
          </div>
          <div className='bg-white shadow-lg rounded-lg w-full    p-5  space-y-5'>
            <div className='flex justify-center items-center'>
              <span className='text-2xl uppercase font-[500] lg:text-[#495057] text-[#913030]'>Jodi</span>
            </div>
            <div className='md:text-2xl text-sm whitespace-nowrap flex justify-center items-center uppercase font-[500] lg:text-[#17273b] text-[#913030]'>
              Each Bet amount should be between {min && min || ''} - {max && max || ""}
            </div>

            <div className=' flex justify-center items-center gap-5'>
              <button onClick={this.handleAmount} value={200} className= {`${this.state.initialValue==200?" bg-yellow-700":"bg-[#17273b]"} p-3 text-white rounded-md font-bold`}>₹ 200</button>
              <button onClick={this.handleAmount} value={500} className={`${this.state.initialValue==500?" bg-yellow-700":"bg-[#17273b]"} p-3 text-white rounded-md font-bold`}>₹ 500</button>
              <button onClick={this.handleAmount} value={1000} className={`bg-[#17273b] p-3 text-white rounded-md font-bold`}>₹ 1000</button>
            </div>
            <div className="full-page-container overflow-x-auto overflow-hidden mx-auto">
              <table className='table text-[#17273B]   w-full  '>
                <tbody className=''>
                  {marketDetails && marketDetails.numList && marketDetails.numList.length > 0 ? (
                    Array.from({ length: 10 }).map((_, rowIndex) => (
                      <tr key={rowIndex} className=' border-2 '>
                        {Array.from({ length: 10 }).map((_, colIndex) => {
                          return(
                            <td key={colIndex} className=' border-2 text-[#17273B]  font-bold text-center p-3 '><div>{marketDetails.numList[rowIndex * 10 + colIndex]} </div> 
                            
                         {NotnumList&&NotnumList.length>0&&NotnumList.includes(marketDetails?.numList[rowIndex * 10 + colIndex]?.toString())  ?
                         <div className=' relative'>

                         <input name={marketDetails.numList[rowIndex * 10 + colIndex]} disabled onChange={this.handleChange} type='number' className=' w-16 p-1   rounded-xl  border' placeholder='₹' />
                         <MdOutlineBlock className=' absolute top-1  right-1/2 font-bold text-2xl text-red-700' title='Blocked' />
                         </div>
                         
                         :<input name={marketDetails.numList[rowIndex * 10 + colIndex]} onClick={this.handleClick} value={this.state.orderList[marketDetails.numList[rowIndex * 10 + colIndex]]} onChange={this.handleChange} type='number' className=' w-16 p-1   rounded-xl  border' placeholder='₹' />}
                            
                            </td>
                          )
                        })}
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="10">Not found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>




            <div className='grid grid-cols-2 gap-5'>
              {selectedElements && selectedElements.length > 0 ? selectedElements.map((element, index) => (
                <div className='flex space-x-1 mr-2'>
                  <div className='bg-[#555555] h-12 w-12 flex justify-center items-center rounded-l-md'>
                    <span className='text-white p-3'>{element}</span>
                  </div>
                  <div className='h-12 w-44 border border-[#555555] rounded-r-md'>
                    <input type='number' className="focus:outline-none rounded p-2  h-full  w-full" name={element} placeholder="0" value={fieldStake && fieldStake[element] ? fieldStake[element] : ""} onChange={this.handleStakeChange} />
                  </div>
                  <div onClick={() => this.selectElement(element, fieldStake[element])} className='bg-[#555555] h-12 w-12 flex justify-center items-center rounded-r-md'>
                    <span className='text-white'><FaTimes /></span>
                  </div>
                </div>)) : null}
            </div>
            <div className='flex justify-center items-center space-x-5'>


            </div>
            <div className='flex justify-center items-center space-x-5'>
              <span className='text-xl font-[500] text-[#495057]'>Total Point:</span>
              <span className='text-xl font-[500] text-[#495057]'>{this.state.totalPoint}</span>
            </div>
            <div className='flex justify-center items-center'>
              <button onClick={() => this.placeBet()} className='py-1.5 px-4 bg-[#1B7BFF] text-white text-xl rounded'>Submit</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(MatkaJodi);
