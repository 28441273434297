import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Loader from '../../components/Loader/Loader';

class TransferPointsREf extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: '',
            file: '',
            imagePreviewUrl: '',
            username:"",
            error:{},
        };
        this.amountInputRef = React.createRef();
    }
componentDidMount(){
    this.props.dispatch(userActions.clearImage());
}


    handleFile = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.props.dispatch(userActions.uploadImage(event)); // Assuming uploadImage is defined in userActions
        } else {
            console.log("No File To Upload!");
        }
    };



    submit=(e)=>{
        e.preventDefault();
   
        if(this.handleValid()){
            const data = {
                amount: this.state.amount,
                userName: this.state.username.trim(),
                type:"REFERRAL_TRANSFER"
                
            }
            this.props.dispatch(userActions.transferPointOneToAnother(data));
            
            this.setState({
                amount: '',
                username:"",
            });
            
        }
    }

    handleValid = () =>{
    let valid =true;
    let errorMessage = {};

    if(!this.state.amount||this.state.amount ===""||this.state.amount&&this.state.amount.includes(".")){
        valid=false;
        errorMessage["amount"]="Decimal is not allowed";
    }

    this.setState({
        error: errorMessage
    })

    return valid;

   } 


    render() {
        let { users} = this.props
        let {imgUrl,loading}=users?users:{}
        console.log(imgUrl)
        return (
            <div className='bg-white h-screen'>
                <Loader loading={loading}/>
               
                <form className="max-w-sm mx-auto p-5" onSubmit={(e)=>this.submit(e)}>
                <div className='  mb-2 border-b-2 font-bold'>Transfer Points (Referral Wallet)</div>


                <div className="mb-5">
                        <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">User Name</label>
                        <input
                            type="text"
                            id="username"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#17273b] focus:border-[#17273b] block w-full p-2.5"
                            placeholder="User name"
                            required
                            value={this.state.username} 
                            onChange={(e) => this.setState({ username: e.target.value })}
                        />
                    </div>



                    <div className="mb-5">
                        <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">Amount</label>
                        <input
                            type="number"
                            id="amount"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#17273b] focus:border-[#17273b] block w-full p-2.5"
                            placeholder="Amount"
                            required
                            value={this.state.amount} 
                            onChange={(e) => this.setState({ amount: e.target.value , error:"" })}
                        />
                        {this.state.error&&this.state.error["amount"]?<div className=' text-red-600 '>{this.state.error["amount"]}</div>:""}
                    </div>







                    <button
                    
                        type="submit"
                        className="text-white bg-[#17273b] hover:bg-[#17273b] focus:ring-4 focus:outline-none focus:ring-[#17273b] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-[#17273b] dark:hover:bg-[#17273b] dark:focus:ring-[#17273b]"
                    >
                        Send Request
                    </button>
                </form>
            </div>
        );
    }   
}


function mapStateToProps(state) {
    const { users
    } = state;
    return {
        users

    };
}
export default connect(mapStateToProps)(TransferPointsREf);


