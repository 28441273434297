import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import { IoIosArrowDown } from 'react-icons/io';
import { BiMessageRoundedCheck } from 'react-icons/bi';
import { BsArrowDownShort, BsGraphUp } from 'react-icons/bs';
// import moment from 'moment'
import SuspendMobile from '../../components/SuspendMobile/suspended';
// import BackMobile from '../../components/BackMobile/backmobile';
// import BackDesktop from '../../components/BackDesktop/backdesktop';
// import BackLayDesktop from '../../components/BackLayDesktopDesign/backlaydesktop';
import BackLayMobile from '../../components/BackLayMobileDesign/backlaymobile';
// import LayDesktop from '../../components/LayDesktop/laydesktop';
import SuspendDesktop from '../../components/SuspendDesktop/suspended';
import SidebarRight from '../../components/SidebarRight/SidebarRight';
import BetPlaceMobile from '../../components/BetPlaceMobile/BetPlaceMobile';
import EditStakeMobile from '../../components/EditStakeMobile/EditStakeMobile';
import Marquee from "react-fast-marquee";
import Sidebar from "../../components/Sidebar/Sidebar";

class Events extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
      gameTwo: '',
      gameThree: '',
      gameFour: '',
      gameItem: {},
      activeTab: 1,
      tv: false,
      tv2: false,
      data: {
        "limit": 10,
        "pageno": 1
      },
      data1: {
        "limit": 1,
        "pageno": 1,
        "sport_id": 111,
        "series_id": 0
      }
    }
  }
  componentDidMount() {

    // let data = {
    //   "limit": 10,
    //   "pageno": 1
    // }
    // let data1 = {
    //   "limit": 1,
    //   "pageno": 1,
    //   "sport_id": 111,
    //   "series_id": 0
    // }
    // let data2 = {
    //   "limit": 10,
    //   "pageno": 1,
    //   "sport_id": this.props.match.params.sportId,
    //   "series_id": this.props.match.params.seriesId ? this.props.match.params.seriesId : 0
    // }
    // this.props.dispatch(userActions.game_profile(data));
    // this.props.dispatch(userActions.games_list(data));
    // this.props.dispatch(userActions.event_casino(data1));
    // this.props.dispatch(userActions.event_game(data2));
    this.interval = setInterval(() => this.loadData(), 15000);
  }

  loadData = () => {
    let data = {
      "limit": 10,
      "pageno": 1
    }
    let data1 = {
      "limit": 1,
      "pageno": 1,
      "sport_id": 111,
      "series_id": 0
    }
    this.props.dispatch(userActions.game_profile(data));
    this.props.dispatch(userActions.games_list(data));
    this.props.dispatch(userActions.event_casino(data1));
  }

  handleResponseCasino = (data) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data && data.sport_id === 1113) {
      this.props.history.push('/app/teenpatti-t20')
    } else if (data && data.sport_id === 5556) {
      this.props.history.push('/app/amar-akbar-anthony')
    }
    else if (data && data.sport_id === 5557) {
      this.props.history.push('/app/7updown')
    }
    else if (data && data.sport_id === 5559) {
      this.props.history.push('/app/dragon-tiger')
    }
  }

  navigation = (data) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data === 3) {
      this.props.history.push('/app/livegames')
    } else if (data === 4) {
      this.props.history.push("/app/satta-events")
    }
  }

  handleResponseTannis = (data, status) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data && data.order_by === 1) {
      this.props.history.push('/app/cricket-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
    } else if (data && data.order_by === 2) {
      this.props.history.push('/app/tennis-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
    }
    else if (data && data.order_by === 3) {
      this.props.history.push('/app/soccer-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
    }
  }
  handleSidebar = (data) => {
    console.log('datagameItemgameItemgameItem', data);
    this.setState({ gameItem: data });

  }
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };
  handleTv = () => {
    this.setState({ tv: !this.state.tv })
  }
  handleTv2 = () => {
    this.setState({ tv2: !this.state.tv2 })
  }

  render() {
    let { users } = this.props;
    let { getmatchscoreItems, eventdetalsItems, FcurrentTime, getScoreItems, } = users;
    let { MatchDetails } = eventdetalsItems ? eventdetalsItems : {};
    let { runner_json } = MatchDetails ? MatchDetails : {};


    let resData = eventdetalsItems && eventdetalsItems.MatchDetails && eventdetalsItems.MatchDetails.BetAllowTimeBefore ? eventdetalsItems.MatchDetails.BetAllowTimeBefore : "0";

    let Fmatchtime = eventdetalsItems && eventdetalsItems.MatchDetails && eventdetalsItems.MatchDetails.start_date ? eventdetalsItems.MatchDetails.start_date : "0";

    // const matchedBets = { MatchAndBetfair: listbtssnmkItems && listbtssnmkItems.MatchAndBetfair && listbtssnmkItems.MatchAndBetfair.length ? listbtssnmkItems.MatchAndBetfair.length : 0, MatchFancy: listbtssnmkItems && listbtssnmkItems.MatchFancy && listbtssnmkItems.MatchFancy.length ? listbtssnmkItems.MatchFancy.length : 0 };

    // const total = matchedBets.MatchAndBetfair + matchedBets.MatchFancy;

    return (

      <>
        <div className='relative w-full bg-[#EEEEEE] h-full page_bg overflow-y-auto'>

          <div className='flex'>
            <Sidebar
              open={true}
              gameItem={this.state.gameItem}
              // setOpenMobile={setOpenMobile}
              // openMobile={openMobile}
              props={this.props}
              showSport={true}
            />
            <div className="w-full">
              <div className="lg:flex w-full">
                <div className="lg:w-[75%] w-full space-y-1 md:pb-20">
                  <div className='h-screen overflow-y-auto overflow-hidden'>
                    <div className='px-2 py-0.5 bg-black lg:block hidden text-sm font-medium text-white'>
                      <Marquee gradient={false} speed={50}>
                        MATCH REDUCE TO 28 OVER PER SIDE DUE TO RAIN
                      </Marquee>
                    </div>
                    <div className='bg-black mt-0  border-t border-green-400 lg:mt-2 rounded'>
                      <div onClick={() => this.handleTv()} className='px-2 cursor-pointer flex justify-between items-center '>
                        <div className='flex space-x-2 px-2 pt-3 pb-2'>
                          <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                          <p className='text-white text-sm'> Live Tv 1</p>
                        </div>
                        <div><IoIosArrowDown size={25} className='text-white' /></div>
                      </div>
                      {this.state.tv === true ?
                        <div className='flex justify-center items-center'>
                          <img className='w-[300px] ' src="/images/tv.png" alt="" />
                        </div>
                        : null}

                    </div>
                    <div className='bg-black mt-0  border-t border-white lg:mt-2 lg:hidden block rounded'>
                      <div onClick={() => this.handleTv2()} className='px-2 cursor-pointer flex justify-between items-center '>
                        <div className='flex space-x-2 px-2 pt-3 pb-2'>
                          <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                          <p className='text-white text-sm'> Live Tv 2</p>
                        </div>
                        <div><IoIosArrowDown size={25} className='text-white' /></div>
                      </div>
                      {this.state.tv2 === true ?
                        <div className='flex justify-center items-center'>
                          <img className='w-[300px] ' src="/images/tv.png" alt="" />
                        </div>
                        : null}

                    </div>
                    <div class="w-full font-semibold text-[13px] text-black bg-[black] rounded-t-3xl py-[3px] border border-gray-400 mr-[2px]"></div>
                    <div className="flex justify-between items-center  pt-5 bg-[#252328] border-b-2  border-white text-white h-[128px]">
                      <div className="text-left px-2 text-[14px] w-full">
                        <p className="font-semibold text-[#f98f19]">
                          England
                        </p>

                        <div className="font-semibold text-[#E9D125]">
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].score ? getScoreItems[2].score : "0"}-{" "}
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].wicket ? getScoreItems[2].wicket : "0"}{" "}
                        </div>
                        <div>
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].ballsdone / 6 ? (getScoreItems[2].ballsdone / 6).toFixed(1) : "0"}
                        </div>
                      </div>
                      <div className="w-full">

                        <div className="px-2 text-sm">
                          {getScoreItems && getScoreItems[0] && getScoreItems[0].cb && getScoreItems[0].cb !== null ?
                            <p className="text-[14px] text-center">
                              {getScoreItems && getScoreItems[0] && getScoreItems && getScoreItems[0].cb ? getScoreItems[0].cb : "0"}</p> : <>
                            </>}
                        </div>
                        <div className='flex justify-center items-center text-[] '><p>England won the toss and opt to Bowl</p></div>
                        <div className="bg-black rounded-lg py-4">
                          <h2 className="text-[#E8FE26] text-lg font-bold text-center">Ball</h2>
                        </div>
                        <div className="flex justify-center items-center text-[14px] space-x-2">
                          This over:
                          <span className="flex justify-start items-center space-x-2 py-1">
                            {getScoreItems && getScoreItems[0] && getScoreItems[0].recentBalls && getScoreItems[0].recentBalls[0] && getScoreItems[0].recentBalls[0].length > 0 ? getScoreItems[0].recentBalls[0].map((elementtemp, index) => (<>
                              {elementtemp < 4 ?
                                <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div>
                                : <>
                                  {elementtemp === 4 ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === 6 ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === 6 ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === "W" ?
                                    <div className=" bg-red-500 w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                </>}
                            </>
                            )) : "NA"}
                          </span>
                        </div>
                      </div>
                      <div className="px-2 text-right text-[14px] w-full">
                        <p className="font-semibold text-[#f98f19]">
                          Australia
                        </p>

                        <div className="font-semibold text-[#E9D125]">
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].score2 ? getScoreItems[2].score2 : "0"}-{" "}
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].wicket2 ? getScoreItems[2].wicket2 : "0"}{" "}

                        </div>
                        <div>
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].ballsdone2 / 6 ? (getScoreItems[2].ballsdone2 / 6).toFixed(1) : "0"}
                        </div>
                      </div>
                    </div>
                    <div class="w-full font-semibold text-[13px] text-black bg-[black] rounded-b-3xl py-[3px] border border-gray-400 mr-[2px]"></div>
                    <div class="w-full font-semibold text-[13px] text-black bg-[black] rounded-t-3xl py-[3px] border border-gray-400 mr-[2px]"></div>
                    {/* team Desktop data */}
                    <div className="lg:block hidden ">
                      <div className="bg-black flex justify-between items-center py-1.5 px-1 text-white text-[12px] font-medium">
                        <p>
                          West Indies Women v Ireland Women / <span className=''>Match Odds</span>  <span>Min :250  Max :300000</span>
                        </p>

                        <div className='justify-center flex items-center space-x-2'>
                          <p className='text-white w-5 h-5 rounded-full  justify-center flex items-center bg-[#E2C606]' >FS</p>
                          <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                        </div>
                      </div>
                      {/* <BackLayDesktop /> */}

                      <table className="w-full bg-white ">
                        <tbody>
                          <tr className="border-b border-gray-200">
                            <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                            </td>
                            <td>
                              <div className="flex justify-end items-center ">

                                <div class="text-center flex justify-end items-center pt-2">
                                  <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                                  <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                                  <div class="w-24 text-center py-0.5 cursor-pointer">
                                    <span className="text-[16px] ">
                                      LAGAI
                                    </span>
                                    <br />
                                    <span className="flex justify-center items-center">
                                      <BsArrowDownShort className='bg-[#4fa0e7] text-white h-4 w-4 rounded-full' size={16} />
                                    </span>
                                  </div>
                                  <div class="w-24 lg:pb-3 flex space-x-2 py-0.5 cursor-pointer">
                                    <span className="text-[16px]">
                                      KHAI
                                    </span>
                                    <span><BsArrowDownShort className='bg-[#FAA9BA] text-white h-4 w-4 rounded-full' size={16} /></span>
                                  </div>
                                  <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                                  <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="border-b border-gray-200">
                            <td className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                              <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span> West Indies Women</span>
                              <p className='text-sky-500'>0.00</p>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
      <h2 className='text-white text-xs font-bold uppercase'><span>
        Suspended
      </span></h2>
    </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>
                          <tr className="border-b border-gray-200">
                            <td className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                              <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span> Ireland Women</span>
                              <p className='text-sky-500'>0.00</p>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
      <h2 className='text-white text-xs font-bold uppercase'><span>
        Suspended
      </span></h2>
    </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>


                        </tbody>

                      </table>


                    </div>
                    {/* toss Desktop data */}
                    <div className="lg:block hidden bg-white">
                      <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                        <p>
                          Toss Book
                        </p>
                        <div class="text-center flex justify-end items-center pt-2">
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">West</div>
                          <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Ireland</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        </div>
                      </div>

                      <table className="w-full ">

                        <tbody>
                          <tr className="border-b border-black">
                            <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                              <span>WHO WILL WIN THE TOSS </span>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>
                          {/* 
                            {this.state && this.state.fancystakeopen === true ? (
                              <tr className="w-full">
                                <td colSpan={2}>
                                  {this.state.betSlipData && this.state.betSlipData.selectionId && this.state.betSlipData.selectionId === element.id ?
                                    <>

                                      <div className="border-r-2 border-black h-20">
                                        {this.state.betSlipData.selectionName}
                                        <div
                                          class={`p-1 flex justify-end items-center h-8 space-x-1 text-black  ${this.state.betSlipData.type === "Yes" ? "bg-[#72BBEF]" : "bg-[#FAA9BA]"}`}>
                                          <span className="px-2">
                                            {this.state.betSlipData.element.selectionName}
                                          </span>
                                          <span className="bg-gray-400 rounded">
                                            <span className="px-2 font-bold cursor-pointer" onClick={this.decreaseCount}>
                                              -
                                            </span>
                                            <span className="bg-white px-2 py-1 text-sm">
                                              {this.state &&
                                                this.state.count &&
                                                this.state.count
                                                ? this.state.count
                                                : "0"}{" "}
                                            </span>
                                            <span
                                              className=" px-2 font-bold cursor-pointer"
                                              onClick={this.increaseCount}
                                            >
                                              +
                                            </span>
                                          </span>
                                          <input
                                            className="focus:outline-none rounded px-2 w-32"
                                            type="number"
                                            placeholder="0"
                                            value={
                                              this.state.betSlipData.stake
                                            }
                                            onChange={this.inputChange}
                                          />
                                        </div>
                                        <div className="flex justify-end items-center space-x-2 py-2 whitespace-nowrap">
                                          {this.state.finalMatchStack.map(
                                            (element, index) => (
                                              <button
                                                class={`rounded px-4 py-2 text-sm ${this.state.betSlipData
                                                  .type === "Yes"
                                                  ? "bg-gradient-to-b from-[#5FCBE5] to-[#2CA3C2] hover:from-[#2CA3C2] hover:to-[#5FCBE5]"
                                                  : "bg-gradient-to-b from-[#FABBBD] to-[#EB7D84] hover:from-[#EB7D84] hover:to-[#FABBBD]"
                                                  }`}
                                                onClick={() =>
                                                  this.updateStackOnclic(
                                                    element,
                                                  )
                                                }
                                              >
                                                {element}
                                              </button>
                                            ),
                                          )}

                                          <button
                                            className="bg-[#38C20D] rounded px-4 py-2 text-sm text-white"
                                            onClick={() =>
                                              this.placeBet3()
                                            }
                                          >
                                            Place Bet
                                          </button>
                                          <button
                                            className="bg-[#4e4b42] rounded px-4 py-2 text-sm"
                                            onClick={() =>
                                              this.handleFancyStakeOpen()
                                            }
                                          >
                                            Edit Stake
                                          </button>
                                          <button
                                            className="bg-[#F70407] rounded px-4 py-2 text-sm text-white"
                                            onClick={() =>
                                              this.handleFancyClose()
                                            }
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      </div>
                                      <BetPlaceMobile
                                        betSlipData={this.state.betSlipData}
                                        finalMatchStack={this.state.finalMatchStack}
                                        updateStackOnclic={this.updateStackOnclic}
                                        inputChange={this.inputChange}
                                        decreaseCount={this.decreaseCount}
                                        increaseCount={this.increaseCount}
                                        placeBet={this.placeBet3}
                                        handleClose={this.handleFancyClose}
                                        handlestakeOpenWeb={this.handleFancyStakeOpen}
                                        count={this.state.count}
                                      />

                                    </>
                                    : null}
                                </td>
                              </tr>) : null}


                            {this.state &&
                              this.state.fancystake === true ? (
                              <tr>
                                <td colSpan={2}>
                                  {this.state.betSlipData &&
                                    this.state.betSlipData.selectionId &&
                                    this.state.betSlipData.selectionId ===
                                    element.id ? <>

                                    <EditStakeMobile
                                      handleCurrentIndex={this.handleCurrentIndex}
                                      finalMatchStack={this.state.finalMatchStack}
                                      handlestakeClose={this.handlestakeClosefancy}
                                      handleStakeInput={this.handleStakeInput}
                                    />
                                  </>
                                    : null}
                                </td>
                              </tr>) : null
                            }

                            {this.state && this.state.fancyrunopen === true ? (
                              <tr className="w-full">
                                <td colSpan={2}>
                                  {this.state.betSlipData && this.state.betSlipData.selectionId && this.state.betSlipData.selectionId === element.id ? (

                                    <table className="min-w-full capitalize border border-collapse ">
                                      <tr className=" text-white text-[0.8125rem] text-left font-semibold bg-[#265467]">
                                        <th className="px-[6px] py-1 whitespace-nowrap border border-slate-300 ">Runs</th>
                                        <th className="px-[6px] py-1 whitespace-nowrap border border-slate-300">P&L</th>
                                        {JSON.stringify(this.state.rundata)}

                                      </tr>
                                      {
                                        this.state.rundata && this.state.rundata > 0 ?
                                          this.state.rundata.map((element, index) => (
                                            <React.Fragment >
                                              <tr className=" text-black text-[0.8125rem] text-left font-medium">
                                                <td className="px-[6px] py-1 whitespace-nowrap border border-slate-300">{element && element.key ? element.key : "-"}</td>
                                                <td className={`px-[6px] py-1 whitespace-nowrap border border-slate-300 ${element.userPL > 0 ? "text-green-600" : "text-red-600"}`}>{element && element.value ? element.value : "-"}</td>
                                              </tr>

                                            </React.Fragment>
                                          )) :
                                          <tr className="">
                                            <td colSpan={2} className="py-1 px-[6px] text-black text-sm text-center whitespace-nowrap">
                                              There are no records to show
                                            </td>
                                          </tr>
                                      }
                                    </table>

                                  ) : null}
                                </td>
                              </tr>) : null} */}
                        </tbody>

                      </table>

                    </div>


                    {/*toss mobile */}
                    <div className="lg:hidden block">
                      <div className="bg-black flex justify-start items-center py-1.5 px-1 text-white text-sm font-medium">
                        <span>
                          Toss Book
                        </span>
                      </div>
                      <BackLayMobile />

                      <table className="w-full ">
                        {getmatchscoreItems && getmatchscoreItems.SSN && getmatchscoreItems.SSN.length > 0 ? getmatchscoreItems.SSN.map((element, index) => (
                          <tbody>
                            <tr className="border-b border-black">
                              <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                                <span>{element && element.RunnerName ? element.RunnerName : "NA"} </span>
                              </td>
                              <td>
                                {
                                  element && element.GameStatus && element.GameStatus === "SUSPENDED" ?
                                    <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                      <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                        <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                          <span className="text-[13px] font-semibold">00</span><br />
                                          <span className="text-[10px]">0</span>
                                        </div>
                                        <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                          <span className="text-[13px] font-semibold">00</span><br />
                                          <span className="text-[10px]">0</span>
                                        </div>
                                        <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                          <h2 className='text-white text-xs font-bold uppercase'><span>
                                            Suspended
                                          </span></h2>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                      <div className="flex flex-row-reverse divide-x-2 divide-gray-200">
                                        <td className="border-l-2 w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0"
                                          onClick={() => this.handleFancyOpenMob({
                                            data: element, type: "No", element: element, selectionId: element.id, run: element.BackPrice1, size: element.BackSize1,
                                          })}>
                                          <span className="text-[13px] font-semibold">{element && element.BackPrice1 ? element.BackPrice1 : "0"}</span>
                                          <br />
                                          <span className="text-[10px]">{element && element.BackSize1 ? element.BackSize1 : "0"}</span>
                                        </td>
                                      </div>
                                      <div className="flex divide-x-2 divide-gray-200">
                                        <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0"
                                          onClick={() => this.handleFancyOpenMob({
                                            data: element, type: "Yes", element: element, selectionId: element.id, run: element.LayPrice1, size: element.LaySize1
                                          })}>
                                          <span className="text-[13px] font-semibold">{element && element.LayPrice1 ? element.LayPrice1 : "0"}</span>
                                          <br />
                                          <span className="text-[10px]">{element && element.LaySize1 ? element.LaySize1 : "0"}</span>
                                        </div>
                                      </div>
                                    </div>
                                }
                              </td>
                            </tr>

                            <tr className="w-full">
                              <td colSpan={2}>
                                {this.state &&
                                  this.state.fancyBookModalMob === true ? (
                                  <>
                                    {this.state.betSlipData &&
                                      this.state.betSlipData.selectionId &&
                                      this.state.betSlipData.selectionId ===
                                      element.id ? (
                                      <div className="border-2 border-black">
                                        {
                                          this.state.betSlipData
                                            .selectionName
                                        }
                                        <div
                                          class={`p-1 flex justify-end items-center space-x-1 text-black  ${this.state.betSlipData.type ===
                                            "Yes"
                                            ? "bg-[#72BBEF]"
                                            : "bg-[#FAA9BA] "
                                            }`}
                                        >
                                          <span className="px-2">
                                            {
                                              this.state.betSlipData.element
                                                .selectionName
                                            }
                                          </span>
                                          <span className="bg-gray-400 rounded">
                                            <span
                                              className="px-2 font-bold cursor-pointer"
                                              onClick={this.decreaseCount}
                                            >
                                              -
                                            </span>
                                            <span className="bg-white px-2 py-1 text-sm">
                                              {this.state &&
                                                this.state.count &&
                                                this.state.count
                                                ? this.state.count
                                                : "0"}{" "}
                                            </span>
                                            <span
                                              className=" px-2 font-bold cursor-pointer"
                                              onClick={this.increaseCount}
                                            >
                                              +
                                            </span>
                                          </span>
                                          <input
                                            className="focus:outline-none rounded px-2 w-32"
                                            type="number"
                                            placeholder="0"
                                            value={
                                              this.state.betSlipData.stake
                                            }
                                            onChange={this.inputChange}
                                          />
                                        </div>
                                        {eventdetalsItems &&
                                          eventdetalsItems.UserSportSettings &&
                                          eventdetalsItems.UserSportSettings
                                            .length > 0
                                          ? eventdetalsItems.UserSportSettings.map(
                                            (element, index) => (
                                              <div className="py-2">
                                                <div className="flex justify-start items-center divide-x border divide-black/20 border-black/20   py-2">
                                                  {this.state.finalMatchStack.map(
                                                    (element, index) => (
                                                      <button
                                                        class={`px-3 py-1 text-sm font-medium ${this.state
                                                          .betSlipData
                                                          .type ===
                                                          "Yes"
                                                          ? "bg-gradient-to-b from-[#5FCBE5] to-[#2CA3C2] hover:from-[#2CA3C2] hover:to-[#5FCBE5]"
                                                          : "bg-gradient-to-b from-[#FABBBD] to-[#EB7D84] hover:from-[#EB7D84] hover:to-[#FABBBD]"
                                                          }`}
                                                        onClick={() =>
                                                          this.updateStackOnclic(
                                                            element,
                                                          )
                                                        }
                                                      >
                                                        {element}
                                                      </button>
                                                    ),
                                                  )}
                                                </div>

                                                <div className="flex flex-row-reverse gap-2">
                                                  <button
                                                    className="bg-gradient-to-b from-[#07ae07] to-[#026e02]   rounded px-4 py-2 text-sm text-white w-full"
                                                    onClick={() =>
                                                      this.placeBet3()
                                                    }
                                                  >
                                                    Place Bet
                                                  </button>
                                                  <button
                                                    className=" bg-gradient-to-b from-[#fdba06] to-[#e5a700]  rounded px-4 py-2 text-sm w-full"
                                                    onClick={() =>
                                                      this.handleFancyStakeOpenMob()
                                                    }
                                                  >
                                                    Edit Stake
                                                  </button>
                                                  <button
                                                    className="  bg-gradient-to-b from-[#fe4f70] to-[#c3062a]  rounded px-4 py-2 text-sm text-white w-full"
                                                    onClick={() =>
                                                      this.handleClosefancyMob()
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            ),
                                          )
                                          : null}
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                            </tr>

                            {/* table view */}
                            <tr>
                              <td colSpan={2}>
                                {this.state &&
                                  this.state.fancyBookstakeMob === true ? (
                                  <>
                                    {this.state.betSlipData &&
                                      this.state.betSlipData.selectionId &&
                                      this.state.betSlipData.selectionId ===
                                      element.id ? (
                                      <div className="grid grid-cols-5 gap-2  py-2">
                                        {this.state.finalMatchStack.map(
                                          (element, index) => (
                                            <input
                                              onChange={
                                                this.handleStakeInput
                                              }
                                              onClick={() =>
                                                this.handleCurrentIndex(
                                                  index,
                                                )
                                              }
                                              type="text"
                                              className="bg-white text-black border border-black rounded px-8 py-1 text-xs"
                                              value={element}
                                            />
                                          ),
                                        )}
                                        <button
                                          onClick={() =>
                                            this.handlestakeClosefancyMob()
                                          }
                                          className="bg-[#29C20E] rounded px-4 py-2 text-sm text-white flex justify-center items-center mx-auto"
                                        >
                                          <BiMessageRoundedCheck /> Save
                                        </button>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        ),
                        )
                          : null}
                      </table>

                    </div>

                    {/* Fancy Desktop data */}
                    <div className="lg:block hidden bg-white">
                      <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                        <p>
                          Fancy  <span className='text-xs'>Min :250  Max :300000</span>
                        </p>
                        <div class="text-center flex justify-end items-center pt-2">
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                          <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        </div>
                      </div>

                      <table className="w-full ">

                        <tbody>
                          <tr className="border-b border-gray-200">
                            <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                              <span>5 over runs AUS(ENG vs AUS)adv </span>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>
                          <tr className="border-b border-gray-200">
                            <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                              <span>Only 5 over run AUS </span>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>
                          <tr className="border-b border-gray-200">
                            <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                              <span>Fall of 1st wkt AUS(ENG vs AUS)adv </span>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>
                          <tr className="border-b border-gray-200">
                            <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                              <span>U Khawaja Boundaries(ENG vs AUS)adv </span>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>

                        </tbody>

                      </table>

                    </div>


                    {/*Fancy mobile */}
                    <div className="lg:hidden block">
                      <div className="bg-[#1C77C7] flex justify-start items-center py-1.5 px-1 text-white text-sm font-medium">
                        <span>
                          Indian Fancy
                        </span>
                      </div>
                      <BackLayMobile />

                      <table className="w-full ">
                        {getmatchscoreItems && getmatchscoreItems.SSN && getmatchscoreItems.SSN.length > 0 ? getmatchscoreItems.SSN.map((element, index) => (
                          <tbody>
                            <tr className="border-b border-black">
                              <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                                <span>{element && element.RunnerName ? element.RunnerName : "NA"} </span>
                              </td>
                              <td>
                                {
                                  element && element.GameStatus && element.GameStatus === "SUSPENDED" ?
                                    <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                      <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                        <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                          <span className="text-[13px] font-semibold">00</span><br />
                                          <span className="text-[10px]">0</span>
                                        </div>
                                        <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                          <span className="text-[13px] font-semibold">00</span><br />
                                          <span className="text-[10px]">0</span>
                                        </div>
                                        <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                          <h2 className='text-white text-xs font-bold uppercase'><span>
                                            Suspended
                                          </span></h2>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                      <div className="flex flex-row-reverse divide-x-2 divide-gray-200">
                                        <td className="border-l-2 w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0"
                                          onClick={() => this.handleFancyOpenMob({
                                            data: element, type: "No", element: element, selectionId: element.id, run: element.BackPrice1, size: element.BackSize1,
                                          })}>
                                          <span className="text-[13px] font-semibold">{element && element.BackPrice1 ? element.BackPrice1 : "0"}</span>
                                          <br />
                                          <span className="text-[10px]">{element && element.BackSize1 ? element.BackSize1 : "0"}</span>
                                        </td>
                                      </div>
                                      <div className="flex divide-x-2 divide-gray-200">
                                        <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0"
                                          onClick={() => this.handleFancyOpenMob({
                                            data: element, type: "Yes", element: element, selectionId: element.id, run: element.LayPrice1, size: element.LaySize1
                                          })}>
                                          <span className="text-[13px] font-semibold">{element && element.LayPrice1 ? element.LayPrice1 : "0"}</span>
                                          <br />
                                          <span className="text-[10px]">{element && element.LaySize1 ? element.LaySize1 : "0"}</span>
                                        </div>
                                      </div>
                                    </div>
                                }
                              </td>
                            </tr>

                            <tr className="w-full">
                              <td colSpan={2}>
                                {this.state &&
                                  this.state.fancyBookModalMob === true ? (
                                  <>
                                    {this.state.betSlipData &&
                                      this.state.betSlipData.selectionId &&
                                      this.state.betSlipData.selectionId ===
                                      element.id ? (
                                      <div className="border-2 border-black">
                                        {
                                          this.state.betSlipData
                                            .selectionName
                                        }
                                        <div
                                          class={`p-1 flex justify-end items-center space-x-1 text-black  ${this.state.betSlipData.type ===
                                            "Yes"
                                            ? "bg-[#72BBEF]"
                                            : "bg-[#FAA9BA] "
                                            }`}
                                        >
                                          <span className="px-2">
                                            {
                                              this.state.betSlipData.element
                                                .selectionName
                                            }
                                          </span>
                                          <span className="bg-gray-400 rounded">
                                            <span
                                              className="px-2 font-bold cursor-pointer"
                                              onClick={this.decreaseCount}
                                            >
                                              -
                                            </span>
                                            <span className="bg-white px-2 py-1 text-sm">
                                              {this.state &&
                                                this.state.count &&
                                                this.state.count
                                                ? this.state.count
                                                : "0"}{" "}
                                            </span>
                                            <span
                                              className=" px-2 font-bold cursor-pointer"
                                              onClick={this.increaseCount}
                                            >
                                              +
                                            </span>
                                          </span>
                                          <input
                                            className="focus:outline-none rounded px-2 w-32"
                                            type="number"
                                            placeholder="0"
                                            value={
                                              this.state.betSlipData.stake
                                            }
                                            onChange={this.inputChange}
                                          />
                                        </div>
                                        {eventdetalsItems &&
                                          eventdetalsItems.UserSportSettings &&
                                          eventdetalsItems.UserSportSettings
                                            .length > 0
                                          ? eventdetalsItems.UserSportSettings.map(
                                            (element, index) => (
                                              <div className="py-2">
                                                <div className="flex justify-start items-center divide-x border divide-black/20 border-black/20   py-2">
                                                  {this.state.finalMatchStack.map(
                                                    (element, index) => (
                                                      <button
                                                        class={`px-3 py-1 text-sm font-medium ${this.state
                                                          .betSlipData
                                                          .type ===
                                                          "Yes"
                                                          ? "bg-gradient-to-b from-[#5FCBE5] to-[#2CA3C2] hover:from-[#2CA3C2] hover:to-[#5FCBE5]"
                                                          : "bg-gradient-to-b from-[#FABBBD] to-[#EB7D84] hover:from-[#EB7D84] hover:to-[#FABBBD]"
                                                          }`}
                                                        onClick={() =>
                                                          this.updateStackOnclic(
                                                            element,
                                                          )
                                                        }
                                                      >
                                                        {element}
                                                      </button>
                                                    ),
                                                  )}
                                                </div>

                                                <div className="flex flex-row-reverse gap-2">
                                                  <button
                                                    className="bg-gradient-to-b from-[#07ae07] to-[#026e02]   rounded px-4 py-2 text-sm text-white w-full"
                                                    onClick={() =>
                                                      this.placeBet3()
                                                    }
                                                  >
                                                    Place Bet
                                                  </button>
                                                  <button
                                                    className=" bg-gradient-to-b from-[#fdba06] to-[#e5a700]  rounded px-4 py-2 text-sm w-full"
                                                    onClick={() =>
                                                      this.handleFancyStakeOpenMob()
                                                    }
                                                  >
                                                    Edit Stake
                                                  </button>
                                                  <button
                                                    className="  bg-gradient-to-b from-[#fe4f70] to-[#c3062a]  rounded px-4 py-2 text-sm text-white w-full"
                                                    onClick={() =>
                                                      this.handleClosefancyMob()
                                                    }
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            ),
                                          )
                                          : null}
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                            </tr>

                            {/* table view */}
                            <tr>
                              <td colSpan={2}>
                                {this.state &&
                                  this.state.fancyBookstakeMob === true ? (
                                  <>
                                    {this.state.betSlipData &&
                                      this.state.betSlipData.selectionId &&
                                      this.state.betSlipData.selectionId ===
                                      element.id ? (
                                      <div className="grid grid-cols-5 gap-2  py-2">
                                        {this.state.finalMatchStack.map(
                                          (element, index) => (
                                            <input
                                              onChange={
                                                this.handleStakeInput
                                              }
                                              onClick={() =>
                                                this.handleCurrentIndex(
                                                  index,
                                                )
                                              }
                                              type="text"
                                              className="bg-white text-black border border-black rounded px-8 py-1 text-xs"
                                              value={element}
                                            />
                                          ),
                                        )}
                                        <button
                                          onClick={() =>
                                            this.handlestakeClosefancyMob()
                                          }
                                          className="bg-[#29C20E] rounded px-4 py-2 text-sm text-white flex justify-center items-center mx-auto"
                                        >
                                          <BiMessageRoundedCheck /> Save
                                        </button>
                                      </div>
                                    ) : null}
                                  </>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        ),
                        )
                          : null}
                      </table>

                    </div>

                    {/* Extra Fancy Desktop data */}
                    <div className="lg:block hidden bg-white">
                      <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                        <p>
                          Extra  Fancy  <span className='text-xs'>Min :100   Max :50000</span>
                        </p>
                        <div class="text-center flex justify-end items-center pt-2">
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                          <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                          <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                        </div>
                      </div>

                      <table className="w-full ">

                        <tbody>
                          <tr className="border-b border-gray-200">
                            <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                              <span>Top batsman runs in match(WI W vs IRE W)adv </span>
                            </td>
                            <td>

                              <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                    <span className="text-[13px] font-semibold">0.92</span><br />
                                    <span className="text-[10px]">0</span>
                                  </div>
                                  {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer">
                                </div>
                                <div className="w-24 text-center py-0.5 cursor-pointer" >
                                </div>
                              </div>

                            </td>
                          </tr>



                        </tbody>

                      </table>

                    </div>

                    <div className='w-full text-center text-blue-500 py-3  text-sm bg-[#FFFFFF] border-t-[6px] border-black rounded-t-xl mb-2' >
                      <p className='group-hover:text-black hover:text-black cursor-pointer'>See All Completed Bets</p>
                    </div>






                    {/* mobile */}
                    <div className="lg:hidden block">
                      <div className="bg-[#1C77C7] flex justify-start items-center py-1.5 px-1 text-white text-sm font-medium">
                        <span>
                          {eventdetalsItems &&
                            eventdetalsItems.MatchDetails &&
                            eventdetalsItems.MatchDetails.marketName
                            ? eventdetalsItems.MatchDetails.marketName
                            : null}{" "}
                        </span>
                        <span>
                          {eventdetalsItems &&
                            eventdetalsItems.MatchDetails &&
                            eventdetalsItems.MatchDetails.matchVolumn
                            ? eventdetalsItems.MatchDetails.matchVolumn
                            : null}
                        </span>
                      </div>
                      <BackLayMobile />
                      {FcurrentTime > Fmatchtime - resData ? (
                        <table className="w-full ">
                          {getmatchscoreItems &&
                            getmatchscoreItems.MatchDetails &&
                            getmatchscoreItems.MatchDetails.runner_json &&
                            getmatchscoreItems.MatchDetails.runner_json.length > 0
                            ? getmatchscoreItems.MatchDetails.runner_json.map(
                              (element, index) => (
                                <tbody>
                                  <tr className="border-b border-black">
                                    <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                                      <span>{runner_json && runner_json[index] && runner_json[index].selectionName ? runner_json[index].selectionName : "NA"} </span>
                                      {runner_json && runner_json[index] && runner_json[index].WinAndLoss && runner_json[index].WinAndLoss === "0" ? null :
                                        <span className={`px-4 ${runner_json && runner_json[index] && runner_json[index].WinAndLoss && runner_json[index].WinAndLoss < 0 ? "text-red-500" : "text-green-500"}`} >
                                          {runner_json && runner_json[index] && runner_json[index].WinAndLoss ?
                                            this.state.betSlipData.stake ? (runner_json[index].WinAndLoss + (this.state.betSlipData.stake * this.state.count) - this.state.betSlipData.stake) : runner_json[index].WinAndLoss
                                            : null}
                                        </span>
                                      }
                                    </td>

                                    {
                                      element && element.GameStatus && element.GameStatus === "SUSPENDED" ?
                                        <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                          <SuspendDesktop />
                                        </div>
                                        :
                                        <td>
                                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                                            <div className="flex flex-row-reverse divide-x-2 divide-gray-200">
                                              {element &&
                                                element.ex &&
                                                element.ex.availableToBack &&
                                                element.ex.availableToBack.length >
                                                0 ? (
                                                element.ex.availableToBack.map(
                                                  (temp, index) => (
                                                    <React.Fragment>
                                                      {index === 0 ? (
                                                        <td
                                                          className={`border-l-2 w-24 text-center py-0.5 cursor-pointer ${index === 0
                                                            ? "match_bg_blue_index-0"
                                                            : ""
                                                            }`}
                                                          onClick={() =>
                                                            this.handleBackOpen({
                                                              data: temp,
                                                              type: "BACK",
                                                              element: element,
                                                              selectionId:
                                                                element.selectionId,
                                                            })
                                                          }
                                                        >
                                                          <span className="text-[13px] font-semibold">
                                                            {temp && temp.price
                                                              ? temp.price
                                                              : "-"}
                                                          </span>
                                                          <br />
                                                          <span className="text-[10px]">
                                                            {temp && temp.size
                                                              ? (
                                                                temp.size / 1000
                                                              ).toFixed(3)
                                                              : "-"}
                                                          </span>
                                                        </td>
                                                      ) : null}
                                                    </React.Fragment>
                                                  ),
                                                )
                                              ) : (
                                                <tr className="bg-white bg-opacity-5 ">
                                                  <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">
                                                    Not cound
                                                  </td>
                                                </tr>
                                              )}
                                            </div>
                                            <div className="flex divide-x-2 divide-gray-200">
                                              {element &&
                                                element.ex &&
                                                element.ex.availableToLay &&
                                                element.ex.availableToLay.length > 0 ? (
                                                element.ex.availableToLay.map(
                                                  (tempData, index) => (
                                                    <React.Fragment>
                                                      {index === 0 ? (
                                                        <div
                                                          className={`w-24 text-center py-0.5 cursor-pointer ${index === 0
                                                            ? "match_bg_pink_index-0"
                                                            : ""
                                                            }`}
                                                          onClick={() =>
                                                            this.handleBackOpen({
                                                              data: tempData,
                                                              type: "LAY",
                                                              element: element,
                                                              selectionId:
                                                                element.selectionId,
                                                            })
                                                          }
                                                        >
                                                          <span className="text-[13px] font-semibold">
                                                            {tempData &&
                                                              tempData.price
                                                              ? tempData.price
                                                              : "-"}
                                                          </span>
                                                          <br />
                                                          <span className="text-[10px]">
                                                            {tempData && tempData.size
                                                              ? (
                                                                tempData.size / 1000
                                                              ).toFixed(3)
                                                              : "-"}
                                                          </span>
                                                        </div>
                                                      ) : null}
                                                    </React.Fragment>
                                                  ),
                                                )
                                              ) : (
                                                <tr className="bg-white bg-opacity-5 ">
                                                  <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">
                                                    Not cound
                                                  </td>
                                                </tr>
                                              )}
                                            </div>
                                          </div>
                                        </td>
                                    }
                                  </tr>

                                  <tr className="w-full">
                                    <td colSpan={2}>
                                      {this.state &&
                                        this.state.backBetModal === true ? (
                                        <>
                                          {this.state.betSlipData &&
                                            this.state.betSlipData.selectionId &&
                                            this.state.betSlipData.selectionId ===
                                            element.selectionId ? <>
                                            <div className="border-2 border-black">
                                              {
                                                this.state.betSlipData
                                                  .selectionName
                                              }
                                              <div
                                                class={`p-1 flex justify-end items-center space-x-1 text-black  ${this.state.betSlipData.type ===
                                                  "BACK"
                                                  ? "bg-[#72BBEF]"
                                                  : "bg-[#FAA9BA] "
                                                  }`}
                                              >
                                                <span className="px-2">
                                                  {
                                                    this.state.betSlipData.element
                                                      .selectionName
                                                  }
                                                </span>
                                                <span className="bg-gray-400 rounded">
                                                  <span
                                                    className="px-2 font-bold cursor-pointer"
                                                    onClick={this.decreaseCount}
                                                  >
                                                    -
                                                  </span>
                                                  <span className="bg-white px-2 py-1 text-sm">
                                                    {this.state &&
                                                      this.state.count &&
                                                      this.state.count
                                                      ? this.state.count
                                                      : "0"}{" "}
                                                  </span>
                                                  <span
                                                    className=" px-2 font-bold cursor-pointer"
                                                    onClick={this.increaseCount}
                                                  >
                                                    +
                                                  </span>
                                                </span>
                                                <input
                                                  className="focus:outline-none rounded px-2 w-32"
                                                  type="number"
                                                  placeholder="0"
                                                  value={
                                                    this.state.betSlipData.stake
                                                  }
                                                  onChange={this.inputChange}
                                                />
                                              </div>
                                              {eventdetalsItems &&
                                                eventdetalsItems.UserSportSettings &&
                                                eventdetalsItems.UserSportSettings
                                                  .length > 0
                                                ? eventdetalsItems.UserSportSettings.map(
                                                  (element, index) => (
                                                    <div className="py-2">
                                                      <div className="flex justify-start items-center divide-x border divide-black/20 border-black/20   py-2">
                                                        {this.state.finalMatchStack.map(
                                                          (element, index) => (
                                                            <button
                                                              class={`px-3 py-1 text-sm font-medium ${this.state
                                                                .betSlipData
                                                                .type ===
                                                                "BACK"
                                                                ? "bg-gradient-to-b from-[#5FCBE5] to-[#2CA3C2] hover:from-[#2CA3C2] hover:to-[#5FCBE5]"
                                                                : "bg-gradient-to-b from-[#FABBBD] to-[#EB7D84] hover:from-[#EB7D84] hover:to-[#FABBBD]"
                                                                }`}
                                                              onClick={() =>
                                                                this.updateStackOnclic(
                                                                  element,
                                                                )
                                                              }
                                                            >
                                                              {element}
                                                            </button>
                                                          ),
                                                        )}
                                                      </div>

                                                      <div className="flex flex-row-reverse gap-2">
                                                        <button
                                                          className="bg-gradient-to-b from-[#07ae07] to-[#026e02]   rounded px-4 py-2 text-sm text-white w-full"
                                                          onClick={() =>
                                                            this.placeBet()
                                                          }
                                                        >
                                                          Place Bet
                                                        </button>
                                                        <button
                                                          className=" bg-gradient-to-b from-[#fdba06] to-[#e5a700]  rounded px-4 py-2 text-sm w-full"
                                                          onClick={() =>
                                                            this.handlestakeOpen()
                                                          }
                                                        >
                                                          Edit Stake
                                                        </button>
                                                        <button
                                                          className="  bg-gradient-to-b from-[#fe4f70] to-[#c3062a]  rounded px-4 py-2 text-sm text-white w-full"
                                                          onClick={() =>
                                                            this.handleClose()
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                      </div>
                                                    </div>
                                                  ),
                                                )
                                                : null}
                                            </div>
                                            <BetPlaceMobile
                                              betSlipData={this.state.betSlipData}
                                              finalMatchStack={this.state.finalMatchStack}
                                              updateStackOnclic={this.updateStackOnclic}
                                              inputChange={this.inputChange}
                                              decreaseCount={this.decreaseCount}
                                              increaseCount={this.increaseCount}
                                              placeBet={this.placeBet}
                                              handleClose={this.handleClose}
                                              handlestakeOpenWeb={this.handlestakeOpenWeb}
                                              count={this.state.count}
                                            />
                                          </>
                                            : null}
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>

                                  {/* table view */}
                                  <tr>
                                    <td colSpan={2}>
                                      {this.state &&
                                        this.state.stakeopen === true ? (
                                        <>
                                          {this.state.betSlipData &&
                                            this.state.betSlipData.selectionId &&
                                            this.state.betSlipData.selectionId ===
                                            element.selectionId ? <>
                                            {/* <div className="grid grid-cols-5 gap-2  py-2">
                                          {this.state.finalMatchStack.map(
                                            (element, index) => (
                                              <input
                                                onChange={
                                                  this.handleStakeInput
                                                }
                                                onClick={() =>
                                                  this.handleCurrentIndex(
                                                    index,
                                                  )
                                                }
                                                type="text"
                                                className="bg-white text-black border border-black rounded px-8 py-1 text-xs"
                                                value={element}
                                              />
                                            ),
                                          )}
                                          <button
                                            onClick={() =>
                                              this.handlestakeClose()
                                            }
                                            className="bg-[#29C20E] rounded px-4 py-2 text-sm text-white flex justify-center items-center mx-auto"
                                          >
                                            <BiMessageRoundedCheck /> Save
                                          </button>
                                        </div> */}
                                            <EditStakeMobile
                                              handleCurrentIndex={this.handleCurrentIndex}
                                              finalMatchStack={this.state.finalMatchStack}
                                              handlestakeClose={this.handlestakeClose}
                                              handleStakeInput={this.handleStakeInput}
                                            />
                                          </> : null}
                                        </>
                                      ) : null}
                                    </td>
                                  </tr>
                                </tbody>
                              ),
                            )
                            :
                            <SuspendMobile />}
                        </table>
                      ) : (
                        <table className="w-full ">
                          {eventdetalsItems &&
                            eventdetalsItems.MatchDetails &&
                            eventdetalsItems.MatchDetails.runner_json &&
                            eventdetalsItems.MatchDetails.runner_json.length > 0
                            ? eventdetalsItems.MatchDetails.runner_json.map(
                              (element, index) => (
                                <tbody>
                                  <tr className="border-b border-black">
                                    <td className="flex justify-start items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full divide-y divide-gray-400">
                                      <span>
                                        {element && element.selectionName
                                          ? element.selectionName
                                          : "NA"}
                                      </span>
                                    </td>

                                    <td>
                                      <div className="flex justify-end items-center divide-x-2 divide-gray-200 relative">
                                        <div className="flex flex-row-reverse divide-x-2 divide-gray-200">
                                          {element &&
                                            element.ex &&
                                            element.ex.availableToBack &&
                                            element.ex.availableToBack.length >
                                            0 ? (
                                            element.ex.availableToBack.map(
                                              (temp, index) => (
                                                <React.Fragment>
                                                  {index === 0 ? (
                                                    <td
                                                      className={`border-l-2 w-24 text-center py-0.5 cursor-pointer ${index === 0
                                                        ? "match_bg_blue_index-0"
                                                        : ""
                                                        }`} >
                                                      <span className="text-[13px] font-semibold">
                                                        {temp && temp.price
                                                          ? temp.price
                                                          : "-"}
                                                      </span>
                                                      <br />
                                                      <span className="text-[10px]">
                                                        {temp && temp.size
                                                          ? (
                                                            temp.size / 1000
                                                          ).toFixed(3)
                                                          : "-"}
                                                      </span>
                                                    </td>
                                                  ) : null}
                                                </React.Fragment>
                                              ),
                                            )
                                          ) : (
                                            <tr className="bg-white bg-opacity-5 ">
                                              <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">
                                                Not cound
                                              </td>
                                            </tr>
                                          )}
                                        </div>
                                        <div className="flex divide-x-2 divide-gray-200">
                                          {element &&
                                            element.ex &&
                                            element.ex.availableToLay &&
                                            element.ex.availableToLay.length > 0 ? (
                                            element.ex.availableToLay.map(
                                              (tempData, index) => (
                                                <React.Fragment>
                                                  {index === 0 ? (
                                                    <div
                                                      className={`w-24 text-center py-0.5 cursor-pointer ${index === 0
                                                        ? "match_bg_pink_index-0"
                                                        : ""}`} >
                                                      <span className="text-[13px] font-semibold">
                                                        {tempData &&
                                                          tempData.price
                                                          ? tempData.price
                                                          : "-"}
                                                      </span>
                                                      <br />
                                                      <span className="text-[10px]">
                                                        {tempData && tempData.size
                                                          ? (
                                                            tempData.size / 1000
                                                          ).toFixed(3)
                                                          : "-"}
                                                      </span>
                                                    </div>
                                                  ) : null}
                                                </React.Fragment>
                                              ),
                                            )
                                          ) : (
                                            <tr className="bg-white bg-opacity-5 ">
                                              <td className="col-span-3 px-6 py-3 text-sm font-medium text-gray-500 whitespace-nowrap">
                                                Not cound
                                              </td>
                                            </tr>
                                          )}
                                        </div>
                                        <div
                                          className="absolute bg-black/50 w-full h-full flex justify-center items-center"
                                          style={{
                                            backgroundImage: `url("/images/supend_bg.png")`,
                                          }}
                                        >
                                          <h2 className="text-white text-xs font-bold">
                                            <span>
                                              {this.secToTime(
                                                parseInt(Date.now() / 1000),
                                                eventdetalsItems &&
                                                  eventdetalsItems.MatchDetails &&
                                                  eventdetalsItems.MatchDetails
                                                    .start_date
                                                  ? eventdetalsItems.MatchDetails
                                                    .start_date - resData
                                                  : null,
                                              )}
                                            </span>
                                          </h2>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              ),
                            )
                            : null}
                        </table>
                      )}
                    </div>


                  </div>
                </div>
                <div className="w-[25%] lg:block hidden h-screen bg-white">
                  <div className="bg-[#2A2A2A] flex justify-between items-center rounded-t-[6px] py-2 px-2 text-white text-sm font-medium">
                    <div>
                      BetSlip
                    </div>
                    <div>
                      <button onClick={() => this.setEditStakeOpen()} className='inline-flex items-center justify-center w-full px-2 py-1 text-[13px]  leading-none text-black bg-[#FFFAEC] border border-transparent rounded-full'>Edit Stake</button>
                    </div>
                  </div>
                  <SidebarRight />
                </div>
              </div>
            </div>

          </div>
        </div >
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(Events);
