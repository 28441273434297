export const createAccountConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    APP_DETAIL_REQUEST: 'APP_DETAIL_REQUEST',
    APP_DETAIL_SUCCESS: 'APP_DETAIL_SUCCESS',
    APP_DETAIL_FAILURE: 'APP_DETAIL_FAILURE',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

};
