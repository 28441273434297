import React, { Component } from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import OtpTimer from 'otp-timer'

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.OtpVerify = this.OtpVerify.bind(this);
    this.state = {
      fieldslogin: {},
      errorslogin: {},
      passtype: "password",
      visible: false,
    };
  }

  componentDidMount() {
  
    this.props.dispatch(userActions.ClearUser());

  }

  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = this.state.errorslogin;
    fieldslogin[name] = value;
    errorslogin[name] = "";
    console.log(name, value);
    this.setState({ fieldslogin, errorslogin });
  }

  loginSubmit(e) {
    e.preventDefault();
    if (this.handleValidationLogin()) {
      let data = {
        email: this.state.fieldslogin.username,
      };
      this.props.dispatch(userActions.forgotPassword(data, this.props));
    }
  }


  handleClick=(e)=>{
    if (this.handleValidationLogin()) {
      let data = {
        email: this.state.fieldslogin.username,
      };
      this.props.dispatch(userActions.forgotPassword(data, this.props));
    }
  }

  OtpVerify(e) {
    e.preventDefault();
    if (this.handleValidationLogin2()) {
      const { loggingIn, users, otpSent } = this.props;
      let { forgetPassword } = users ? users : {};

      let { data } = forgetPassword ? forgetPassword : {};
      let { isOTPSent, token } = data ? data : {};
      console.log(this.state.fieldslogin)
      let datas = {
        otp: this.state.fieldslogin.otp.trim(),
        newPassword: this.state.fieldslogin.newpassword.trim(),
        token: token
      };
      this.props.dispatch(userActions.forgotPasswordValidateOtp(datas, this.props));
    }
  }

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldslogin: {},
      errorslogin: {},
    });
    this.hideErrorMessage();
  };

  handleValidationLogin2 = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"]) {
      formIsValid = false;
      errorslogin["username"] = "Email cannot be blank.";
    }
    if (!fieldslogin["otp"]) {
      formIsValid = false;
      errorslogin["otp"] = "OTP Cannot Be Blank.";
    }
  
    if (!fieldslogin["newpassword"] || fieldslogin["newpassword"] === '') {
      formIsValid = false;
       errorslogin["newpassword"] = 'New password cannot be blank.';
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/.test(fieldslogin["newpassword"])
    ) {
      formIsValid = false;
       errorslogin["newpassword"] =
        'Password must be 8 to 15 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
    }



    //password

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };
  handleValidationLogin = () => {
    let fieldslogin = this.state.fieldslogin;
    let errorslogin = {};
    let formIsValid = true;

    //User Name
    if (!fieldslogin["username"]) {
      formIsValid = false;
      errorslogin["username"] = "Email Cannot Be Blank.";
    }
    //password

    this.setState({ errorslogin: errorslogin });
    return formIsValid;
  };

  handleVisible = () => {
    this.setState((prevState) => ({
      visible: !prevState.visible
    }));
  }

  render() {
    const { loggingIn, users, otpSent } = this.props;
    let { forgetPassword } = users ? users : {};

    let { data } = forgetPassword ? forgetPassword : {};
    let { isOTPSent } = data ? data : {};
    console.log(data);

    return (
      <div className=" bg-black   flex justify-center items-center ">
        <div className="flex bg-white justify-center  lg:w-[50vw] w-full  items-center h-screen">
          <div className="flex flex-col   md:px-3 px-6   md:border h-[90%]  rounded-lg  justify-center items-center  py-5">



            <div className="flex items-center justify-center text-3xl font-bold text-[#17273b] mb-2 ">Forgot Password</div>

            <div className="w-96 p-4">

              <div className=" w-full flex flex-col">
                <input
                  type="email"
                  name="username"
                  disabled={isOTPSent ? true : false}
                  id="username"
                  value={this.state.fieldslogin.username}
                  className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500"
                  placeholder="Enter Your Email"
                  onChange={this.inputChange}
                />
                {this.state.errorslogin &&
                  this.state.errorslogin["username"] ? (
                  <div className="text-red-500 text-left text-sm">
                    {this.state.errorslogin["username"]}
                  </div>
                ) : null}
              </div>


              {isOTPSent &&
                <div className=" w-full flex mt-5 flex-col">
                  <input
                    type="number"

                    name="otp"
                    id="otp"
                    value={this.state.fieldslogin.otp}
                    className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500"
                    placeholder="Enter OTP"
                    onChange={this.inputChange}
                  />
                  {this.state.errorslogin &&
                    this.state.errorslogin["otp"] ? (
                    <div className="text-red-500 text-left text-sm">
                      {this.state.errorslogin["otp"]}
                    </div>
                  ) : null}
                </div>}
              {isOTPSent &&
                <div className=" w-full relative flex mt-5 flex-col">
                  <input
                    type={this.state.visible ? "text" : "password"}

                    name="newpassword"
                    id="newpassword"
                    value={this.state.fieldslogin.newpassword}
                    className="w-full px-2 py-3 focus:outline-none text-lg border-b border-gray-400 placeholder:text-stone-500"
                    placeholder="New Password"
                    onChange={this.inputChange}
                  />
                  {this.state.visible ? <MdOutlineRemoveRedEye onClick={this.handleVisible} className=" absolute top-3 cursor-pointer right-0" /> : <FaRegEyeSlash onClick={this.handleVisible} className=" absolute top-3 cursor-pointer right-0" />}

                  {this.state.errorslogin &&
                    this.state.errorslogin["newpassword"] ? (
                    <div className="text-red-500 text-left text-sm">
                      {this.state.errorslogin["newpassword"]}
                    </div>
                  ) : null}

                  <div  className=" justify-center items-center flex mt-2">
                    <OtpTimer ButtonText="Resend Otp"  background={"#ffffff"}   buttonColor={"#F64411"} textColor={"#000000"}  seconds={30} minutes={0} resend={this.handleClick} />
                  </div>
                </div>}
              {isOTPSent ?
                <div className="w-full flex justify-center items-center">
                  <button type="button" onClick={this.OtpVerify}
                    className="flex justify-center items-center py-5 bg-[#F64411] w-full text-white font-semibold  text-lg  rounded-lg mt-5">
                    <span className="tracking-wider">Verify OTP</span>
                  </button>
                </div>
                :
                <div className="w-full flex justify-center items-center">
                  <button type="button" onClick={this.loginSubmit}
                    className="flex justify-center items-center py-5 bg-[#F64411] w-full text-white font-semibold  text-lg  rounded-lg mt-5">
                    <span className="tracking-wider">Submit</span>
                  </button>
                </div>}


              <div className=" my-2   text-stone-600 text-base  flex justify-center items-center ">
                <div className=" ">Back to <Link to={'/login'} className=" text-[#17273b] cursor-pointer text-lg p-1">Login !</Link></div>
              </div>

            </div>



          </div>
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn, user, otpSent } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    otpSent,
    user,
    users,
  };
}
export default connect(mapStateToProps)(ForgotPassword);
