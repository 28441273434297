import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoCaretBackCircleSharp } from "react-icons/io5";
const ChangePassword = ({ dispatch, props }) => {
  const [fieldslogin, setFieldsLogin] = useState({});

  const [errorslogin, setErrorsLogin] = useState({});


  const inputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFieldsLogin((prevState) => ({ ...prevState, [name]: value }));
    setErrorsLogin((prevState) => ({ ...prevState, [name]: '' }));
  };

  const history = useHistory();

  const changePassword = () => {

    if (handleValidationLogin()) {
      const data = {
        oldPassword: fieldslogin.oldPassword,
        newPassword: fieldslogin.password,
        confirmPassword: fieldslogin.confirmPassword,
      };
      dispatch(userActions.changePassword(data, history));
    }
  };

  const handleValidationLogin = () => {
    let errorslogin = {};
    let formIsValid = true;
    if (!fieldslogin.oldPassword || fieldslogin.oldPassword === '') {
      formIsValid = false;
      errorslogin.oldPassword = 'Old Password Cannot Be Blank.';
    }
    
    // Check New Password
    if (!fieldslogin.password || fieldslogin.password === '') {
      formIsValid = false;
      errorslogin.password = 'New Password Cannot Be Blank.';
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/.test(fieldslogin.password)
    ) {
      formIsValid = false;
      errorslogin.password =
        'Password must be 8 to 15 characters and contain at least one uppercase letter, one lowercase letter, one number, and one special character.';
    }
    
    // Check Confirm Password
    if (!fieldslogin.confirmPassword || fieldslogin.confirmPassword === '') {
      formIsValid = false;
      errorslogin.confirmPassword = 'Confirm Password Cannot Be Blank.';
    } else if (fieldslogin.password !== fieldslogin.confirmPassword) {
      formIsValid = false;
      errorslogin.confirmPassword = 'Passwords and Confirm Password do not match.';
    }
    
    // Clear Confirm Password error if everything else is valid
    if (formIsValid) {
      errorslogin.confirmPassword = '';
    }

    setErrorsLogin(errorslogin);
    return formIsValid;
  };

  // console.log('usersusersusersusersusers', users);

  return (
    <>
      <div className='bg-[#EEEEEE] w-full min-h-screen'>
        <div className='flex space-x-1 p-1'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='w-full'>
            <IoCaretBackCircleSharp  onClick={()=>   history.push('/')} className=' text-3xl text-[#17273b] cursor-pointer m-1' />
            <div className='flex  bg-[#FFFFFF]  border-black rounded-t-xl p-2' >
              <h2 className='text-sm font-medium text-[#213042]'>Change Password </h2>
            </div>
            <div className='w-full p-4 pt-8 rounded-[4px]'>
              <div className=' flex gap-4  flex-col'>

<div>

                <div className="form-group">
                  <label className="text-[#363063]  text-sm" for="oldpassword">Old Password *</label>
                  <input id="oldpassword"
                    className='w-full border border-gray-300 p-2.5 focus:outline-none text-xs md:text-sm font-medium text-gray-400 rounded'
                    type="password"
                    name="oldPassword"
                    value={fieldslogin.oldPassword}
                    onChange={inputChange}
                  />
                </div>
                {errorslogin && errorslogin["oldPassword"] ?
                  <div className="flex justify-center">
                    <div className="text-red-700 w-full ">
                      {errorslogin["oldPassword"]}
                    </div>
                  </div>
                  : null}
     </div>

<div>

                <div className="form-group">
                  <label className="text-[#363063]  text-sm" for="newPassword">New Password *</label>
                  <input className='w-full border border-gray-300 p-2.5 focus:outline-none md:text-sm text-xs font-medium text-gray-400 rounded'
                    type="password"
                    name="password"
                    id="password"
                    placeholder='New Password'
                    value={fieldslogin.password}
                    onChange={inputChange}
                  />
                </div>
                {errorslogin && errorslogin["password"] ?
                  <div className="flex justify-center">
                    <div className="text-red-700 w-full ">
                      {errorslogin["password"]}
                    </div>
                  </div>
                  : null}
</div>
                  <div>

                <div className="form-group ">
                  <label className="text-[#363063]  text-sm" for="confirmpassword">Confirm New Password *</label>
                  <input className='w-full border border-gray-300 p-2.5 focus:outline-none text-xs md:text-sm font-medium text-gray-400 rounded'
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    value={fieldslogin.confirmPassword}
                    onChange={inputChange}
                  />
                </div>
                {errorslogin && errorslogin["confirmPassword"] ?
                  <div className="flex justify-center">
                    <div className="text-red-700 w-full ">
                      {errorslogin["confirmPassword"]}
                    </div>
                  </div>
                  : null}
</div>
              </div>
              <div className="form-group space-x-2 py-3">
                <button onClick={changePassword} type="button" className="bg-[#17273b] text-white text-sm font-medium px-4 py-2 rounded tracking-widest">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(ChangePassword));