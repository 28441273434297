import React, { useState, useEffect } from 'react';

const PlayerCount = ({ appSettingData }) => {
  const [playerCount, setPlayerCount] = useState(0);

  // useEffect(() => {
  //   if (appSettingData && appSettingData.length > 0) {
  //     // Step 1: Get the initial count from appSettingData
  //     const initialCount = parseInt(
  //       appSettingData.filter((e) => e.key === "currentPlayerPocket10")[0]?.value
  //     );

  //     // Step 2: Get current time and calculate the increment
  //     const now = new Date();
  //     const minutes = now.getMinutes();
  //     const seconds = now.getSeconds();
  //     const timeIncrement = minutes * 60 + seconds; // Using time to create a consistent increment

  //     // Step 3: Set the player count
  //     setPlayerCount(initialCount + timeIncrement);

  //     // Step 4: Increment the count periodically based on time

  //   }
  // }, [appSettingData]);










  useEffect(() => {
    if (appSettingData && appSettingData.length > 0) {
      // Step 1: Get the initial count from appSettingData
      const initialCount = parseInt(
        appSettingData.find((e) => e.key === "currentPlayerPocket10")?.value
      );

      // Step 2: Function to calculate the current count based on the time
      const calculatePlayerCount = () => {
        const now = new Date();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const timeIncrement = minutes * 60 + seconds; // Increment based on time

        // setPlayerCount(initialCount + timeIncrement);
      };
      setPlayerCount(initialCount );

      // Step 4: Calculate the count initially
      calculatePlayerCount();

      // Step 5: Update the count every second
      const interval = setInterval(calculatePlayerCount, 6000);

      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }
  }, [appSettingData]);





  return (
    <div>
      Join the excitement! <span id="playerCount" className='blinking text-green-300'> 🏹 {playerCount} 🏹 </span> players are currently betting on this.
    </div>
  );
};

export default PlayerCount;
