import React from "react";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
// import moment from "moment";
import Sidebar from "../../components/Sidebar/Sidebar";
import { BsGraphUp, BsArrowDownShort, BsInfoCircleFill } from 'react-icons/bs';
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { IoIosArrowDown } from 'react-icons/io';
import Loader from "../../components/Loader/Loader";
import SuspendDesktop from '../../components/SuspendDesktop/suspended';
import BetPlaceDesktop from '../../components/BetPlaceDesktop/BetPlaceDesktop';
import EditStakeDesktop from '../../components/EditStakeDesktop/EditStakeDesktop';
// import BetPlaceMobile from '../../components/BetPlaceMobile/BetPlaceMobile';
// import EditStakeMobile from '../../components/EditStakeMobile/EditStakeMobile';
import { BiMessageRoundedCheck } from 'react-icons/bi';
import BackDesktop from '../../components/BackDesktop/backdesktop';
import BackLayDesktop from '../../components/BackLayDesktopDesign/backlaydesktop';
// import BackLayMobile from '../../components/BackLayMobileDesign/backlaymobile';
import LayDesktop from '../../components/LayDesktop/laydesktop';
import BackLayMobile from '../../components/BackLayMobileDesign/backlaymobile';
import SidebarRight from "../../components/SidebarRight/SidebarRight";
import EditformModal from "../../casino_components/EditformModal/EditformModal";

class Cricketdetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      backBetModal: false,
      layBetModal: false,
      betBackData: {},
      betLayData: {},
      count: 0,
      otherModal: false,
      stakeopen: false,
      fancystakeopen: false,
      finalMatchStack: [],
      BookModal: false,
      Bookstake: false,
      Bookstake2: false,
      fancyBookModalMob: false,
      fancyBookstakeMob: false,
      BookBetModal: false,
      fancyBetModal: false,
      fancystake: false,
      fancyrunopen: false,
      editStake: false,
      tv: false,
      tv2: false,
      currentIndex: 0,
      otherstake: false,
      showBetSlip: false,
      betSlipData: {},
      rundata: [],
      fieldsbet: {},
      errorsbet: {},
    };
  }
  inputChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    console.log("name, value  ", name, value);

    let { betSlipData } = this.state;
    betSlipData.stake = value;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  resetForm = (e) => {
    e.preventDefault();
    this.setState({
      fieldsbet: {},
      errorsbet: {},
    });
    this.hideErrorMessage();
  };
  handleValidationbet = () => {
    console.log("hello validation");

    let fieldsbet = this.state.fieldsbet;
    let errorsbet = {};
    let formIsValid = true;

    //Stake Name
    if (!fieldsbet["stack"]) {
      formIsValid = false;
      errorsbet["stack"] = "Stack Cannot Be Blank.";
    }

    this.setState({ errorsbet: errorsbet });
    return formIsValid;
  };

  increaseCount = () => {
    this.setState((prevState) => ({
      count: prevState.count + 0.01,
    }));
  };

  decreaseCount = () => {
    this.setState((prevState) => ({
      count: prevState.count - 0.01,
    }));
  };

  componentDidMount() {

   let temp = {
    id : "1.215755399"
   }
   this.props.dispatch(userActions.getMarket(temp));

    let data = {
      sport_id: this.props.match.params.sportId,
      match_id: this.props.match.params.match_id,
    };
    let data2 = {
      fancy_id: "0 ",
      limit: 10,
      market_id: "0",
      match_id: this.props.match.params.match_id,
      pageno: 1,
    };
    this.props.dispatch(userActions.list_bt_ssn_mk(data2));
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { eventsessionItems } = users;

    let finalData = {
      match_id: this.props.match.params.match_id,
      requestData: {
        odds: { id: eventdetalsItems && eventdetalsItems.MatchDetails && eventdetalsItems.MatchDetails.market_id ? eventdetalsItems.MatchDetails.market_id : "0", type: "B" },
        book: {
          id: eventdetalsItems && eventdetalsItems.BookerMakerMarket && eventdetalsItems.BookerMakerMarket.market_id ? eventdetalsItems.BookerMakerMarket.market_id : "0",
          type: "M"
        },

        static: []
      },
    };

    if (eventdetalsItems && eventdetalsItems.OtherMarketList) {
      finalData.requestData.others = eventdetalsItems.OtherMarketList.map((item) => ({
        id: item.market_id ? item.market_id : "0",
        type: "B"
      }));
    }
    if (eventsessionItems) {
      finalData.requestData.session = eventsessionItems.map((item) => ({
        id: item.SelectionId ? item.SelectionId : "0",
        type: "B"
      }));
    }



    this.props.dispatch(userActions.get_match_score(finalData));

    let score = {
      matchId: this.props.match.params.match_id,
    };
    this.props.dispatch(userActions.getScore(score));

    let data3 = {
      match_id: this.props.match.params.match_id,
    };
    this.props.dispatch(userActions.event_session(data3));


    this.props.dispatch(userActions.event_detals(data));

    // this.interval = setInterval(() => this.loadData(), 15000);
    // this.interval1 = setInterval(() => this.loadData1(), 1000);
  }
  loadData = () => {
    let data = {
      sport_id: this.props.match.params.sportId,
      match_id: this.props.match.params.match_id,
    };
    let data2 = {
      fancy_id: "0 ",
      limit: 10,
      market_id: "0",
      match_id: this.props.match.params.match_id,
      pageno: 1,
    };
    let data3 = {
      match_id: this.props.match.params.match_id,
    };
    this.props.dispatch(userActions.list_bt_ssn_mk(data2));
    this.props.dispatch(userActions.event_detals(data));
    this.props.dispatch(userActions.event_session(data3));
  };

  loadData1 = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { eventsessionItems } = users;
    let finalData = {
      match_id: this.props.match.params.match_id,
      requestData: {
        odds: { id: eventdetalsItems && eventdetalsItems.MatchDetails && eventdetalsItems.MatchDetails.market_id ? eventdetalsItems.MatchDetails.market_id : "0", type: "B" },
        book: {
          id: eventdetalsItems && eventdetalsItems.BookerMakerMarket && eventdetalsItems.BookerMakerMarket.market_id ? eventdetalsItems.BookerMakerMarket.market_id : "0",
          type: "M"
        },
        session: eventsessionItems.map(item => item.SelectionId),
        static: []
      },
    };

    if (eventdetalsItems && eventdetalsItems.OtherMarketList) {
      finalData.requestData.others = eventdetalsItems.OtherMarketList.map((item) => ({
        id: item.market_id ? item.market_id : "0",
        type: "B"
      }));
    }
    this.props.dispatch(userActions.get_match_score(finalData));
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval1);
  }

  handleOtherBet = (data) => {

    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(',') : []

    this.setState({ stakeopen: true, finalMatchStack: finalMatchStack });
    console.log('datadatadatadatadatadatadatadatavvvvvvvvvvvvvvvvvvvvvvvvv', data);

    this.setState({
      otherModal: true,
      otherstake: false,
      betSlipData: { ...data, stake: '0' }, count: data.data.price
      ,
    });
  }

  placeBet1 = () => {

    let data2 = {
      "fancy_id": "0 ",
      "limit": 10,
      "market_id": "0",
      "match_id": this.props.match.params.match_id,
      "pageno": 1
    }
    let data = {
      market_id: this.state.betSlipData.elementtemp,
      match_id: this.props.match.params.match_id,
      selection_id: this.state.betSlipData.selectionId,
      stack: this.state.betSlipData.stake + "",
      odds: this.state.betSlipData.data.price + "",
      is_back: this.state.betSlipData && this.state.betSlipData.type && this.state.betSlipData.type === "BACK" ? "1" : "0",
    }
    this.props.dispatch(userActions.save_bet(data, data2))
    console.log('this.state.betSlipDatathis.state.betSlipData', this.state.betSlipData);
  }

  handleOtherStake = () => {
    this.setState({ otherstake: true });
  }

  handleOtherClose = () => {
    this.setState({ otherModal: false, otherstake: false, backBetModal2: false, stakeopen2: false });
  }
  handlestakeClose = () => {
    let { finalMatchStack } = this.state;

    let finalString = finalMatchStack.join(',');
    console.log("finalString  ", finalString);

    let data = {
      match_stack: finalString,
      one_click_stack: "0",
      sport_id: this.props.match.params.sportId,
    }

    this.props.dispatch(userActions.single_click_update_amount(data))

    this.setState({ stakeopen2: false, Bookstake: false, stakeopen: false, otherstake: false });
  }
  handlestakeClosefancy = () => {
    let { finalMatchStack } = this.state;

    let finalString = finalMatchStack.join(',');
    console.log("finalString  ", finalString);

    let data = {
      match_stack: finalString,
      one_click_stack: "0",
      sport_id: this.props.match.params.sportId,
    }

    this.props.dispatch(userActions.single_click_update_amount(data))

    this.setState({ fancystake: false, fancystakeopen: false });
  }
  handlestakeClosefancyMob = () => {
    let { finalMatchStack } = this.state;

    let finalString = finalMatchStack.join(',');
    console.log("finalString  ", finalString);

    let data = {
      match_stack: finalString,
      one_click_stack: "0",
      sport_id: this.props.match.params.sportId,
    }

    this.props.dispatch(userActions.single_click_update_amount(data))

    this.setState({ fancyBookstakeMob: false, fancyBookModalMob: false });
  }
  handleotherOpen2 = (data) => {

    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(',') : []

    this.setState({ stakeopen: true, finalMatchStack: finalMatchStack });
    console.log('datadatadatadatadatadatadatadatavvvvvvvvvvvvvvvvvvvvvvvvv', data);

    this.setState({
      backBetModal2: true,
      stakeopen2: false,
      betSlipData: { ...data, stake: '0' }, count: data.data.price
      ,
    });
  }

  handleBookBet = (data) => {

    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(',') : []

    this.setState({ stakeopen: true, finalMatchStack: finalMatchStack });
    console.log('datadatadatadatadatadatadatadatavvvvvvvvvvvvvvvvvvvvvvvvv', data);

    this.setState({
      BookModal: true,
      Bookstake: false,
      betSlipData: { ...data, stake: '0' }, count: data.data.price
      ,
    });
  }

  handleFancyOpen = (data) => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;
    let finalMatchStack =
      UserSportSettings &&
        UserSportSettings.length > 0 &&
        UserSportSettings[0].match_stack
        ? UserSportSettings[0].match_stack.split(",")
        : [];
    this.setState({ fancystakeopen: true, finalMatchStack: finalMatchStack });
    this.setState({
      fancyBookModal: true,
      fancyBookstake: false,
      betSlipData: { ...data, stake: '0' },
      count: data.run
    });

    console.log('fancydatafancydatafancydatafancydatafancydatafancydata', data);
    console.log('this.state.countthis.state.countthis.state.count', this.state.count);


  };

  handleFancyRun = (data) => {
    this.setState({ fancyrunopen: true, rundata: data });
    console.log('rundatarundatarundatarundatarundata', this.state.rundata);
  };

  handleFancyRunClose = () => {
    this.setState({ fancyrunopen: false });
  }

  handleFancyOpenMob = (data) => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(",") : [];
    this.setState({ fancystakeopenMob: true, finalMatchStack: finalMatchStack });
    this.setState({
      fancyBookModalMob: true,
      fancyBookstakeMob: false,
      betSlipData: { ...data, stake: '0' },
      count: data.run
    });
  };

  placeBet3 = () => {
    let data2 = {
      "fancy_id": "0 ",
      "limit": 10,
      "market_id": "0",
      "match_id": this.props.match.params.match_id,
      "pageno": 1
    }
    let data = {
      fancy_id: this.state.betSlipData.selectionId + "",
      is_back: this.state.betSlipData && this.state.betSlipData.type && this.state.betSlipData.type === "No" ? "1" : "0",
      match_id: this.props.match.params.match_id + "",
      run: this.state.betSlipData.run + "",
      size: this.state.betSlipData.size,
      sport_id: "4",
      stack: this.state.betSlipData.stake,
    }
    this.props.dispatch(userActions.save_ssn_bet(data, data2))
    console.log('this.state.betSlipDatathis.state.betSlipData', this.state.betSlipData);
  }
  placeBet2 = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let data2 = {
      "fancy_id": "0 ",
      "limit": 10,
      "market_id": "0",
      "match_id": this.props.match.params.match_id,
      "pageno": 1
    }
    let data = {
      market_id: eventdetalsItems.BookerMakerMarket.market_id,
      match_id: this.props.match.params.match_id,
      selection_id: this.state.betSlipData.selectionId,
      stack: this.state.betSlipData.stake + "",
      odds: this.state.betSlipData.data.price + "",
      is_back: this.state.betSlipData && this.state.betSlipData.type && this.state.betSlipData.type === "BACK" ? "1" : "0",
    }
    this.props.dispatch(userActions.save_bet(data, data2))
    console.log('this.state.betSlipDatathis.state.betSlipData', this.state.betSlipData);
  }

  handleBookStake = () => {
    this.setState({ Bookstake: true, Bookstake2: true, });
  }

  handleStackClose = () => {
    let { finalMatchStack } = this.state;

    let finalString = finalMatchStack.join(',');
    console.log("finalString  ", finalString);

    let data = {
      match_stack: finalString,
      one_click_stack: "0",
      sport_id: this.props.match.params.sportId,
    }

    this.props.dispatch(userActions.single_click_update_amount(data))

    this.setState({ stakeopen: false });
  }


  handleBookStakeOpenWebfancy = () => {
    let { users } = this.props;
    let { eventsessionItems } = users;
    let { UserSportSettings } = eventsessionItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack =
      UserSportSettings &&
        UserSportSettings.length > 0 &&
        UserSportSettings[0].match_stack
        ? UserSportSettings[0].match_stack.split(",")
        : [];

    this.setState({ fancyBookstakeMob: true, finalMatchStack: finalMatchStack });
  };
  handleClosefancy = () => {
    this.setState({ fancyBetModal: false, fancystakeopen: false });
  };
  handleClosefancyMob = () => {
    this.setState({ fancyBookModalMob: false, fancyBookstakeMob: false });
  };


  handleBackOpen = (data) => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(",") : [];

    this.setState({ stakeopen: true, finalMatchStack: finalMatchStack });

    this.setState({ backBetModal: true, stakeopen: false, betSlipData: { ...data, stake: "0" }, count: data.data.price });
  };

  handleBackOpen2 = (data) => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(",") : [];

    this.setState({ stakeopen: true, finalMatchStack: finalMatchStack });
    console.log("datadatadatadatadatadatadatadatavvvvvvvvvvvvvvvvvvvvvvvvv", data,);

    this.setState({ backBetModal2: true, stakeopen2: false, betSlipData: { ...data, stake: "0" }, count: data.data.price, });
  };

  updateStackOnclic = (num) => {
    let { betSlipData } = this.state;
    betSlipData.stake = num;
    this.setState({
      betSlipData: betSlipData,
    });
  };

  placeBet = (num) => {
    let { users } = this.props;
    let { eventdetalsItems, } = users;
    let data2 = {
      fancy_id: "0 ",
      limit: 10,
      market_id: "0",
      match_id: this.props.match.params.match_id,
      pageno: 1,
    };
    let data = {
      market_id: eventdetalsItems.MatchDetails.market_id,
      match_id: this.props.match.params.match_id,
      selection_id: this.state.betSlipData.selectionId,
      stack: this.state.betSlipData.stake + "",
      odds: this.state.betSlipData.data.price + "",
      is_back:
        this.state.betSlipData &&
          this.state.betSlipData.type &&
          this.state.betSlipData.type === "BACK"
          ? "1"
          : "0",
    };
    this.props.dispatch(userActions.save_bet(data, data2));
    console.log(
      "this.propsthis.propsthis.propseventdetalsItems",
      eventdetalsItems.MatchDetails.market_id,
    );
  };

  handlestakeOpen = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);

    this.setState({ stakeopen: true, backBetModal: false });
  };

  handleStakeInput = (e) => {
    e.preventDefault();
    let { value } = e.target;
    console.log("value  ", value);

    let { finalMatchStack } = this.state;
    finalMatchStack[this.state.currentIndex] = value;
    this.setState({ finalMatchStack });
  };

  handleCurrentIndex = (index) => {
    this.setState({ currentIndex: index });
  };

  handlestakeOpenWeb = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack = UserSportSettings && UserSportSettings.length > 0 && UserSportSettings[0].match_stack ? UserSportSettings[0].match_stack.split(",") : [];

    this.setState({ stakeopen: true, finalMatchStack: finalMatchStack });
  };

  handleBookStakeOpenWeb = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack =
      UserSportSettings &&
        UserSportSettings.length > 0 &&
        UserSportSettings[0].match_stack
        ? UserSportSettings[0].match_stack.split(",")
        : [];

    this.setState({ Bookstake: true, finalMatchStack: finalMatchStack });
  };

  handleFancyStakeOpen = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack =
      UserSportSettings &&
        UserSportSettings.length > 0 &&
        UserSportSettings[0].match_stack
        ? UserSportSettings[0].match_stack.split(",")
        : [];

    this.setState({ fancystake: true, finalMatchStack: finalMatchStack });
  };
  handleFancyStakeOpenMob = () => {
    let { users } = this.props;
    let { eventdetalsItems } = users;
    let { UserSportSettings } = eventdetalsItems;

    console.log("UserSportSettings  ", UserSportSettings);
    let finalMatchStack =
      UserSportSettings &&
        UserSportSettings.length > 0 &&
        UserSportSettings[0].match_stack
        ? UserSportSettings[0].match_stack.split(",")
        : [];

    this.setState({ fancyBookstakeMob: true, finalMatchStack: finalMatchStack });
  };

  handlestakeOpen2 = () => {
    this.setState({ stakeopen2: true, backBetModal2: false });
  };
  handlestakeOpenWeb2 = () => {
    this.setState({ ookstakeopen2: true });
  };
  handleClose = () => {
    this.setState({ backBetModal: false, stakeopen: false, backBetModal2: false });
  };
  handleBookClose = () => {
    this.setState({
      BookModal: false,
      Bookstake: false
    });
  };

  handleFancyClose = () => {
    this.setState({
      fancystakeopen: false,
      fancystake: false
    });
  };

  secToTime = (startDate, endDate) => {
    let ms = endDate * 1000 - startDate * 1000;
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / 1000 / 3600) % 24);
    return [hours, minutes, seconds]
      .map((v) => String(v).padStart(2, 0))
      .join(":");

    // { hours, minutes, seconds };
  };

  handleTv = () => {
    this.setState({ tv: !this.state.tv })
  }
  handleTv2 = () => {
    this.setState({ tv2: !this.state.tv2 })
  }

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake })
  }

  render() {
    let { users } = this.props;
    let { listbtssnmkItems, getmatchscoreItems, eventdetalsItems, betLoading, FcurrentTime, getScoreItems, getMarket } = users;
    let { MatchDetails } = eventdetalsItems ? eventdetalsItems : {};
    let { runner_json } = MatchDetails ? MatchDetails : {};
     console.log("getMarketgetMarketgetMarket", getMarket);

    let resData = eventdetalsItems && eventdetalsItems.MatchDetails && eventdetalsItems.MatchDetails.BetAllowTimeBefore ? eventdetalsItems.MatchDetails.BetAllowTimeBefore : "0";

    let Fmatchtime = eventdetalsItems && eventdetalsItems.MatchDetails && eventdetalsItems.MatchDetails.start_date ? eventdetalsItems.MatchDetails.start_date : "0";

    const matchedBets = { MatchAndBetfair: listbtssnmkItems && listbtssnmkItems.MatchAndBetfair && listbtssnmkItems.MatchAndBetfair.length ? listbtssnmkItems.MatchAndBetfair.length : 0, MatchFancy: listbtssnmkItems && listbtssnmkItems.MatchFancy && listbtssnmkItems.MatchFancy.length ? listbtssnmkItems.MatchFancy.length : 0 };

    const total = matchedBets.MatchAndBetfair + matchedBets.MatchFancy;


    return (
      <>
        <div>
          <Loader active={betLoading} />
        </div>
        <div className="relative w-full h-full page_bg overflow-y-auto flex">
          <Sidebar
            open={true}
            sportId={this.props.match.params.sportId}
            seriesId={this.props.match.params.seriesId}
            match_id={this.props.match.params.match_id}
            props={this.props}
          />
          <div className="w-full">
            <div className="lg:flex w-full space-x-2">
              <div className="lg:w-[75%] w-full  md:pb-20">
                <div className='bg-black mt-0  border-t border-green-400 rounded'>
                  <div onClick={() => this.handleTv()} className='px-2 cursor-pointer flex justify-between items-center '>
                    <div className='flex space-x-2 px-2 pt-3 pb-2'>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                      <p className='text-white text-sm'> Live Tv 1</p>
                    </div>
                    <div><IoIosArrowDown size={20} className={`text-white ${this.state.tv === true ? "rotate-180" : "rotate-0"}`} /></div>
                  </div>
                  {this.state.tv === true ?
                    <div className='flex justify-center items-center'>
                      <img className='w-[300px] ' src="/images/tv.png" alt="" />
                    </div>
                    : null}

                </div>
                <div className='bg-black mt-0  border-t border-white rounded lg:hidden block'>
                  <div onClick={() => this.handleTv2()} className='px-2 cursor-pointer flex justify-between items-center '>
                    <div className='flex space-x-2 px-2 pt-3 pb-2'>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                      <p className='text-white text-sm'> Live Tv 2</p>
                    </div>
                    <div><IoIosArrowDown size={20} className={`text-white ${this.state.tv2 === true ? "rotate-180" : "rotate-0"}`} /></div>
                  </div>
                  {this.state.tv2 === true ?
                    <div className='flex justify-center items-center'>
                      <img className='w-[300px] ' src="/images/tv.png" alt="" />
                    </div>
                    : null}

                </div>
                {/* get-match socre */}
                {getScoreItems && getScoreItems !== null ? (
                  <>
                    <div class="w-full font-semibold text-[13px] text-black bg-[black] rounded-t-3xl py-[3px] border border-white mr-[2px]"></div>
                    <div className="flex  justify-between px-2 items-center  border-b-2  border-white py-5 bg-[#252328] text-white h-[128px]">
                      <div className="text-left text-[14px] w-full">
                        <p className="font-semibold text-[#f98f19]">
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].team1 ? getScoreItems[2].team1 : "0"}
                        </p>

                        <div className="font-semibold text-[#E9D125]">
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].score ? getScoreItems[2].score : "0"}-{" "}
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].wicket ? getScoreItems[2].wicket : "0"}{" "}
                        </div>
                        <div>
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].ballsdone / 6 ? (getScoreItems[2].ballsdone / 6).toFixed(1) : "0"}
                        </div>
                      </div>
                      <div className="w-full">
                        <div className="px-2 text-sm">
                          {getScoreItems && getScoreItems[0] && getScoreItems[0].cb && getScoreItems[0].cb !== null ?
                            <p className="text-[14px] text-center">
                              {getScoreItems && getScoreItems[0] && getScoreItems && getScoreItems[0].cb ? getScoreItems[0].cb : "0"}</p> : <>
                            </>}
                        </div>
                        <div className="bg-black rounded-lg py-4">
                          <h2 className="text-[#E8FE26] text-lg font-bold text-center">Ball</h2>
                        </div>
                        <div className="flex justify-center items-center text-[14px] space-x-2">
                          This over: {" "}
                          <span className="flex justify-start items-center space-x-2 px-1 py-1">
                            {getScoreItems && getScoreItems[0] && getScoreItems[0].recentBalls && getScoreItems[0].recentBalls[0] && getScoreItems[0].recentBalls[0].length > 0 ? getScoreItems[0].recentBalls[0].map((elementtemp, index) => (<>
                              {elementtemp < 4 ?
                                <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div>
                                : <>
                                  {elementtemp === 4 ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === 6 ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === 6 ?
                                    <div className=" bg-[#131E29] w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                  {elementtemp === "W" ?
                                    <div className=" bg-red-500 w-6 h-6 flex justify-center items-center rounded-full text-white font-medium text-[13px]">{elementtemp}</div> : null
                                  }
                                </>}
                            </>
                            )) : "NA"}
                          </span>
                        </div>
                      </div>
                      <div className="text-right text-[14px] w-full">
                        <p className="font-semibold text-[#f98f19]">
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].team2 ? getScoreItems[2].team2 : "0"}
                        </p>

                        <div className="font-semibold text-[#E9D125]">
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].score2 ? getScoreItems[2].score2 : "0"}-{" "}
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].wicket2 ? getScoreItems[2].wicket2 : "0"}{" "}

                        </div>
                        <div>
                          {getScoreItems && getScoreItems[2] && getScoreItems && getScoreItems[2].ballsdone2 / 6 ? (getScoreItems[2].ballsdone2 / 6).toFixed(1) : "0"}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {/* team Desktop data */}
                <div className="lg:block hidden ">
                  <div className="bg-black flex justify-between items-center py-1.5 px-1 text-white text-[12px] font-medium">
                    <p>
                      West Indies Women v Ireland Women / <span className=''>Match Odds</span>  <span>Min :250  Max :300000</span>
                    </p>

                    <div className='justify-center flex items-center space-x-2'>
                      <p className='text-white w-5 h-5 rounded-full  justify-center flex items-center bg-[#E2C606]' >FS</p>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                    </div>
                  </div>
                  {/* <BackLayDesktop /> */}

                  <table className="w-full bg-white ">
                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                        </td>
                        <td>
                          <div className="flex justify-end items-center ">

                            <div class="text-center flex justify-end items-center pt-2">
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                              <div class="w-24 text-center py-0.5 cursor-pointer">
                                <span className="text-[16px] ">
                                  LAGAI
                                </span>
                                <br />
                                <span className="flex justify-center items-center">
                                  <BsArrowDownShort className='bg-[#4fa0e7] text-white h-4 w-4 rounded-full' size={16} />
                                </span>
                              </div>
                              <div class="w-24 lg:pb-3 flex space-x-2 py-0.5 cursor-pointer">
                                <span className="text-[16px]">
                                  KHAI
                                </span>
                                <span><BsArrowDownShort className='bg-[#FAA9BA] text-white h-4 w-4 rounded-full' size={16} /></span>
                              </div>
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                              <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                          <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span> West Indies Women</span>
                          <p className='text-sky-500'>0.00</p>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center '
                              //  style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-black text-sm font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div>

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                          <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span> Ireland Women</span>
                          <p className='text-sky-500'>0.00</p>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
      <h2 className='text-white text-xs font-bold uppercase'><span>
        Suspended
      </span></h2>
    </div> */}

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>


                    </tbody>

                  </table>


                </div>
                {/* team mobile data */}
                <div className="lg:hidden block  bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 px-1 text-white text-[12px] font-medium">
                    <p>
                      West Indies Women v Ireland Women / <span className=''>Match Odds</span>  <span>Min :250  Max :300000</span>
                    </p>

                    <div className='justify-center flex items-center space-x-2'>
                      <p className='text-white w-5 h-5 rounded-full  justify-center flex items-center bg-[#E2C606]' >FS</p>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                    </div>
                  </div>
                  {/* <BackLayDesktop /> */}

                  <div className="w-full bg-white ">
                    <div>
                      <div className="border-b border-gray-200 w-full">
                        <div className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                        </div>
                        <div className="w-full">
                          <div className="flex justify-end items-center ">

                            <div class="text-center flex justify-end items-center pt-2">
                              <div class="w-24 text-center py-0.5 cursor-pointer">
                                <span className="text-[16px] ">
                                  LAGAI
                                </span>
                                <br />
                                <span className="flex justify-center items-center">
                                  <BsArrowDownShort className='bg-[#4fa0e7] text-white h-4 w-4 rounded-full' size={16} />
                                </span>
                              </div>
                              <div class="w-24 lg:pb-3 flex space-x-2 py-0.5 cursor-pointer">
                                <span className="text-[16px]">
                                  KHAI
                                </span>
                                <span><BsArrowDownShort className='bg-[#FAA9BA] text-white h-4 w-4 rounded-full' size={16} /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="border-b border-gray-200 flex justify-between items-center">
                        <div className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                          <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span> West Indies Women</span>
                          <p className='text-sky-500'>0.00</p>
                        </div>
                        <div>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center '
                              //  style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-black text-sm font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="border-b border-gray-200 flex justify-between w-ful">
                        <div className="flex flex-col h-full text-[13px] uppercase  text-gray-800 font-medium px-2 w-full">
                          <span className='inline-flex items-center font-semibold'><span className='px-1'><BsGraphUp /></span> Ireland Women</span>
                          <p className='text-sky-500'>0.00</p>
                        </div>
                        <div>
                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center'
                              // style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-black text-sm font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>


                    </div>

                  </div>


                </div>
                {/* toss Desktop data */}
                <div className="lg:block hidden bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>
                      Toss Book
                    </p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">West</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Ireland</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                    </div>
                  </div>

                  <table className="w-full ">

                    <tbody>
                      <tr className="border-b border-black">
                        <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>WHO WILL WIN THE TOSS </span>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>

                    </tbody>

                  </table>

                </div>
                {/*toss mobile */}
                <div className="lg:hidden block  bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>
                      Toss Book
                    </p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">West</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Ireland</div>
                    </div>
                  </div>

                  <table className="w-full ">

                    <tbody>
                      <tr className="border-b border-black">
                        <td className="flex justify-between items-center h-full text-[13px] uppercase  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>WHO WILL WIN THE TOSS </span>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                          </div>

                        </td>
                      </tr>

                    </tbody>

                  </table>

                </div>

                {/* Fancy Desktop data */}
                <div className="lg:block hidden bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>
                      Fancy  <span className='text-xs'>Min :250  Max :300000</span>
                    </p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                    </div>
                  </div>

                  <table className="w-full ">

                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>5 over runs AUS(ENG vs AUS)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Only 5 over run AUS </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center'
                              //  style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-black text-sm font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div>

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Fall of 1st wkt AUS(ENG vs AUS)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>U Khawaja Boundaries(ENG vs AUS)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>

                    </tbody>

                  </table>

                </div>

                {/*Fancy mobile */}
                <div className="lg:hidden block  bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>
                      Fancy  <span className='text-[10px]'>Min :250  Max :300000</span>
                    </p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                    </div>
                  </div>

                  <div className="w-full ">

                    <div>
                      <div className="border-b border-gray-200 flex justify-between items-center">
                        <div className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>5 over runs AUS(ENG vs AUS)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className='absolute top-0 bg-white/70 w-full h-full flex justify-center items-center'
                              // style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-black text-sm font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="border-b border-gray-200 flex justify-between items-center">
                        <div className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Only 5 over run AUS </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center'
                              style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-white text-xs font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div> */}

                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="border-b border-gray-200 flex justify-between items-center">
                        <div className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Fall of 1st wkt AUS(ENG vs AUS)adv</span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center'
                              style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-white text-xs font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div> */}

                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="border-b border-gray-200 flex justify-between items-center">
                        <div className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>U Khawaja Boundaries(ENG vs AUS)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </div>
                        <div>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center'
                              style={{ backgroundImage: `url("/images/supend_bg.png")` }}
                              >
                                <h2 className='text-white text-xs font-bold uppercase'><span>
                                  Suspended
                                </span></h2>
                              </div> */}

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>

                  </div>

                </div>

                {/* Extra Fancy Desktop data */}

                <div className="lg:block hidden bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>
                      Extra  Fancy  <span className='text-xs'>Min :100   Max :50000</span>
                    </p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                      <div class="w-24 font-semibold text-[13px] text-transparent bg-transparent py-[3px]">Lay</div>
                    </div>
                  </div>

                  <table className="w-full ">

                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Top batsman runs in match(WI W vs IRE W)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer">
                            </div>
                            <div className="w-24 text-center py-0.5 cursor-pointer" >
                            </div>
                          </div>

                        </td>
                      </tr>



                    </tbody>

                  </table>

                </div>
                {/* Extra Fancy mobile data */}

                <div className="lg:hidden block  bg-white">
                  <div className="bg-black flex justify-between items-center py-1.5 border rounded-t-md px-1 text-white text-sm font-medium">
                    <p>
                      Extra  Fancy  <span className='text-xs'>Min :100   Max :50000</span>
                    </p>
                    <div class="text-center flex justify-end items-center pt-2">
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px] mr-[2px]">No</div>
                      <div class="w-24 font-semibold text-[13px] text-white py-[3px]">Yes</div>
                    </div>
                  </div>

                  <table className="w-full ">

                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="flex justify-between items-center h-full text-[13px]  text-gray-800 font-medium px-2 py-4 w-full">
                          <span>Top batsman runs in match(WI W vs IRE W)adv </span>
                          <div className="px-3"><BsInfoCircleFill className="text-[#208091] text-xl" /></div>
                        </td>
                        <td>

                          <div className="flex justify-end items-center divide-x-2 divide-gray-200">
                            <div className='flex justify-end items-center divide-x-2 divide-gray-200 relative'>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_pink_index-0">
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              <div className="w-24 text-center py-0.5 cursor-pointer match_bg_blue_index-0" >
                                <span className="text-[13px] font-semibold">0.92</span><br />
                                <span className="text-[10px]">0</span>
                              </div>
                              {/* <div className='absolute top-0 bg-black/50 w-full h-full flex justify-center items-center' style={{ backgroundImage: `url("/images/supend_bg.png")` }}>
                                    <h2 className='text-white text-xs font-bold uppercase'><span>
                                      Suspended
                                    </span></h2>
                                  </div> */}

                            </div>
                          </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="h-14">
                  </div>
                </div>
                <div className='w-full text-center text-blue-500 py-3  text-sm bg-[#FFFFFF] border-t-[6px] border-black rounded-t-xl mb-2' >
                  <p className='group-hover:text-black hover:text-black cursor-pointer'>See All Completed Bets</p>
                </div>
              </div>
              <div className="w-[25%] lg:block hidden bg-white h-screen">
                <div className='bg-black mt-0  border-t border-white rounded'>
                  <div onClick={() => this.handleTv2()} className='px-2 cursor-pointer flex justify-between items-center '>
                    <div className='flex space-x-2 px-2 pt-3 pb-2'>
                      <img className='w-[25px] h-5' src="/images/live-tv.png" alt="" />
                      <p className='text-white text-sm'> Live Tv 2</p>
                    </div>
                    <div><IoIosArrowDown size={20} className={`text-white ${this.state.tv2 === true ? "rotate-180" : "rotate-0"}`} /></div>
                  </div>
                  {this.state.tv2 === true ?
                    <div className='flex justify-center items-center'>
                      <img className='w-[300px] ' src="/images/tv.png" alt="" />
                    </div>
                    : null}

                </div>
                {this.state &&
                  this.state.backBetModal === true ? (
                  <BetPlaceDesktop
                    betSlipData={this.state.betSlipData}
                    finalMatchStack={this.state.finalMatchStack}
                    updateStackOnclic={this.updateStackOnclic}
                    inputChange={this.inputChange}
                    decreaseCount={this.decreaseCount}
                    increaseCount={this.increaseCount}
                    placeBet={this.placeBet}
                    handleClose={this.handleClose}
                    handlestakeOpenWeb={this.handlestakeOpenWeb}
                    count={this.state.count}
                  />
                ) : null}
                <div className="bg-[#2A2A2A] flex justify-between items-center rounded py-2 px-2 text-white text-sm font-medium">
                  <div>
                    BetSlip
                  </div>
                  <div>
                    <button onClick={() => this.setEditStakeOpen()} className='inline-flex items-center justify-center w-full px-2 py-0.5 text-sm  leading-none text-black bg-[#FFFAEC] border border-transparent rounded-full md:w-auto hover:bg-[#FFFAEC]  focus:ring-2 focus:ring-offset-2 focus:ring-[#FFFAEC]'>Edit Stake</button>
                  </div>
                </div>
                <SidebarRight />
              </div>
              {this.state.editStake === true ?
                <EditformModal setEditStakeOpen={this.setEditStakeOpen} />
                : null
              }
            </div>
          </div>
        </div >
      </>
    );
  }
}


function mapStateToProps(state) {
  const { users } = state;

  console.log("usersusersusersusers11111111", users);
  return {
    users,
  };
}

export default connect(mapStateToProps)(Cricketdetails);
