import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarRight from '../../components/SidebarRight/SidebarRight';
import EditformModal from "../../casino_components/EditformModal/EditformModal";
import BetSlipTab from "../../casino_components/BetSlipTab/BetSlipTab";

class SattaEvents extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
      gameTwo: '',
      gameThree: '',
      gameFour: '',
      gameItem: {},
      activeTab: 1,
      editStake: false,

      data: {
        "limit": 10,
        "pageno": 1
      }
    }
  }
  componentDidMount() {

    // let data = {
    //   "limit": 10,
    //   "pageno": 1
    // }
    // this.props.dispatch(userActions.game_profile(data));
    // this.props.dispatch(userActions.games_list(data));
    // this.interval = setInterval(() => this.loadData(), 15000);
  }

  loadData = () => {
    // let data = {
    //   "limit": 10,
    //   "pageno": 1
    // }
    // this.props.dispatch(userActions.game_profile(data));
    // this.props.dispatch(userActions.games_list(data));
  }

  handleResponseCasino = () => {
    this.props.history.push('/app/satta-events-id')
  }

  navigation = (data) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data === 3) {
      this.props.history.push('/app/livegames')
    } else if (data === 4) {
      this.props.history.push("/app/satta-events")
    }
  }

  handleResponseTannis = (data, status) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data && data.order_by === 1) {
      this.props.history.push('/app/cricket-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
    } else if (data && data.order_by === 2) {
      this.props.history.push('/app/tennis-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
    }
    else if (data && data.order_by === 3) {
      this.props.history.push('/app/soccer-details/' + data.sport_id + "/" + data.series_id + "/" + data.match_id + "/" + data.market_id)
    }
  }
  handleSidebar = (data) => {
    console.log('datagameItemgameItemgameItem', data);
    this.setState({ gameItem: data });

  }
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake })
  }

  render() {
    let { users } = this.props;
    let { eventcasinoItems } = users;
   
    return (
      <div className='relative w-full h-full overflow-y-auto flex space-x-1 p-1' style={{ backgroundImage: "url(/images/mw-ban.png)" }}>
        <div className="w-full">
          <div className="lg:flex w-full space-x-1">
            <div className="w-full space-y-1">
              <div className='h-screen overflow-y-auto w-[60%] mx-auto'>
                <div className='px-3 py-3 text-xl font-semibold text-center text-[#17273b] '>
                  ALL MATKA PANEL CHART RECORDS
                </div>
                <div className='space-y-2'>
                  <div onClick={() => this.handleResponseCasino()} className='bg-[#555555] lg:py-1 py-0.5 cursor-pointer rounded'>
                    <p className='text-white text-center text-[19px] lg:text-[22px] uppercase'>Faridabad-30-05-2023</p>
                    <p className='text-white text-center  text-[14px]'>2023-05-30 09:00:00</p>
                  </div>
                  <div onClick={() => this.handleResponseCasino()} className='bg-[#555555] lg:py-1 py-0.5 cursor-pointer rounded'>
                    <p className='text-white text-center text-[19px] lg:text-[22px] uppercase'>gali-27-06-2023</p>
                    <p className='text-white text-center  text-[14px]'>2023-06-27 09:00:00</p>
                  </div>
                  <div onClick={() => this.handleResponseCasino()} className='bg-[#555555] lg:py-1 py-0.5 cursor-pointer rounded'>
                    <p className='text-white text-center text-[19px] lg:text-[22px] uppercase'>disawar-27-06-2023</p>
                    <p className='text-white text-center  text-[14px]'>2023-06-27 09:00:00</p>
                  </div>
                  <div onClick={() => this.handleResponseCasino()} className='bg-[#555555] lg:py-1 py-0.5 cursor-pointer rounded'>
                    <p className='text-white text-center text-[19px] lg:text-[22px] uppercase'>Faridabad-27-06-2023</p>
                    <p className='text-white text-center  text-[14px]'>2023-06-27 09:00:00</p>
                  </div>
                  <div onClick={() => this.handleResponseCasino()} className='bg-[#555555] lg:py-1 py-0.5 cursor-pointer rounded'>
                    <p className='text-white text-center text-[19px] lg:text-[22px] uppercase'>ghaziabad-27-06-2023</p>
                    <p className='text-white text-center text-[14px]'>2023-06-27 09:00:00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(SattaEvents);
