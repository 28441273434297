import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { userActions } from '../../_actions';
import { connect, useSelector } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { useHistory } from 'react-router-dom';
import { IoCaretBackCircleSharp } from "react-icons/io5";
const HowToPlay = ({ dispatch, props }) => {
  const history = useHistory();
  let selector = useSelector(state => state)
  let { users } = selector ? selector : {}
  let { appSettingData } = users;

  useEffect(() => {
    dispatch(userActions.getAllAppSetting());
  }, [])

  console.log(appSettingData)


  return (
    <>
      <div className='bg-[#EEEEEE] h-screen  w-full '>
        <div className='flex space-x-1 p-1 h-[90vh]  overflow-y-scroll'>
          <Sidebar
            open={true}
            props={props}
            showSport={true}
          />
          <div className='w-full'>
            <IoCaretBackCircleSharp onClick={() => history.push('/')} className=' text-3xl text-[#17273b] cursor-pointer m-1' />
      
            <div className=' bg-[#17273B] justify-center items-center p-5 rounded-md mb-10 w-full  flex  '>
              {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {

                if (e && e.key === "Video") {

                  return (
                    <div className="video-player">
                    <video width="600" controls>
                        <source src={e.value} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                  )
                }
              })}
           

            </div>


            <div className=' my-0.5 bg-[#17273B] text-white  font-bold text-lg  text-center'>

                        HOW TO PLAY
            </div>
            
            <div className=' bg-[#17273B] text-white justify-center items-center p-5 rounded-md mb-10 w-full  flex  '>
             
              {appSettingData && appSettingData.length > 0 && appSettingData.map((e) => {

                if (e && e.key === "How_To_Play") {

                  return (
                    <div dangerouslySetInnerHTML={{ __html: e.value }} />
                  )
                }
              })}

            </div>




          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default withRouter(connect(mapStateToProps)(HowToPlay));