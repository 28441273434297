
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { MdWindow } from "react-icons/md";
import { FaShare } from "react-icons/fa";
import { IoMdArrowDropright } from "react-icons/io";
import { FaRegUserCircle } from "react-icons/fa";
import { RiFileList3Line } from "react-icons/ri";
import { MdOutlineContentCopy } from "react-icons/md";
import { MdPassword } from "react-icons/md";
import { RiLogoutBoxLine } from "react-icons/ri";
import { MdArrowRight } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { GiPodiumWinner } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { RiPagesLine } from "react-icons/ri";
import { IoBarChartOutline } from "react-icons/io5";
import { alertActions, userActions } from "../../_actions";
import { alert } from "../../_reducers/alert.reducer";
import { TbTransferIn } from "react-icons/tb";
import { MdVideocam } from "react-icons/md";

function Sidebar(props) {
  const { opensizebar, setopensizebar } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const [navbarOpen, setNavbarOpen] = useState(false);
  const [sport_id, setSport_id] = useState(props.sportId || null);
  const [series_id, setSeries_id] = useState("");
  const [editformModal, setEditformModal] = useState(false);

  const onClickMenu = (url) => {
    history.push(url);
    setNavbarOpen(!navbarOpen);
    setopensizebar(false)
  };

  useEffect(() => {
    let data = {
      limit: 1,
      pageno: 1,
    };
    let gameReq = {
      "fromDate": "",
      "toDate": "",
      "keyWord": "",
      "sortBy": "",
      "sortOrder": "",
      "pageNo": 1,
      "size": 100
    }
    dispatch(userActions.getGamesList(gameReq));
    dispatch(userActions.getProfile());
  }, []);

  const showList = (ele, url) => {
    setSport_id(ele.sport_id);
    let data1 = {
      limit: 1,
      pageno: 1,
      sport_id: ele.sport_id,
    };
    // dispatch(userActions.event_game_list(data1));

    history.push(url + ele.sport_id);
  };

  const handleClickInside = () => {
    // Handle click inside
  };

  const handleLogout = () => {
    confirmAlert({
      title: '',
      message: ` Are you sure you want to Logout?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }
  const handleLogout2 = () => {
    confirmAlert({
      title: '',
      message: ` Are you sure you want to Delete Account?`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(userActions.deleteUser())
            localStorage.clear();
            sessionStorage.clear();
            history.push('/login');
          }
        },
        {
          label: 'No'
        }
      ]
    });
  }

  let [model, setModel] = useState(false)

  let handlechart = () => {
    setModel((prev) => !prev)
  }


//   let handleShare = async () => {
//     let { users } = props;
//     let { getProfile } = users?users : {};  // Safely destructuring with fallback
//     let { parentId } = getProfile?getProfile : {};
//     let { _id } = parentId?parentId: {};
    
//     const baseUrl =await window.location.origin;
//     const shareLink = `${baseUrl}/#/${getProfile?._id}/${_id}/register`;

//     try {
//       if(getProfile._id&&_id &&shareLink&&baseUrl ){
//         await navigator.clipboard.writeText(shareLink);
//         alertActions.success('Share Link Copyed')
//       }

//     } catch (err) {
//         console.error('Failed to copy link:', err);
//     }
// };


let handleShare = async () => {
  let { users } = props;
  let { getProfile } = users ? users : {};  // Safely destructuring with fallback
  let { parentId } = getProfile ? getProfile : {};
  let { _id } = parentId ? parentId : {};
  
  let isReferral = true
  const baseUrl = await window.location.origin;
  const shareLink = `${baseUrl}/#/${getProfile?._id}/${_id}/register`;

  // Modify shareLink based on whether it’s a referral link or not
  const referralLink = isReferral ? `${shareLink}?referral=true` : shareLink;

  try {
      if (getProfile._id && _id && referralLink && baseUrl) {
          await navigator.clipboard.writeText(referralLink);
          alertActions.success('Share Link Copied');
      }
  } catch (err) {
      console.error('Failed to copy link:', err);
  }
};



  let { users } = props;
  let { totalGame, loading, gameList, slideData, currentTimeApi, appSettingData } = users;


  return (
    <div className={`shadow-lg flex relative transition-all duration-100 ease-in-out w-${opensizebar ? '72' : '0'} h-screen border-t-8 border-black rounded-t-xl`}>
      <div className={`flex flex-col  rounded-lg transition-all duration-100 ease-in-out    ${opensizebar ? 'md:w-96  w-[30rem]' : 'w-0'}  bg-[#17273b] overflow-hidden relative h-[95%]`}>
        {/* <div className=" bg-[#f8713e] h-40  w-48 rounded-full absolute -top-28 right-10 "></div> */}
        <div className="flex flex-col flex-1 overflow-x-hidden overflow-y-auto scroll-md">
          <div className="text-[#3B5160]  md:relative">
            {/* <div className=" text-white  py-5 px-2 text-xl font-bold">Navbar</div> */}

            <div className=" px-4 flex flex-col gap-2 mt-3">
              <span onClick={() => onClickMenu('/app/dashboard')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/dashboard" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><MdWindow className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Home</span>
              </span>


              <span onClick={() => onClickMenu('/app/my_profile')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my_profile" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><FaRegUserCircle className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">MyProfile</span>
              </span>
              <span onClick={() => onClickMenu('/app/my-bets')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/my-bets" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><RiFileList3Line className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Bet History</span>
              </span>

              <span onClick={() => onClickMenu('/app/win_history')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/win_history" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><GiPodiumWinner className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Win History</span>
              </span>

              <span onClick={() => onClickMenu('/app/wallet_history')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/wallet_history" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><RiPagesLine className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Transaction History</span>
              </span>


              <span onClick={() => onClickMenu('/app/transfer_points')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/transfer_points" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><BiTransfer className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Transfer Points</span>
              </span>
              {/* <span onClick={() => onClickMenu('/app/transfer_point')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/transfer_point" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><BiTransfer className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Referral wallet</span>
              </span> */}
              <span onClick={() => onClickMenu('/app/selftransfer')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/selftransfer" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><TbTransferIn className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Referral to Wallet</span>
              </span>

              <span onClick={() => onClickMenu('/app/video')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/video" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><MdVideocam className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Video</span>
              </span>
              {/* <span onClick={() => onClickMenu('/app/ChangePassword')} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/ChangePassword" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><MdPassword className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Change Password</span>
              </span> */}


              <span onClick={() => handlechart()} className={`cursor-pointer h-9  justify-between items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/Logout" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="cursor-pointer h-9   items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out">

                <span className="group-hover:text-[#222a2d]"><IoBarChartOutline className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Chart</span>
                </span>
                
               {model? <span className="group-hover:text-[#222a2d]"><IoMdArrowDropright className="text-xl" /></span>
                :<span className="group-hover:text-[#222a2d]"><IoMdArrowDropdown className="text-xl" /></span>}
              </span>
              {model && <div>

                {gameList && gameList.length > 0 && gameList?.map((e) => {
                  console.log(e)

                  return (
                    <span className={`cursor-pointer h-9  ml-5 items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/Logout" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                      <span className="group-hover:text-[#222a2d]"><MdArrowRight /></span>
                      <span onClick={() => onClickMenu(`/app/calander/${e?._id}/${e?.name}`)} className="font-semibold capitalize ">{e && e.name}</span>
                    </span>
                  )
                })}




              </div>}



              <span onClick={() => handleShare()} className={`cursor-pointer h-9  items-center flex  justify-between px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/Logout" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className=" flex space-x-4">

                <span className="group-hover:text-[#222a2d]"><FaShare className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Share</span>
                </span>

                <MdOutlineContentCopy />
              </span>






              <span onClick={() => handleLogout()} className={`cursor-pointer h-9  items-center flex space-x-4 px-1 py-2 text-base transition ease-in-out duration-150 ${location.pathname === "/app/Logout" ? "text-white bg-[#222a2d] rounded-md" : "text-slate-100 "}`}>
                <span className="group-hover:text-[#222a2d]"><RiLogoutBoxLine className="text-xl" /></span>
                <span className="font-semibold whitespace-nowrap ">Logout</span>
              </span>
            </div>

          </div>
        </div>
      </div>
      <div onClick={()=> setopensizebar((prev)=>!prev)} className="  w-full h-full"></div>
    </div>
  );
}

function mapStateToProps(state) {
  const { users } = state;
  const { user } = state.authentication;
  return {
    users,
    user,
  };
}

export default withRouter(connect(mapStateToProps)(Sidebar));
