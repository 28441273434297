import React from "react";
import { connect } from "react-redux";
import { DomainName } from "../../_config/index";
import { alertActions, userActions } from "../../_actions";
import moment from "moment";
import { FaWallet } from "react-icons/fa";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaPause } from "react-icons/fa";
import BetCloseAlert from "../../components/CloseBetAlert/CloseBetAlert";
import schedule from "./schedule.png";
import { FaPlay } from "react-icons/fa";
import SimpleSlider from "../../components/Sidebar/DemoCarousel";
import { IoCall } from "react-icons/io5";
import { FaTelegram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      gameItem: {},
      activeTab: 1,
      notification: false,
      editStake: false,
      timeDifference: null,
      isPastClosingTime: false,
    };
  }

  componentDidMount() {
    const baseUrl = window.location.origin;
    console.log("baseUrl", baseUrl);
    // const hasSeenPopup = localStorage.getItem('hasSeenPopup');
    // if (!hasSeenPopup) {
    //   this.setState({ notification: true });
    //   localStorage.setItem('hasSeenPopup', true);
    // }
    let data = {
      keyWord: "",
      pageNo: 1,
      sortBy: "slug",
      sortOrder: "asc",
      fromDate: "",
      toDate: "",
      size: 100,
    };

    this.props.dispatch(userActions.getAllSlider());
    this.props.dispatch(userActions.getAllAppSetting());
    this.props.dispatch(userActions.getAllMarket());
    this.props.dispatch(userActions.getProfile());
    this.intervalId = setInterval(() => {
      this.props.dispatch(userActions.getGamesList(data));
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  navigateGameDetailsPage = (data) => {
    const closingTimeInMillis = data.closingTime;
    const currentTimeInMillis = new Date().getTime();

    if (currentTimeInMillis < closingTimeInMillis) {
      // this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)

      this.props.history.push(`/app/matkaDetails/${data._id}`);
    } else {
      // this.setState({ isPastClosingTime: true });
      // this.props.history.push(`/app/matkaDetails/${data.name}/${data._id}`)
      this.props.history.push(`/app/matkaDetails/${data._id}`);
    }
  };

  handleCloseAlert = () => {
    this.setState({ isPastClosingTime: false });
  };

  handleCloseNotification = () => {
    // this.setState({ notification: false });
    // localStorage.setItem('hasSeenPopup', true);
  };

  render() {
    const { activeTab, notification, isPastClosingTime } = this.state;
    let { users } = this.props;
    let {
      totalGame,
      loading,
      gameList,
      slideData,
      currentTimeApi,
      appSettingData,
      getAllmarket,
      getProfile,
    } = users;

    console.log(getAllmarket);
    let jodi = getAllmarket && getAllmarket?.filter((e) => e.name == "JODI");
    let house = getAllmarket && getAllmarket?.filter((e) => e.name == "HOUSE");
    let ending =
      getAllmarket && getAllmarket?.filter((e) => e.name == "ENDING");
    let JODI_second =
      getAllmarket && getAllmarket?.filter((e) => e.name == "JODI_second");
    let ENDING_Secound =
      getAllmarket && getAllmarket?.filter((e) => e.name == "ENDING_Secound");
    let HOUSE_Secound =
      getAllmarket && getAllmarket?.filter((e) => e.name == "HOUSE_Secound");

    const currentTimeApis = moment.unix(currentTimeApi).format("hh:mm A");
    let recentList = [
      {
        name: "Sridevi",
        closingTime: 211331313131,
        openTime: 1131313131312,
      },
    ];

    var settings = {
      button: false,
      dots: true,
      slickPrev: false,
      slickNext: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };


    return (
      <>
        {/* <div className='w-full ' style={{ backgroundImage: "url(/images/bg-all.jpg)" }}> */}

        <div className="w-full " style={{ backgroundColor: "#fff" }}>
          <Loader loading={loading} />
          <div className=" flex space-x-1">
            <div className="lg:flex w-full  space-x-1">
              <div className="w-full space-y-1 h-[98vh] overflow-y-auto md:pb-18">
                <marquee class="text-white bg-[#17273b]  font-bold mt-1 text-sm py-1 bgheader">
                  {appSettingData &&
                    appSettingData.length > 0 &&
                    appSettingData.filter(
                      (e) => e.key === "MarqueeDashboard"
                    )[0]?.value}{" "}
                </marquee>
                <div className=" overflow-hidden  space-y-4  w-11/12 mx-auto">
                  <div className="border border-[#17273b] rounded-md">
                    <SimpleSlider slideData={slideData} />
                  </div>

                  <div className="text-base  flex lg:justify-between justify-center space-x-4 lg:space-x-0 items-center">
                    <div className="flex border bg-green-500 text-white rounded-lg  px-3 py-2 justify-center items-center gap-1 shadow-md w-full">
                      <FaWhatsapp size={24} />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter((e) => e.key === "Whatsapp")[0]
                            ?.value
                        }
                      >
                        <span className="text-md font-bold">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "Whatsapp"
                            )[0]?.key}
                        </span>
                      </a>
                    </div>






                    <div className="flex border bg-[#28A8EA] text-white rounded-lg  px-3 py-2 justify-center items-center gap-1 shadow-md w-full">
                      <FaTelegram size={24} />
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter((e) => e.key === "Telegram")[0]
                            ?.value
                        }
                      >
                        <span className="text-md font-bold">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "Telegram"
                            )[0]?.key}
                        </span>
                      </a>
                    </div>





                    {/* <div className="flex border bg-[#28A8EA] text-white rounded-lg  px-3 py-2 justify-center items-center gap-1 shadow-md w-full">
                    

                    <a
                    href={
                      appSettingData &&
                      appSettingData.length > 0 &&
                      appSettingData.filter((e) => e.key === "Telegram")[0]
                        ?.value
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FaTelegram size={24} />
                   <span>
                   Telegram

                   </span>
                   
                  </a>


                    </div> */}
                  </div>
                  <div className="text-base  flex lg:justify-between justify-center space-x-4 lg:space-x-0 items-center">
                    <div className="flex border bg-[#17273b] text-white rounded-lg  px-3 py-2 justify-center items-center gap-1 shadow-md w-full">
                      <FaWallet size={24} />
                      <span className="text-md font-bold">
                        Referal : <span className="m-1 ">₹</span>{getProfile?.walletRef?.toString()}
                      </span>
                    </div>
                  </div>
                  <a
                    href={
                      appSettingData &&
                      appSettingData.length > 0 &&
                      appSettingData.filter((e) => e.key === "Telegram")[0]
                        ?.value
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex border bg-[#28A8EA] active:scale-90 transition-transform duration-200 text-white rounded-lg px-3 py-2 justify-center items-center gap-1 shadow-md w-full"
                  >
                    <FaTelegram size={24} />
                    <span className="text-md font-bold">
                      {appSettingData &&
                        appSettingData.length > 0 &&
                        appSettingData.filter((e) => e.key === "Telegram")[0]
                          ?.name}
                    </span>
                  </a>
                  <button
                    className="w-full relative bkg text-2xl font-bold p-2 drop-shadow-xl text-white rounded-md text-center"
                    onClick={() => this.props.history.push("/app/teer")}
                  >
                    Teer
                    <span className="absolute right-3  text-white scale-up md:text-2xl  text-base">
                      Play Now
                    </span>
                  </button>

                  <button
                    className="w-full relative bkg2 text-2xl font-bold p-2 drop-shadow-xl text-white rounded-md text-center"
                    onClick={() => this.props.history.push("/app/pocket10")}
                  >
                    Pocket 10
                    <span className="absolute right-3  text-white scale-up md:text-2xl  text-base">
                      Play Now
                    </span>
                  </button>
                </div>
                <section className="bg-[#17273b]">
                  <div className="w-11/12 mx-auto py-3 grid lg:grid-cols-2 gap-2  mt-2 rounded-md pb-20">
                    <div className="text-white space-y-2 border p-4 border-white border-opacity-30 rounded-md shadow">
                      <h1 className="lg:text-2xl text-xl font-medium text-center">
                        Pocket-X Game Play Rates
                      </h1>
                      <div className="bg-[#9A3334] rounded-lg flex justify-center items-center">
                        First Round
                      </div>

                      <p className="bg-[#9A3334]  whitespace-nowrap lg:bg-transparent text-center text-base rounded w-full p-2">
                        jodi_bhav 1 ka {jodi && jodi[0]?.point} (Min. Bet Amount
                        : {jodi && jodi[0]?.minBetAmount})
                      </p>
                      <p className="bg-[#9A3334]  whitespace-nowrap lg:bg-transparent text-center text-base rounded w-full p-2">
                        House_bhav 1 ka {house && house[0]?.point} (Min. Bet
                        Amount : {house && house[0]?.minBetAmount})
                      </p>
                      <p className="bg-[#9A3334]  whitespace-nowrap lg:bg-transparent text-center text-base rounded w-full p-2">
                        Ending_bhav 1 ka {ending && ending[0]?.point} (Min. Bet
                        Amount : {ending && ending[0]?.minBetAmount})
                      </p>

                      <div className="bg-[#9A3334] rounded-lg flex justify-center items-center">
                        Second Round
                      </div>

                      <p className="bg-[#9A3334] whitespace-nowrap lg:bg-transparent text-center text-base rounded w-full p-2">
                        jodi_bhav 1 ka {JODI_second && JODI_second[0]?.point}{" "}
                        (Min. Bet Amount :{" "}
                        {JODI_second && JODI_second[0]?.minBetAmount})
                      </p>
                      <p className="bg-[#9A3334]  whitespace-nowrap lg:bg-transparent text-center text-base rounded w-full p-2">
                        House_bhav 1 ka{" "}
                        {HOUSE_Secound && HOUSE_Secound[0]?.point} (Min. Bet
                        Amount :{" "}
                        {HOUSE_Secound && HOUSE_Secound[0]?.minBetAmount})
                      </p>
                      <p className="bg-[#9A3334]  whitespace-nowrap lg:bg-transparent text-center text-base rounded w-full p-2">
                        Ending_bhav 1 ka{" "}
                        {ENDING_Secound && ENDING_Secound[0]?.point} (Min. Bet
                        Amount :{" "}
                        {ENDING_Secound && ENDING_Secound[0]?.minBetAmount})
                      </p>
                      {/* <p className='bg-[#9A3334] lg:bg-transparent text-center text-base rounded w-full p-2'>triple_patti_bhav 1 ka 600</p>
                      <p className='bg-[#9A3334] lg:bg-transparent text-center text-base rounded w-full p-2'>odd_even_bhav 1 ka 10</p> */}
                    </div>
                    <div className="text-white space-y-2 border p-4 border-white border-opacity-30 rounded-md shadow">
                      <div className="flex flex-col justify-center items-center text-center">
                        <h1 className="text-xl text-[#FAFC05]">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "Withdraw"
                            )[0]?.name}
                        </h1>

                        <p className="text-lg pt-2">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "Withdraw"
                            )[0]?.value}
                        </p>

                        <p className="text-lg">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "Withdrawday"
                            )[0]?.value}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center items-center text-center">
                        <h1 className="text-xl text-[#FAFC05]">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter((e) => e.key === "Deposit")[0]
                              ?.name}
                        </h1>
                        <p className="text-lg pt-2">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter((e) => e.key === "Deposit")[0]
                              ?.value}
                        </p>
                        <p className="text-lg">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "DepositDay"
                            )[0]?.value}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center items-center text-center">
                        <h1 className="text-xl text-[#FAFC05]">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter((e) => e.key === "offDay")[0]
                              ?.name}
                        </h1>
                        <p className="text-base pt-2">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter((e) => e.key === "offDay")[0]
                              ?.value}
                        </p>
                      </div>
                      <div className="flex flex-col justify-center items-center text-center">
                        <p className="text-base text-[#FAFC05]">
                          {appSettingData &&
                            appSettingData.length > 0 &&
                            appSettingData.filter(
                              (e) => e.key === "minAmount"
                            )[0]?.value}
                        </p>
                      </div>
                    </div>
                    <div className="text-white space-y-2 border p-4 border-white border-opacity-30 rounded-md shadow">
                      <h1 className="text-2xl font-medium">
                        {appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter(
                            (e) => e.key === "playmatkagame"
                          )[0]?.name}
                      </h1>
                      <p className="text-base">
                        {appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter(
                            (e) => e.key === "playmatkagame"
                          )[0]?.value}
                      </p>
                    </div>
                    <div className="text-white space-y-2 border p-4 border-white border-opacity-30 rounded-md shadow">
                      <h2 className="text-2xl font-medium">
                        {appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter(
                            (e) => e.key === "disclaimer"
                          )[0]?.name}
                      </h2>
                      <p className="text-base">
                        {appSettingData &&
                          appSettingData.length > 0 &&
                          appSettingData.filter(
                            (e) => e.key === "disclaimer"
                          )[0]?.value}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {this.state.isPastClosingTime ? (
            <BetCloseAlert handleCloseAlert={this.handleCloseAlert} />
          ) : null}
          {/* 
          {notification &&
            <RulesNotification
              handleCloseNotification={this.handleCloseNotification}
              DomainName={DomainName}
            />} */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users,
  };
}

export default connect(mapStateToProps)(Dashboard);
