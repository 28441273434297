import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from "../../components/Sidebar/Sidebar";

class LiveGames extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleResponseCasino = (url) => {
    this.props.history.push(url)
  }

  render() {
    // let { users } = this.props;
    // let { eventcasinoItems, eventgameItems, FcurrentTime } = users;

    return (
      <div className='w-full page_bg flex space-x-1 p-1'>
        <Sidebar
          open={true}
          gameItem={this.state.gameItem}
          // setOpenMobile={setOpenMobile}
          // openMobile={openMobile}
          props={this.props}
          showSport={true}
        />
        <div className='h-screen overflow-y-auto scroll-md w-full'>
          <div className='w-full bg-[#FFFFFF] border-t-[6px] border-black rounded-t-xl mb-2' >
            <h2 className='text-sm py-2 font-medium px-4 text-[#213042]'>
              Casino Games
            </h2>
          </div>
          <div>
            <h2 className='text-sm font-semibold px-2 py-1 text-white bg-black w-full'>Virtual Casino & Matka</h2>
          </div>
          <div className='flex justify-center md:justify-start space-x-3 py-2 w-full'>
            <div className=' xl:w-[270px] md:w-[220px] w-full'>
              <img onClick={() => this.handleResponseCasino("/app/lottery/andarbahar")} className='w-full h-full cursor-pointer' src="/images/v_ab.jpg" alt="" />
            </div>
            <div className='xl:w-[270px] md:w-[220px] w-full relative'>
              <img onClick={() => this.handleResponseCasino("/app/satta-events")} className='w-full h-full cursor-pointer' src="/images/matka.png" alt="" />
              <div className='absolute top-2 md:-right-4 right-0 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
          </div>
          <div>
            <h2 className='text-sm font-semibold px-2 py-1 text-white bg-black w-full'>Live Casino</h2>
          </div>
          <div className='grid grid-cols-2 md:grid-cols-6 pb-28 gap-4 py-2 pr-4'>
            <div onClick={() => this.handleResponseCasino("/app/livegames/ab2")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full cursor-pointer' src="/images/teenday-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>andar bahar 2</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
            <div onClick={() => this.handleResponseCasino("/app/livegames/Lucky7B")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full cursor-pointer' src="/images/lucky7b-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>lucky 7b</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
            <div onClick={() => this.handleResponseCasino("/app/livegames/AAA")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full' src="/images/aaa-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>aaa</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
            <div onClick={() => this.handleResponseCasino("/app/livegames/live-teenpattit20")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full' src="/images/teent20-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>teenpatti t20</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
            <div onClick={() => this.handleResponseCasino("/app/livegames/live-teenodi")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full' src="/images/teenday-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>teenpatti oneday</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
            <div onClick={() => this.handleResponseCasino("/app/livegames/dt20")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full' src="/images/dt20-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>dragon tiger</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
            <div onClick={() => this.handleResponseCasino("/app/livegames/dt202")} className='relative cursor-pointer'>
              <img className='2xl:w-[400px] xl:w-[270px] lg:w-[220px] w-full h-full' src="/images/dt202-new.jpg" alt="" />
              <p className='text-center absolute bottom-0 left-0 font-medium text-sm py-2 bg-[#CF0015] w-full text-white uppercase'>dragon tiger 2</p>
              <div className='absolute top-0 -right-4 rounded-full pulse2 w-10 h-10 p-2'>
                <p className='text-white text-[8px] text-center'>New</p>
                <p className='text-white text-[8px] text-center'>Launch</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(LiveGames);