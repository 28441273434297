import React from 'react';
import { connect } from 'react-redux';
import { FaHome, FaYoutube, FaInstagram, FaKey, FaUserCircle, FaFacebookSquare, FaWhatsapp } from 'react-icons/fa';

const Footer = (props) => {
  const onClickMenu = (url) => {
    props.props.history.push(url)
  }

  return (
    <div className="h-48 bg-[#2A2A2A] w-full lg:w-[50vw] bottom-0 fixed z-6 flex flex-col justify-between px-4 py-4 items-center">
      <div className='w-full flex justify-center items-center'>
        <img src='/images/matkaLogo.png' alt='logo'
          className='h-[80px] cursor-pointer' />
      </div>
      <div className="flex justify-between space-x-3 px-4 py-4 items-center">
        <span onClick={() => onClickMenu("/app/dashboard")} className="cursor-pointer text-white">
          <FaFacebookSquare size={32} />
        </span>
        <span onClick={() => onClickMenu("/app/my-ledger")} className="cursor-pointer text-white">
          <FaWhatsapp size={32} />
        </span>
        <span onClick={() => onClickMenu("/app/livegames")} className="cursor-pointer text-white">
          <FaInstagram size={32} />
        </span>
        <span onClick={() => onClickMenu("/app/changepassword")} className="cursor-pointer text-white">
          <FaYoutube size={32} />
        </span>
      </div>
      <div className='w-full flex justify-center items-center'>
        <span className='text-white'>Copyright -Shillong Pocket Matka 2024</span>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}
export default connect(mapStateToProps)(Footer);