import React from 'react';
import { connect } from 'react-redux';
import Sidebar from "../../components/Sidebar/Sidebar";
import { userActions } from '../../_actions';
class Dashboard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
    let data1 = {
      "limit": 1,
      "pageno": 1,
      "sport_id": 111,
      "series_id": 0
    }
    this.props.dispatch(userActions.event_casino(data1));
  }
  handleResponseCasino = (data) => {
    console.log('data::::::::::::::::::::::::::', data);
    if (data && data.sport_id === 1113) {
      this.props.history.push('/app/teenpatti-t20')
    } else if (data && data.sport_id === 5556) {
      this.props.history.push('/app/amar-akbar-anthony')
    }
    else if (data && data.sport_id === 5557) {
      this.props.history.push('/app/7updown')
    }
    else if (data && data.sport_id === 5559) {
      this.props.history.push('/app/dragon-tiger')
    }
  }


  render() {

    let { users } = this.props;
    let { eventcasinoItems, } = users;

    return (
      <>
        <div className='relative w-full h-full page_bg overflow-y-auto'>
          <div className='flex'>
            <Sidebar
              open={true}
              gameItem={this.state.gameItem}
              // setOpenMobile={setOpenMobile}
              // openMobile={openMobile}
              props={this.props} 
              showSport={true}
            />
            <div className='h-screen overflow-y-auto'>

            <div className="w-full  md:p-4 p-2">
                <div className="grid md:grid-cols-4 grid-cols-2  gap-4 w-full">
                  {
                    eventcasinoItems && eventcasinoItems && eventcasinoItems.InplayMatches && eventcasinoItems.InplayMatches.length > 0 ?
                      eventcasinoItems.InplayMatches.map((element, index) => (
                        <img key={index} src={element.image} alt={`casino ${index}`} className='w-full cursor-pointer transition ease-in-out delay-50 hover:-translate-y-1 hover:scale-105 duration-300' onClick={() => this.handleResponseCasino(element)} />
                      )) : ''
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

      </>


    );
  }
}

function mapStateToProps(state) {
  const { users } = state;

  console.log("users:::ACTIVE_MATCH::", users);

  return {
    users
  };
}

export default connect(mapStateToProps)(Dashboard);

// export default AdminList;