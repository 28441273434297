import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';
import Sidebar from "../../components/Sidebar/Sidebar";
import SidebarRight from '../../components/SidebarRight/SidebarRight';
import EditformModal from "../../casino_components/EditformModal/EditformModal";
import BetSlipTab from "../../casino_components/BetSlipTab/BetSlipTab";
import moment from 'moment'
import { IoCaretBackCircle } from "react-icons/io5";
class MatkaOddEven extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      gameOne: '',
      gameTwo: '',
      gameThree: '',
      gameFour: '',
      gameItem: {},
      activeTab: 1,
      editStake: false,
      fieldStake: {},
      errorStake: {},
      fieldMarket: {},
      errorMarket: {},

      data: {
        "limit": 10,
        "pageno": 1
      }
    }
  }
  componentDidMount() {
    let data = {
      "marketId": this.props.match.params.marketId,
    }
    console.log(data)
    this.props.dispatch(userActions.getMarketByMarketId(data));
    this.props.dispatch(userActions.getGamesByGamesIdForUser({ "gameId": this.props.match.params.gameId }))
  }

  handleResponseCasino = () => {
    this.props.history.push('/app/satta-events-id')
  }

  handleSidebar = (data) => {
    console.log('datagameItemgameItemgameItem', data);
    this.setState({ gameItem: data });

  }
  handleTabClick = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  };

  setEditStakeOpen = () => {
    this.setState({ editStake: !this.state.editStake })
  }

  handleStakeChange = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldStake, errorStake } = this.state;
    fieldStake[name] = parseFloat(value)>=0?value:0;
    errorStake[name] = "data not found";

    this.setState({ fieldStake, errorStake, totalPoint:  value });
  };

  handleChangeSelect = (e) => {
    e.preventDefault();
    let { name, value } = e.target;
    let { fieldMarket, errorStake } = this.state;
    fieldMarket[name] = value;
    errorStake[name] = "data not found";
    this.setState({ fieldMarket, errorStake });
    this.setState({
      errorStake: {
        ...this.state.errorStake,
        market: ""  ,
        betNumber:'',
      }
    });
  };

  placeBet = () => {
    let { fieldStake, fieldMarket,totalPoint } = this.state;
    console.log(fieldMarket["betNumber"])
    if(this.handleValid()&&totalPoint!==0&&totalPoint!==''){

      let reqData = {
        "marketId": this.props.match.params.marketId,
        "gameId": this.props.match.params.gameId,
        "bets": [{
          "betNumber": fieldMarket["betNumber"],
          "amount": totalPoint
        }], 
        "isOpen": fieldMarket["isOpen"] === "open-market" ? true : false,
      }
      this.props.dispatch(userActions.placeBet(reqData));
    



    }

  }
  handleValid=()=>{
    let valid =true;
    let { fieldStake, fieldMarket } = this.state;
    let errorMessage ={}
    let bets = Object.keys(fieldStake).map(key => ({ "betNumber": key, "amount": parseInt(fieldStake[key]) }));

    if(fieldMarket&&Object.keys(fieldMarket).length==0){
      valid=false;
      errorMessage["market"]="Select Market"
  
    }

    if(!fieldMarket["betNumber"]||fieldMarket["betNumber"]==""){
      valid=false;
      errorMessage["betNumber"]="Select Even/Odd"
  
    }
    if(bets&&bets.length==0 ){
      valid=false;
      errorMessage["bet"]="Bets not found"
      
    }
    this.setState({ errorStake: {...errorMessage}})
  
    console.log(this.state.errorStake)
    return valid
  
    }


  render() {
    let { users } = this.props;
    let { marketDetails, marketLoading } = users;
    let { fieldStake, fieldMarket, errorStake, totalPoint } = this.state;
    // let { numList } = marketDetails && marketDetails;
    let {  loading, gameData
    } = users;


    let { currentTime, list } = gameData ? gameData : {};
    let { openTime, closingTime, betOpenTime, betClosingTime, max, min } = list ? list : {};


    const openingTime = moment()
      .hours(parseInt(openTime && openTime / 3600))
      .minutes(parseInt((openTime && openTime % 3600) / 60))
      .seconds(parseInt(openTime && openTime % 60))
      .milliseconds(0);

    const closingTimes = moment()
      .hours(parseInt(closingTime && closingTime / 3600))
      .minutes(parseInt((closingTime && closingTime % 3600) / 60))
      .seconds(parseInt(closingTime && closingTime % 60))
      .milliseconds(0);

    const closeCloseBidTime = moment.duration(betClosingTime && betClosingTime, 'seconds').asMinutes()
    const closeOpenBidTime = moment.duration(betOpenTime && betOpenTime, 'seconds').asMinutes()

    const MinutesBeforeClosing = closingTimes.clone().subtract(closeCloseBidTime, 'minutes');
    const MinutesBeforeOpening = openingTime.clone().subtract(closeOpenBidTime, 'minutes');

    //formate
    const CloseBetMinutesBeforeClosing = MinutesBeforeClosing.format("hh:mm A");
    const CloseBetMinutesBeforeOpening = MinutesBeforeOpening.format("hh:mm A");

    //current time
    const currentTimeApis = moment.unix(currentTime).format("hh:mm A");

    const isBeforeClose = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetMinutesBeforeClosing, "hh:mm A"));
    const isBeforeOpen = moment(currentTimeApis, "hh:mm A").isBefore(moment(CloseBetMinutesBeforeOpening, "hh:mm A"));
    return (
      <div className='w-full h-full overflow-y-auto bg-gray-200'>
        <div className=' text-5xl absolute text-[#17273b]'>

<IoCaretBackCircle className=' cursor-pointer' onClick={()=>this.props.history.goBack()} />
</div>
      {/* <div className='w-full h-full overflow-y-auto' style={{ backgroundImage: "url(/images/mw-ban.png)" }}> */}
        <div className="w-full md:p-6 p-2 mb-10 space-y-10">
          <div className='px-3 py-3 text-2xl font-semibold text-center text-[#17273b] capitalize '>
            {this.props.match.params.gameName}
          </div>

          <div className='bg-white w-full lg:mx-auto p-5 lg:p-10 space-y-5'>
            <div className='flex justify-center items-center'>
              <span className='text-2xl uppercase font-[500] lg:text-[#495057] text-[#913030]'>ODD / EVEN</span>
            </div>
            <div className='md:text-2xl text-sm whitespace-nowrap flex justify-center items-center uppercase font-[500] lg:text-[#17273b] text-[#913030]'>
               Bet amount should be between {min && min || ''} - {max && max || ""}
            </div>
            <div className='bg-[#923131]  flex  flex-wrap   justify-center items-center space-y-3 lg:space-y-0 lg:space-x-5 p-5 rounded-xl'>
              <div className='flex flex-col justify-center items-center'>
                <div className='space-y-1 md:w-auto w-full'>
                  <label className='text-xl font-[500] text-white'>Choose Bet Type</label>
                </div>
                <select onChange={this.handleChangeSelect}
                  name="isOpen"
                  value={fieldMarket && fieldMarket["isOpen"] ? fieldMarket["isOpen"] : ""}
                  className='px-3 py-1.5 text-xl bg-white border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block'>
                  <option value="">Select Market Type</option>
                  {isBeforeClose&&isBeforeOpen &&<option value="open-market">Open Market</option>}
                {isBeforeClose&&<option value="close-market">Close Market</option>}
                </select>
                {this.state.errorStake&&this.state.errorStake['market']?<div className=' text-red-500'>{this.state.errorStake['market']}</div>:""}
             
              </div>

              <div className='flex flex-col justify-center items-center'>
                <div className='space-y-1 md:w-auto w-full'>
                  <label className='text-xl font-[500] text-white'>Select Even / Odd</label>
                </div>
                <select onChange={this.handleChangeSelect}
                  name="betNumber"
                  value={fieldMarket && fieldMarket["betNumber"] ? fieldMarket["betNumber"] : ""}
                  className='px-3 py-1.5 text-xl bg-white border border-gray-900 rounded md:w-60 w-full focus:outline-none text-[#495057] placeholder:text-gray-500 block'>
                  <option value="">Select Even/Odd</option>
                  {marketDetails && marketDetails.numList && marketDetails.numList.length > 0 ? marketDetails.numList.map((element, index) => (
                    <option value={element}>{element}</option>)) : null}
                </select>
                {this.state.errorStake&&this.state.errorStake['betNumber']?<div className=' text-red-500'>{this.state.errorStake['betNumber']}</div>:""}
                
              </div>
              <div className='flex flex-col justify-center items-start lg:items-center'>
                <div className='space-y-1 md:w-auto w-full'>
                  <label className='text-xl font-[500] text-white'>Amount</label>
                </div>
                <div className='h-12 w-44 border border-[#555555] bg-white'>
                  <input type='number' className="focus:outline-none rounded px-2 py-2 h-full w-full" name="amount" placeholder="0" value={fieldStake && fieldStake["amount"] ? fieldStake["amount"] : ""} onChange={this.handleStakeChange} />
                </div>
              </div>
            </div>
            <div className='flex justify-center items-center space-x-5'>
              <span className='text-xl font-[500] text-[#495057]'>Total Point:</span>
              <span className='text-xl font-[500] text-[#495057]'>{totalPoint}</span>
            </div>
            <div className='flex justify-center items-center'>
              <button onClick={() => this.placeBet()} className='w-full md:w-auto py-1.5 px-4 bg-[#913030] lg:bg-[#495057] text-white text-xl rounded'>Submit Game</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  return {
    users
  };
}

export default connect(mapStateToProps)(MatkaOddEven);
