import React from 'react';
import { RiCloseCircleFill } from "react-icons/ri";

export default function Models(props) {

  let { openmodel, visibleArry, submittedModel, name,handleChangeinmodel, handleremoveNumber, handleButton, handleCloseModel } = props;
  console.log( name)

  let sumodamount = 0;
  for (let i = 0; i < submittedModel.length; i++) {



if(
  !isNaN(parseInt(submittedModel[i].amount))
){

  sumodamount = sumodamount + parseInt(submittedModel[i].amount);
}
    
  }


  return (
    <div
      className={openmodel ? "fixed flex-col inset-0 flex items-center justify-center bg-black bg-opacity-50" : "hidden"}
    >
      <div className="bg-white p-8  shadow-lg relative w-11/12 lg:w-[600px]  justify-center items-center  over rounded gap-3 flex flex-col flex-wrap">
        <RiCloseCircleFill onClick={() => handleCloseModel()} className=' cursor-pointer absolute right-3 top-1 text-xl' />
        <div className='flex justify-center items-center '>
              <span className='lg:text-4xl capitalize text-xl underline  lg:font-[800] font-[800] lg:text-[#495057] text-[#495057]'>{name && name} Bid</span>
          
            </div>
        <div className=' flex gap-4  h-72  overflow-hidden overflow-y-auto  justify-center flex-wrap items-center text-white'>

          {
            visibleArry && visibleArry.length > 0 && visibleArry.map((elem, i) => {
              console.log(elem)

              return (
                <div className='bg-[#17273B]    relative flex justify-center items-center flex-col  p-4 rounded-lg h-20 w-27'>
                  {elem?.flag == null &&elem?.num.toString()!="0000"   && <div> {elem?.num} </div>}
                  {elem?.flag == null &&elem?.num.toString()=="0000"   && <div> All Pair </div>}
                  {elem?.flag == true && <div> {elem?.num} House</div>}
                  {elem?.flag == false && <div> {elem?.num} Ending</div>}


                  <input

                    value={
                      submittedModel &&
                      submittedModel.find(e =>
                        e?.marketId === elem?._id &&
                        e?.betNumber?.toString() === elem?.num.toString() &&
                        e?.isOpen === elem?.flag
                      )?.amount || ''
                    }

                    className='w-20 rounded-lg text-black' onChange={(e) => handleChangeinmodel(elem, e.target.value)} type='number' />
                  <RiCloseCircleFill onClick={() => handleremoveNumber(elem)} className=' cursor-pointer absolute right-1 top-1 text-xl' />

                </div>
              )
            })
          }
        </div>

<div>
  <div>Total Bid: <span>{visibleArry.length}</span></div>
  <div>Total Amount:  <span>{sumodamount||""}</span></div>
</div>

        <button className='bg-green-500 rounded-lg  font-bold w-32 py-1 px-4' onClick={() => handleButton()}>Submit</button>
      </div>


    </div>
  );
}
